// contact.scss //
.sfp-theme {
  .banner-section {
    &.secondary-banner {
      padding: 175px 0 140px 0;

      &.contact-banner {
        background-image: url(../images/star-graphic-light.svg);
        background-position: top left;
        background-repeat: no-repeat;
        background-size: auto;
      }

      @include bp(sm-max) {
        padding: 35px 0 0px 0;
      }

      .row {
        align-items: center;
      }

      .content-block {
        padding: 0 160px 0 0;
        @include bp(md-max) {
          padding: 0 100px 0 0;
        }
        @include bp(sm-max) {
          padding: 0 0 0 0;
        }

        h1 {
          letter-spacing: -2px;
          @include bp(sm-max) {
            letter-spacing: -1.6px;
          }
        }
      }

      .more-info {
        font-size: 20px;
        line-height: 32px;
        color: $dark-gray-02;
        letter-spacing: -0.2px;
        margin: -50px 0 0 0;
        @include bp(sm-max) {
          margin: 0px;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.2px;
        }
      }
    }
  }
}

// footer.scss //
footer.sfp-theme {
  border-top: 1px solid $sfp-primary-color;
  background-color: $sfp-primary-color;

  .right-footer {
    .media-icon {
      a {
        i {
          img {
            @include filter-white();
          }
        }
      }
    }
  }

  ul {
    li {
      a {
        i {
          img {
            @include filter-white();
          }
        }

        color: $white;

        &:hover {
          color: $white;
          opacity: 0.6;
        }
      }

      p {
        color: $white;
      }

      &.footer-title {
        span {
          color: $white;
        }

        a {
          color: $white;
          font-weight: 700;
        }
      }
    }
  }

  .bottom-footer {
    p {
      color: $white;
    }
  }

  .dark-theme-ic {
    display: inline-block;
  }

  .light-theme-ic {
    display: none;
  }
}

// slice.scss //
.sfp-theme {
  .product-offer-block {
    background-color: $white;

    .left-block {
      h2 {
        color: $black;
        font-weight: 400;

        span {
          color: $black;
          font-weight: 400;
        }
      }
    }

    a {
      i {
        img {
          @include filter-sfp-primary-colour();
        }
      }
    }

    .product-offer-tiles-wrapper {
      .product-offer-tiles {
        background-color: $sfp-light-gray-05;

        p {
          color: $sfp-primary-color;
        }

        &:hover {
          background-color: $sfp-primary-color;

          i {
            display: inline-block;

            img {
              @include filter-white();
            }
          }

          h5 {
            color: $white;
          }

          p {
            color: $sfp-light-gray-05;
          }

          .btn.btn-raised.btn-secondary {
            display: inline-flex;

            &:focus {
              background-color: $white !important;
            }
          }
        }
      }
    }

    &.has-tools {
      .product-offer-tiles-wrapper {
        @include bp(xs-max) {
          margin: 0;
        }

        .product-offer-tiles {
          @include bp(xs-max) {
            flex: 0 0 100%;
            max-width: 100%;
            padding: 32px;
            min-height: auto;
            margin: 0 0 24px;

            &:hover {
              .btn {
                &.btn-raised {
                  &.btn-secondary {
                    @include bp(xs-max) {
                      background-color: $white !important;

                      &:hover {
                        background-color: $light-gray-07 !important;
                      }
                    }
                  }
                }
              }
            }
          }

          &:last-child {
            margin: 0;
          }

          i {
            @include bp(xs-max) {
              max-width: 30px;
              height: 33px;
              margin: 0 0 20px;
            }
          }

          h5 {
            @include bp(xs-max) {
              font-size: 20px;
              line-height: 24px;
              letter-spacing: -0.4px;
              margin: 0 0 8px;
              min-height: auto;
            }
          }

          p {
            @include bp(xs-max) {
              display: block;
              min-height: auto;
              margin: 0 0 24px;
            }
          }

          .btn {
            &.btn-raised {
              &.btn-secondary {
                @include bp(xs-max) {
                  display: -webkit-inline-box;
                  display: -webkit-inline-flex;
                  display: -ms-inline-flexbox;
                  display: inline-flex;
                  width: auto;
                  background-color: transparent !important;
                  border-color: $light-gray-01;
                }
              }
            }
          }
        }
      }
    }
  }
}

// AboutUs.scss //
.sfp-theme {
  .content-slice {
    padding: 128px 0;
    @include bp(md-max) {
      padding: 50px 5px;
    }

    .content-slice-detail {
      @include bp(sm-max) {
        padding-right: 10px;
      }

      p {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.2px;
        color: $dark-gray-05;
        margin-bottom: 30px;
        @include bp(sm-max) {
          margin-bottom: 23px;
        }
      }

      a {
        display: inline-flex;
        color: $sfp-primary-color;
        justify-content: center;
        align-items: center;
      }
    }

    &.about-contents-slice {
      .sfp-wheel {
        width: 100%;
        max-width: 720px;
        margin: 30px auto 0;
        padding-top: 30px;

        img {
          max-width: 100%;
        }
      }
    }
  }

  .btn-wrap {
    .btn-primary {
      background-color: $sfp-primary-color !important;
      border: 2px solid $sfp-primary-color !important;

      .ripple-container {
        .ripple-decorator {
          &.ripple-on {
            background-color: transparent !important;
            opacity: 0.8;
          }

          &.ripple-out {
            opacity: 0;
          }
        }
      }
    }
  }

  .content-slice {
    &.about-contents-slice {
      .sfp-wheel {
        width: 100%;
        max-width: 720px;
        margin: 30px auto 0;
        padding-top: 30px;

        img {
          max-width: 100%;
        }
      }
    }
  }
}

.sfp-theme {
  .testimonial-section {
    title {
      h2 {
        color: $black;
      }
    }

    .client-testimonial-slider {
      .testimonial-slide {
        background-color: $light-gray-09;

        h6 {
          color: $sfp-primary-color;
        }
      }
    }
  }
}

.sfp-theme {
  .main-wrap {
    .banner-section {
      .modal-content {
        .modal-header {
          h4 {
            color: $black !important;
          }
        }
      }

      &.about-banner {
        &.stand-about-banner {
          .content-block {
            @include bp(sm-max) {
              padding: 35px 0 0 0;
            }
          }

          .img-block {
            position: relative;
            @include bp(sm-max) {
              padding: 35px 0 0 0;
            }

            &:after {
              background: url(../images/star-graphic-light.svg);
              background-repeat: no-repeat;
              background-position: bottom left;
              position: absolute;
              left: -73px;
              bottom: -108px;
              height: 100%;
              width: 100%;
              z-index: 0;
              @include bp(sm-max) {
                display: none;
              }
            }

            &.profile-img {
              &:after {
                background: none !important;
              }
            }
          }

          h1 {
            color: $black;
            font-weight: 400;
          }

          p {
            color: $dark-gray-01;
          }

          .btn-wrap {
            .btn-secondary {
              background-color: $sfp-primary-color;

              .img-wrap {
                color: $sfp-primary-color;
              }

              span {
                color: $sfp-primary-color
              }
            }

            .btn-primary {
              background-color: $sfp-primary-color;
            }
          }
        }
      }
    }
  }
}


// Blogs.scss //
.sfp-theme {
  .content-slice {
    .content-step-wrapper {
      .content-inner::before {
        border: 3px solid $blue-shade-04;
      }
    }

    &.media-heading {
      .breadcrumb {
        .breadcrumb-item {
          a {
            color: $sfp-primary-color;
          }

          &.active {
            a {
              color: unset;
            }
          }
        }
      }
    }
  }

  .blog-listing-section {
    padding: unset;

    .category-listing-block {
      background-color: $sfp-primary-color;

      .circle-list {
        li {
          color: $white;

          &:before {
            border-color: $white;
            background-color: $white;
          }
        }
      }
    }
  }
}

//Header.scss//
.sfp-theme {
  .top-header {
    background-color: $white;

    li {
      a {
        color: $dark-gray-03;
      }
    }

    ul {
      &.right-header {
        li {
          a {
            &:hover {
              color: $sfp-primary-color;
            }
          }
        }
      }

      &.left-header {
        li {
          a {
            color: $sfp-primary-color;

            &.active {
              background-color: $sfp-primary-color;
              color: $white;
            }
          }
        }
      }
    }
  }

  .bottom-header {
    background: $sfp-primary-color;
  }

  .nav {
    @include bp(lg-max) {
      background: $sfp-primary-color;
      .down-arrow {
        display: none !important;
      }
    }

    .top-header-link {
      border-bottom: none;
      border-top: none;
      @include bp(lg-max) {
        background-color: $sfp-primary-color;
      }
    }

    .nav-item {
      @include bp(lg-max) {
        &.has-submenu {
          border-top: 1px solid $sfp-blue-shade-01;
        }
      }

      &.top-header-link {
        background-color: $sfp-light-gray-05;

        span {
          color: $dark-gray-03;
        }
      }

      .nav-link {
        font-family: $roboto;
        color: $white !important;

        &:after {
          background: $white;
        }

        &:hover,
        &.active {
          color: $white;
        }
      }

      &.has-submenu {
        .nav-link {
          &.active {
            ~ .down-arrow {
              &:after {
                border-color: $sfp-primary-color;
              }
            }
          }
        }

        .down-arrow {
          &:after {
            border: 2px solid $dark-gray-04;
          }
        }

        .hover-block {
          .inner-links {
            background-color: #ffffff;

            li {
              a {
                color: $secondary-color;

                &:hover {
                  color: $sfp-primary-color;
                }
              }
            }
          }
        }
      }
    }
  }

  .hamburger-menu {
    &.fixed {
      background: $yellow-04;

      .hamburger {
        background: $white;

        &::after,
        &::before {
          background: $white;
        }
      }
    }
  }

  .hamburger {
    background: $white;

    &:before,
    &:after {
      background: $white;
    }
  }
}

//sfp theme//
.sfp-theme {
  .modal {
    .modal-content {
      .modal-footer {
        button {
          border: 2px solid $sfp-primary-color !important;
          background-color: $sfp-primary-color !important;
        }
      }
    }
  }

  .banner-section {
    &.standalone-banner {
      background-image: url(../images/star-graphic-light.svg);
      background-position: top left, center center;
      background-repeat: no-repeat, no-repeat;
      background-size: auto, cover;

      h1 {
        color: $white;
      }

      .profile-block {
        background-color: $sfp-primary-color;

        .content-block {
          h4 {
            color: $white;
          }

          .sub-title {
            color: $white;
          }

          .btn {
            border-color: rgba(255, 255, 255, 0.7) !important;
            background-color: $sfp-primary-color !important;
            color: $white !important;

            &:focus {
              border-color: $white !important;
              background-color: rgba(255, 255, 255, 0.2) !important;
            }

            &:active {
              box-shadow: none !important;
              border-color: $light-gray-01 !important;
            }

            &:hover {
              border-color: rgba(255, 255, 255, 0.7) !important;
              background-color: rgba(255, 255, 255, 0.2) !important;
              box-shadow: none !important;
            }

            &.disable {
              color: rgba(255, 255, 255, 0.5) !important;
              background-color: $sfp-primary-color !important;
              border-color: rgba(255, 255, 255, 0.5) !important;
              pointer-events: none;
            }

            .ripple-container {
              .ripple-decorator {
                &.ripple-on {
                  background-color: rgba(255, 255, 255, 0.2) !important;
                }

                &.ripple-out {
                  opacity: 0;
                }
              }
            }
          }

          .social-list {
            li {
              div {
                label {
                  background-color: $sfp-primary-color;

                  i {
                    img {
                      @include filter-white();
                    }
                  }
                }
              }
            }

            li {
              a {
                i {
                  img {
                    @include filter-white();
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.sfp-theme {
  .select2-dropdown {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .24);
    border: none;
    border-radius: 0;

    .select2-results__option {
      font-size: 16px;
      line-height: 1.5;
      color: $black-shade-01;
      padding: 16px;

      &.select2-results__option--highlighted {
        color: $black;
        background-color: $light-gray-08;
      }

      &.select2-results__option--selected {
        color: $white;
        background-color: $sfp-primary-color;
      }
    }
  }

  .tab-block {
    background-color: $sfp-light-gray-05;

    .nav-tabs {
      @include bp(sm-max) {
        justify-content: center;
      }
    }

    .title {
      h2 {
        color: $black;
      }
    }

    .tab-content {
      @include bp(sm-max) {
        background-color: $sfp-light-gray-05;
        padding-top: 0;
      }

      .tab-pane {
        .title {
          p {
            font-weight: 400;
          }

          h2 {
            font-weight: 400;
          }
        }

        ul {
          li:before {
            border: 3px solid $sfp-primary-color;
          }
        }
      }
    }

    .contact-detail-tiles {
      background-color: $sfp-primary-color;
    }

    .contact-detail-tiles {
      ul {
        li {
          a {
            i {
              img {
                @include filter-sfp-primary-colour();
              }
            }
          }
        }
      }
    }
  }
}

.sfp-theme {
  .tools-calculators {
    .title {
      h2 {
        color: $black;
      }
    }

  }
}

.sfp-theme {
  .pagination {
    justify-content: flex-end;
    @include bp(sm-max) {
      justify-content: center;
    }

    .page-item {
      .page-link {
        &:hover {
          color: $sfp-primary-color;
        }
      }

      &.active {
        .page-link {
          background-color: $sfp-primary-color;
          border-color: $sfp-primary-color;
        }
      }

      &.active {
        .page-link {
          &:hover {
            color: $white;
          }
        }
      }
    }
  }

  .news-section {
    background-color: $sfp-light-gray-05;

    .media-cards {
      .media-card {
        .card-label {
          p {
            color: $sfp-primary-color;
          }
        }
      }
    }

    a {
      color: $sfp-primary-color !important;
    }

    .title {
      h2 {
        color: $black;
        font-weight: 400;

        span {
          color: $black;
          font-weight: 400;
        }
      }
    }
  }
}

.sfp-theme {
  .banner-section {
    &.standalone-banner {
      .content-block {
        h1 {

          @include bp(lg-max) {
            color: $white !important;
          }
        }
      }
    }
  }
}

// Show More/Less Buttons
.sfp-theme {
  .view-more-less {
    a {
      font-size: 16px;
      color: $sfp-primary-color;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.2px;
      margin: 0 0 14px 0;
      display: block;
    }

    .text-block {
      padding: 30px 25px 35px 40px;
      background-color: $light-gray-06;
      margin: 0 0 30px 0;

      p {
        font-size: 14px;
        color: $dark-gray-02;
        line-height: 20px;
        letter-spacing: -0.2px;
        position: relative;

        &:after {
          content: "";
          position: absolute;
          height: 6px;
          width: 6px;
          background-color: $sfp-primary-color;
          border-radius: 100%;
          left: -20px;
          top: 6px;
        }
      }
    }

    .less-btn {
      display: none;
    }

    .view-less-wrap {
      display: none;
    }

    &.read-more {
      .show-more {
        display: none;
      }
    }

    .show-btn {
      border: 2px solid $sfp-primary-color;
      background-color: transparent;
      border-radius: 4px;
      padding: 8px 10px 8px 10px;
      margin: 0 0 30px 0;
      width: 162px;
      font-size: 14px;
      font-weight: 500;
      color: $sfp-primary-color;
      line-height: 20px;
      letter-spacing: -0.2px;
      cursor: pointer;
      text-transform: none;

      &:hover {
        border: 2px solid $sfp-primary-color;
        background-color: $sfp-light-green !important;
      }

      &:focus {
        border: 2px solid $sfp-primary-color;
        background-color: transparent !important;
      }

      @include bp(sm-max) {
        width: 100%;
      }

      &.show-more {
        span {
          &:after {
            content: "";
            width: 14px;
            height: 2px;
            background-color: $sfp-primary-color;
            display: inline-block;
            position: absolute;
            top: 8px;
            left: 0px;
            @include rotate(90deg);
          }

          &:before {
            top: 6px;
          }
        }
      }

      &.show-less,
      &.show-more {
        span {
          position: relative;
          width: 20px;
          display: inline-flex;
          height: 14px;

          &:before {
            content: "";
            width: 14px;
            height: 2px;
            background-color: $sfp-primary-color;
            display: inline-block;
            position: absolute;
            top: 8px;
            left: 0px;
          }
        }
      }
    }

    .more-less-btn {
      display: block;
      width: 100%;
    }
  }
}

.sfp-theme {
  .banner-section {
    .content {
      p {
        color: $white;
        @include bp(lg-max) {
          color: $black;
        }
      }
    }

    .contact-block {
      .detail-wrapper {
        box-shadow: none;
        background-color: $theme-dark-1;

        li {
          a {
            color: $white;

            &:after {
              background-color: $dark-gray-09;
            }

            i {
              &.call-btn {
                img {
                  display: none;

                  &.call-white {
                    display: inline-block;
                  }
                }
              }
            }
          }
        }

        .rounded-btn {
          &:hover {
            background-color: $dark-gray-09;
          }
        }
      }
    }

    &.secondary-banner {
      .more-info {
        color: $white;
      }
    }
  }

  &.tab-block {
    .nav-tabs {
      .nav-link {
        color: $white !important;
      }
    }
  }

  .about-banner {
    .btn-secondary {
      background: transparent !important;
      color: $sfp-primary-color !important;

      &:hover {
        background: transparent !important;
      }
    }
  }

  .offer-block {
    background-color: $theme-dark-1;

    .offer-tiles-wrapper {
      .offer-tiles {
        background-color: $theme-dark;

        &:hover {
          background-color: $sfp-primary-color;

          p {
            color: $white;
          }
        }

        h5 {
          color: $white;
        }

        p {
          color: $dark-gray-08;
        }
      }
    }
  }

  .slider-section {
    background-color: $theme-dark;

    .swiper-wrapper {
      .swiper-slide {
        p {
          color: $light-gray-03;
        }

        h4 {
          color: $white;
        }
      }
    }
  }

  .financial-goals {
    background-image: url(../images/cta-banner-img.jpg), url(../images/cta-banner-img.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;

    h2 {
      color: $white;

      span {
        font-weight: 500;
      }
    }

    p {
      color: $white;
    }

    a {
      background-color: $sfp-primary-color;
      color: $sfp-primary-color;

      .ripple-container {
        background-color: $sfp-primary-color;
      }
    }

    .modal-policy {
      p {
        color: $black;

        a {
          background-color: $white !important;
          color: $sfp-primary-color;
        }
      }
    }
  }

  .about-section {
    background-color: $white;

    &.text-block-outer {
      .about-text-wrap {
        background-color: $sfp-light-gray-05;
      }
    }

    .title {
      p {
        font-weight: 400;
      }

      h2 {
        font-weight: 400;
        color: $black;
      }

      .text-blue {
        color: $sfp-primary-color;
      }

      .line-bottom {
        background-color: $sfp-primary-color;
      }
    }
  }

  .financial-planner {
    background-color: $white;

    .team-listing > ul > li {
      p {
        color: $light-gray-03;
      }

      .detail-wrapper {
        p {
          color: $sfp-primary-color;
        }

        h3 {
          color: $black;
        }
      }

      h3 {
        color: $white;
      }
    }
  }

  .contact-wrapper {
    .media-info {
      ul {
        li {
          a {
            &.whatsapp-icon {
              i {
                img {
                  filter: unset;
                }
              }
            }
          }
        }
      }
    }

    background-color: $theme-dark;
  }

  .form-group,
  .form-group.bmd-form-group {
    label {
      color: $white;
    }

    .form-control {
      background-color: $white;

      &:focus {
        border-color: $sfp-primary-color;
      }
    }
  }

  .title {
    h2 {
      color: $black;
    }

    .text-blue {
      color: $sfp-primary-color;
    }

    .line-bottom {
      background-color: $sfp-blue-04;
    }
  }

  .contact-block {
    .detail-wrapper {
      li {
        padding: 12px 50px;
      }
    }

    .detail-wrapper li:nth-child(1), .detail-wrapper li:nth-child(2) {
      @include bp(sm-max) {
        border-bottom: 1px solid $dark-gray-09;
      }
    }
  }

  .Read-more-btn {
    color: $white;
    border-bottom: 1px solid $white;
  }
}

.sfp-theme {
  .financial-goals {
    background-size: cover;

    .content-block {
      .btn-primary {
        background-color: $sfp-primary-color !important;
        border-color: $sfp-primary-color !important;
      }
    }

    .head-title {
      font-family: $roboto;
      color: $white;
    }

    p {
      font-family: $roboto;
      color: $light-gray-06;
    }
  }
}

.sfp-theme {
  .select2-container {
    outline: none;
    width: 100% !important;

    &.select2-container--open {
      .select2-selection--single {
        .select2-selection__arrow {
          top: 25px;
          @include rotate(225deg);
        }
      }
    }

    .select2-selection--single {
      background-color: $white;
      border: 2px solid $dark-gray-07;

      &:focus {
        border-color: $sfp-primary-color;
      }

      .select2-selection__arrow {
        border: 2px solid $sfp-primary-color;
        border-top: 0;
        border-left: 0;
      }
    }

    .select2-dropdown {
      .select2-container--open {
        .select2-results {
          .select2-results__option {
            color: $black-shade-01;

            &.select2-results__option--selectable {
            }

            &.select2-results__option--highlighted {
              color: $black;
              background-color: $sfp-primary-color !important;
            }

            &.select2-results__option--selected {
              color: $white;
              background-color: $sfp-primary-color !important;
            }
          }
        }
      }

      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .24);
      border: none;
      border-radius: 0;
    }
  }

  .form-group,
  .form-group.bmd-form-group {
    @include bp(sm-max) {
      margin-top: 25px;
      .dk-selected {
        border: 2px solid $dark-gray-07;

        &:focus {
          border-color: $sfp-primary-color !important;

          &:before {
            border-color: $sfp-primary-color !important;
          }
        }

        &:after {
          display: none;
        }

        &:before {
          border: 2px solid $sfp-primary-color;
          @include rotate(45deg);
        }
      }
    }

    .dk-selected {
      border: 2px solid $dark-gray-07;

      &:focus {
        border-color: $sfp-primary-color !important;

        &:before {
          border-color: $sfp-primary-color !important;
        }
      }

      &:after {
        display: none;
      }

      &:before {
        border: 2px solid $sfp-primary-color;
        @include rotate(45deg);
      }
    }

    &.dk-select-open-down,
    &.dk-select-open-up {
      .dk-selected {
        &:before {
          @include rotate(225deg);
          top: 25px;
        }
      }
    }
  }

  .contact-wrapper {
    a {
      &.location-wrap {
        i {
          img {
            filter: unset;
          }
        }
      }
    }

    ul {
      li {
        a {
          i {
            img {
              @include filter-sfp-primary-colour();
            }
          }
        }
      }
    }

    background-color: $sfp-light-gray-05 !important;

    .contact-form {
      .btn-wrap {
        a {
          color: $sfp-primary-color;
        }
      }
    }
  }

  .banner-section {
    &.has-left-space {
      background-image: url(../images/star-graphic-light.svg);
      background-repeat: no-repeat;
      padding: 60px 0 60px 0;
    }

    &.about-banner {
      background-image: unset;
      background-repeat: no-repeat;
      padding: unset;
    }

    &.secondary-banner {
      background-image: url(../images/star-graphic-light.svg), url(../images/contact-us-banner-img.jpg);
      background-position: top left, center center;
      background-repeat: no-repeat, no-repeat;
      background-size: auto, cover;
      @include bp(lg-max) {
        background-image: url(../images/star-graphic-light.svg);
        background-color: transparent;
      }

      .more-info {
        @include bp(lg-max) {
          margin: 0 0 20px 0;
        }
      }

      .content-block {
        h1 {
          padding-right: 0px;
          color: $white;
          @include bp(lg-max) {
            color: $white;
          }
        }
      }

      .more-info {
        color: $white;
        letter-spacing: -0.25px;
        @include bp(lg-max) {
          color: $white;
        }
      }

      @include bp(sm-max) {
        padding: 35px 0 0px 0;
      }
    }
  }
}


.sfp-theme {
  .blog-card-section {
    &.related-blog-theme-grey {
      background-color: $sfp-light-gray-05;
      
      .blog-action {
        color: $sfp-primary-color !important;
      }

      .slide-card {
        background-color: $white;
      }
    }
  }

  .content-slice {
    @include bp(sm-max) {
      padding: 20px 0;
    }
    padding: 50px 0 50px 0;

    &.media-heading {
      .container {
        .content-slice-detail {
          border-bottom: 1px solid $light-gray-01;
        }
      }

      &.banner-image {
        @include bp(sm-max) {
          padding: 20px 0;
        }
        padding: 50px 50px 0 50px;

        .container {
          max-width: unset;
        }
      }
    }

    &.contact-form {
      .form-group, .bmd-form-group {
        label {
          color: $black;
        }
      }
    }
  }

  .two-card-section {
    .card-row {
      .card-block {
        .img-wrap {
          .file-icon {
            img {
              @include filter-sfp-primary-colour();
            }
          }
        }
      }
    }
  }

  .navbar-section {
    .navbar-block, .navbar-block1 {
      li {
        a::after {
          background-color: $sfp-primary-color;
        }
      }

      a {
        &.desk-link {
          &:hover {
            color: $sfp-primary-color !important;
          }
        }
      }

      &:after {
        background-color: $sfp-primary-color;
      }
    }
  }

  .accordion-wrapper {
    .accordion-block {
      .accordion-title:after {
        border-color: $sfp-primary-color;
      }

      .accordion-title:before {
        border-color: $sfp-primary-color;
      }

      .accordion-title[aria-expanded="true"] {
        h5 {
          color: $sfp-primary-color !important;
        }
      }
    }
  }

  .contact-wrapper {
    .form-group,
    .form-group.bmd-form-group {
      label {
        color: $black-shade-01;
      }
    }
  }

  .contact-detail {
    background-color: $sfp-primary-color;
    color: $white;
    margin-top: -85px;
    height: calc(100% + 85px);
    @include bp(sm-max) {
      border-bottom: 1px solid $white;
      margin: 0 -20px;
    }

    h4 {
      color: $white;
      letter-spacing: -0.74px;
      font-weight: 600;
      font-size: 26px;
    }

    .accordion {
      .card {
        .card-header {
          a {
            color: $white;
            font-weight: 400;
            @include bp(sm-max) {
              font-size: 18px;
              line-height: 20px;
            }

            i {
              &:after {
                border: 2px solid $white;
                border-top: 0;
                border-left: 0;
              }
            }
          }

          a[aria-expanded="true"] {
            color: $white;

            i {
              &:after {
                margin: 7px 0 0 0;
                @include rotate(225deg);
              }
            }
          }
        }
      }
    }
  }

  ul {
    &.info-btn-list {
      a {
        span {
          overflow: auto;
        }

        background-color: $white;
      }
    }
  }

  .select2-container {
    .select2-selection--single {
      .select2-selection__arrow {

      }
    }
  }
}

.sfp-theme {
  .custom-banner {
    background-image: url(../images/star-graphic-light.svg);
    background-position: top left, center center;
    background-repeat: no-repeat, no-repeat;
    background-size: auto, cover;

    h1 {
      color: $white;

      span {
        color: $white;
      }
    }
  }
}

.sfp-theme {
  .custom-banner {
    background-image: url(../images/star-graphic-light.svg);
    background-position: top left;
    background-repeat: no-repeat;
    background-size: auto;
  }
}

.theme-grey {
  .btn-wrapper {
    .view-btn {
      border-color: $white;
    }
  }
}

.sfp-theme {
  .rounded-btn {
    &:hover {
      background-color: $light-gray-10;
    }
  }

  .form-group, .form-check, .form-check-input:checked {
    .form-check-label {
      &:after {
        background-color: $sfp-primary-color;
        border-color: $sfp-primary-color;
      }
    }
  }

  .form-group,
  .form-group.bmd-form-group {
    label {
      color: $white;
    }

    .form-control {
      background-color: $white;

      &:focus {
        border-color: $sfp-primary-color;
      }
    }

  }

  .title {
    .text-blue {
      color: $sfp-primary-color;
    }

    .line-bottom {
      background-color: $sfp-primary-color;
    }
  }

  .swiper-container-horizontal {
    .swiper-scrollbar {
      .swiper-scrollbar-drag {
        background-color: $sfp-primary-color;
      }
    }
  }

  .select2-container {
    &.select2-container--open {
      .select2-selection--single {
        .select2-selection__arrow {
          border-color: $sfp-primary-color;
        }
      }
    }

    .select2-selection--single {
      &:focus {
        border-color: $sfp-primary-color;
      }
    }
  }

  .pagination {
    justify-content: flex-end;
    @include bp(sm-max) {
      justify-content: center;
    }

    .page-item {
      .page-link {
        &:hover {
          color: $sfp-primary-color;
        }
      }

      &.active {
        .page-link {
          background-color: $sfp-primary-color;
          border-color: $sfp-primary-color;
        }
      }
    }
  }

  .btn-wrapper {
    .view-btn {
      span {
        color: $sfp-primary-color;
      }

      &:focus {
        border-color: $sfp-primary-color;
      }

      .icon {
        &:before {
          background-color: $sfp-primary-color;
        }

        &.plus {
          &:after {
            background-color: $sfp-primary-color;
          }
        }
      }
    }
  }

  .accordian-wrapper {
    .custom-accordian {
      .accordian-title[aria-expanded=true] {
        color: $sfp-primary-color;
      }

      .accordian-title {
        &:after {
          border-color: $sfp-primary-color;
        }
      }
    }
  }

  .blue-link {
    color: $sfp-primary-color;
  }

  .form-block {
    background-color: $sfp-light-gray-05;
    border-color: $sfp-light-gray-05;
  }

  .stacked-card-section {
    .stacked-card-block {
      background-color: $sfp-light-gray-05;

      &:hover {
        background-color: $sfp-primary-color;
      }
    }
  }

  .wrapper {
    min-height: unset;
  }

  .pdf-download-section {
    .download-blocks {
      .download-card {
        .inner-wrap {
          i {
            img {
              @include filter-sfp-primary-colour();
            }
          }
        }

        .download-links {
          i {
            img {
              @include filter-sfp-primary-colour();
            }
          }
        }
      }
    }
  }

  .banner-section {
    &.fund-banner {
      background-image: url(../images/star-graphic-light.svg), url(../images/fund-banner-img.jpg);
      @include bp(lg-max) {
        background-size: contain, cover;
        background-color: $white;
      }
      @include bp(xs-max) {
        background-size: 45%, cover;
      }
    }

    &.campaigns-banner {
      background-image: url(../images/star-graphic-light.svg), url(../images/campaigns-banner-img.jpg);
      @include bp(lg-max) {
        background-color: $white;
        background-size: contain, cover;
      }
      @include bp(xs-max) {
        background-size: 45%, cover;
      }
    }

    &.blog-banner {
      background-image: url(../images/star-graphic-light.svg), url(../images/blog-banner.jpg);
      @include bp(lg-max) {
        background-size: contain, cover;
        background-color: $white;
      }
      @include bp(xs-max) {
        background-size: 45%, cover;
      }
    }

    &.contact-banner {
      background-image: url(../images/star-graphic-light.svg), url(../images/contact-us-banner-img.jpg);
      background-position: top left, center center;
      background-repeat: no-repeat, no-repeat;
      background-size: auto, cover;
      @include bp(lg-max) {
        background-color: $white;
        background-size: contain, cover;
      }
      @include bp(xs-max) {
        background-size: 45%, cover;
      }

      .more-info {
        @include bp(lg-max) {
          margin: 0 0 20px 0;
        }
      }

      .content-block {
        h1 {
          padding-right: 0px;
          color: $white;
        }
      }

      .more-info {
        color: $white;
        letter-spacing: -0.25px;
      }

      @include bp(sm-max) {
        padding: 35px 0 20px 0;
      }
    }

    .content {
      p {
        color: $white;
      }
    }
  }

  .custom-banner {
    background-image: url(../images/star-graphic-light.svg), url(../images/contact-us-banner-img.jpg);
    background-position: top left, center center;
    background-repeat: no-repeat, no-repeat;
    background-size: auto, cover;

    h1 {
      color: $white;

      span {
        color: $white;
      }
    }
  }

  .standalone-banner {
    background-image: url(../images/star-graphic-light.svg);
    background-position: top left, center center;
    background-repeat: no-repeat, no-repeat;
    background-size: auto, cover;
    @include bp(lg-max) {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .content-block {
      h1,
      p {
        color: $white;
      }
    }

    .profile-block {
      background-color: $sfp-primary-color;

      .content-block {
        h4 {
          color: $white;
        }

        .sub-title {
          color: $white;
        }

        .btn {
          border-color: rgba(255, 255, 255, 0.7) !important;
          background-color: $sfp-primary-color !important;
          color: $white !important;

          &:focus {
            border-color: $white !important;
            background-color: rgba(255, 255, 255, 0.2) !important;
          }

          &:active {
            box-shadow: none !important;
            border-color: $light-gray-01 !important;
          }

          &:hover {
            border-color: rgba(255, 255, 255, 0.7) !important;
            background-color: rgba(255, 255, 255, 0.2) !important;
            box-shadow: none !important;
          }

          &.disable {
            color: rgba(255, 255, 255, 0.5) !important;
            background-color: $sfp-primary-color !important;
            border-color: rgba(255, 255, 255, 0.5) !important;
            pointer-events: none;
          }

          .ripple-container {
            .ripple-decorator {
              &.ripple-on {
                background-color: rgba(255, 255, 255, 0.2) !important;
              }

              &.ripple-out {
                opacity: 0;
              }
            }
          }
        }

        .social-list {
          li {
            a {
              i {
                img {
                  &.light {
                    display: none;
                  }

                  &.dark {
                    display: block;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .tab-block {
    background-color: $sfp-light-gray-05;

    .title {
      h2 {
        color: $black;
      }
    }

    .contact-detail-tiles {
      background-color: $sfp-primary-color;
    }

    .nav-tabs {
      .nav-link {
        color: $white;

        &:after {
          @include bp(sm-max) {
            background-color: $white;
          }
          background-color: $sfp-primary-color;
        }
      }
    }
  }

  .financial-goals {
    .btn {
      &.btn-raised {
        &.btn-secondary {
          color: $white !important;
          border-color: $sfp-primary-color;
          background-color: transparent !important;

          &:hover {
            background-color: $sfp-primary-color !important;
          }
        }
      }
    }

    .head-title {
      font-family: $roboto;
      color: $white;
    }

    p {
      font-family: $roboto;
      color: $light-gray-06;
    }

    .content-block {
      .btn-primary {
        border-color: $sfp-primary-color !important;
      }
    }
  }

  .tools-calculators {
    .title {
      h2 {
        color: $black;
      }
    }

    p {
      color: $sfp-primary-color;
    }

    .tools-wrapper {
      .col-sm {
        background-color: $sfp-light-gray-05;

        &:hover {
          background-color: $sfp-primary-color;

          p {
            color: $sfp-primary-color;
          }
        }
      }
    }
  }

  .team-listing {
    > ul {
      > li {
        p {
          color: $sfp-primary-color;
        }

        .img-div {
          .hover-div {
            .rounded-btn {
              &:hover {
                background-color: rgba(0, 119, 139, .1);
              }
            }
          }
        }
      }
    }
  }

  .client-testimonial-slider {
    .testimonial-slide {
      background-color: $sfp-light-gray-05;

      .client-intro {
        h6 {
          color: $sfp-primary-color;
        }
      }
    }
  }

  .single-client-testimonial-slider {
    .testimonial-slide {
      .client-intro {
        h6 {
          color: $sfp-primary-color !important;
        }
      }
    }
  }

  .connection-section {
    .content-block {
      .line-bottom {
        background-color: $sfp-primary-color;
      }
    }
  }

  .blog-listing-section {
    .category-listing-block {
      background-color: $sfp-primary-color;

      .circle-list {
        li {
          color: $white;

          &:before {
            border-color: $white;
            background-color: $white;
          }
        }
      }
    }

    .blog-listing-wrap {
      background-color: $sfp-light-gray-05;

      .card-content {
        background-color: $white;
      }

      .news-cards {
        padding-top: 50px;
      }

      .category-listing-block {
        background-color: $sfp-primary-color;

        .tags-block {
          p {
            a {
              color: $white;
            }
          }
        }

        .circle-list {
          li {
            color: $white;

            h5 {
              color: $white;
            }

            a {
              color: $white;
            }

            &:before {
              border-color: $white;
              background-color: $white;
            }
          }
        }

        h5 {
          color: $white;
        }

        p {
          color: $white;
        }

        .btn-block {
          .show-btn {
            span {
              color: $white;
            }

            .icon.plus {
              &:before {
                background-color: $white;
              }

              &:after {
                background-color: $white;
              }
            }

            .icon.minus {
              &:before {
                background-color: $white;
              }

              &:after {
                background-color: $white;
              }
            }
          }
        }
      }
    }
  }

  .contact-detail {
    background-color: $sfp-primary-color;
    color: $white;
    @include bp(sm-max) {
      border-bottom: 1px solid $white;
      margin: 0 -20px;
    }

    h4 {
      color: $white;
      letter-spacing: -0.74px;
      font-weight: 600;
      font-size: 26px;
    }

    .accordion {
      .card {
        .card-header {
          a {
            color: $white;
            font-weight: 400;
            @include bp(sm-max) {
              font-size: 18px;
              line-height: 20px;
            }

            i {
              &:after {
                border: 2px solid $white;
                border-top: 0;
                border-left: 0;
              }
            }
          }

          a[aria-expanded="true"] {
            color: $white;

            i {
              &:after {
                margin: 7px 0 0 0;
                @include rotate(225deg);
              }
            }
          }
        }
      }
    }
  }

  .contact-wrapper {
    .form-group,
    .form-group.bmd-form-group {
      label {
        color: $black-shade-01;
      }
    }
  }

  .contact-form {
    .btn-wrap {
      p {
        a {
          color: $sfp-primary-color;
        }
      }
    }
  }

  .info-btn-list {
    a {
      background-color: $white;
    }
  }

  .offer-block {
    .offer-tiles-wrapper {
      .offer-tiles {
        background-color: $sfp-light-gray-05;

        &:hover {
          background-color: $sfp-primary-color;

          p {
            color: $white;
          }
        }
      }
    }
  }

  .slider-section {
    .swiper-wrapper {
      .swiper-slide {
        p {
          color: $sfp-primary-color;
        }

        i {
          &:hover {
            background-color: rgba(0, 119, 139, .1);
          }
        }
      }
    }
  }

  .custom-list {
    li {
      &:before {
        border: 3px solid $sfp-primary-color;
      }
    }

    &.disk-style {
      li {
        &:before {
          border: 3px solid $blue-05;
        }
      }
    }
  }

  .card-slider-section {
    .card-slider {
      .slide-card {
        background-color: $sfp-light-gray-05;
      }
    }
  }

  .card-slider {
    .slide-card {
      background-color: $sfp-light-gray-05;
    }
  }

  .two-card-section {
    .card-block {
      background-color: $sfp-light-gray-05;
    }
  }

  .product-card-section {
    .label-card-block {
      background-color: $sfp-light-gray-05;

      .price-block {
        p {
          span {
            color: $sfp-primary-color;
          }
        }
      }
    }
  }

  .media-slider {
    .media-slide {
      .label {
        color: $sfp-primary-color;
      }
    }
  }

  .download-blocks {
    .download-card {
      background-color: $sfp-light-gray-05;
    }
  }

  .accordian-wrapper {
    .accordian-title {
      &:after {
        border-color: $sfp-primary-color;
      }
    }
  }

  .fund-offer-section {
    .content {
      p {
        a {
          color: $sfp-primary-color;
        }
      }
    }
  }

  .news-cards {
    .news-card {
      background-color: $sfp-light-gray-05;
    }
  }

  .three-cols-block {
    .row {
      .column {
        .column-inner {
          background-color: $sfp-light-gray-05;

          h5 {
            color: $sfp-primary-color;
          }
        }
      }
    }
  }

  .has-custom-scroll {
    .mCSB_inside {
      .mCSB_scrollTools {
        background-color: $sfp-light-gray-05;

        .mCSB_dragger {
          .mCSB_dragger_bar {
            background-color: $sfp-primary-color;
          }
        }
      }
    }
  }
}

.sfp-theme {
  .blog-listing-wrap {
    .category-listing-wrapper {
      .btn {
        &.btn-raised {
          &.btn-secondary {
            color: $white !important;
            border-color: $sfp-primary-color;
            background-color: $sfp-primary-color !important;

            &:hover {
              background-color: $sfp-primary-color !important;
            }
          }
        }
      }
    }
  }

  .campaigns-listing {
    background-color: $sfp-light-gray-05;

    .news-cards {
      .news-card {
        .card-content {
          background-color: $white;
        }
      }

      padding-top: 50px;
    }
  }

  .theme-grey {
    .three-cols-block {
      .row {
        .column {
          .column-inner {
            background-color: $white;
          }
        }
      }
    }

    background-color: $sfp-light-gray-05;

    &.stacked-card-section {
      .stacked-card-block {
        background-color: $white;

        &:hover {
          background-color: $sfp-primary-color;
        }
      }
    }

    .card-slider {
      .slide-card {
        background-color: $white;
      }
    }

    &.two-card-section {
      .card-block {
        background-color: $white;
      }
    }

    &.product-card-section {
      .label-card-block {
        background-color: $white;

        .card-label {
          span {
            background-color: $light-gray-05;
          }
        }
      }
    }

    .form-block {
      background-color: $white;
      border-color: $light-gray-01;
    }

    .testimonial-slider,
    .client-testimonial-slider {
      .testimonial-slide {
        background-color: $white;
      }
    }

    &.offer-block {
      .offer-tiles-wrapper {
        .offer-tiles {
          background-color: $white;

          &:hover {
            background-color: $sfp-primary-color;
          }

          .btn {
            &.btn-raised {
              &.btn-secondary {
                background-color: $white !important;

                &:hover {
                  background-color: $light-gray-07 !important;
                }
              }
            }
          }
        }
      }
    }

    &.tools-calculators {
      .tools-wrapper {
        .col-sm {
          background-color: $white;

          &:hover {
            background-color: $sfp-primary-color;
          }
        }
      }
    }

    &.about-section {
      .about-text-wrap {
        .expandable-block {
          background-color: $light-gray-06;
        }
      }
    }

    .download-blocks {
      .download-card {
        background-color: $white;
      }
    }

    .news-cards {
      .news-card {
        background-color: $white;
      }
    }

    .media-cards {
      .media-card {
        background-color: $white;
      }
    }

    .btn.btn-raised.btn-secondary {
      background-color: transparent !important;

      &:hover {
        background-color: $light-gray-07 !important;
      }
    }

    &.blog-listing-section {
      .category-listing-block {
        background-color: $white;
      }
    }

    .btn-wrapper {
      .view-btn {
        border-color: $white;
      }
    }
  }
}