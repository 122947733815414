//Break points
@mixin bp($point) {
  $xxxs-max: "(max-width: 320px)";
  $xxxs-min: "(max-width: 375px)";
  $xxs-max: "(max-width: 480px)";
  $xs-max: "(max-width: 575px)";
  $xs-min: "(min-width: 576px)";
  $sm-max: "(max-width: 767px)";
  $md-min: "(min-width: 768px)";
  $md-max: "(max-width: 991px)";
  $lg-min: "(min-width: 992px)";
  $tablet-max:"(max-width: 1024px)";
  $tablet-min:"(min-width: 1025px)";
  $lg-max: "(max-width: 1199px)";
  $xlg-max: "(max-width: 1365px)";
  $xl-min: "(min-width: 1690px)";
  $xl-max: "(max-width: 1439px)";
  $xxl-max: "(max-width: 1499px)";
  $xxl-min: "(min-width: 1499px)";
  @if $point==xxxs-max {
    @media #{$xxxs-max} {
      @content;
    }
  }
  @else if $point==xxxs-min {
    @media #{$xxxs-min} {
      @content;
    }
  }
  @else if $point==xxs-max {
    @media #{$xxs-max} {
      @content;
    }
  }
  @else if $point==xs-max {
    @media #{$xs-max} {
      @content;
    }
  }
  @else if $point==xs-min {
    @media #{$xs-min} {
      @content;
    }
  }
  @else if $point==sm-max {
    @media #{$sm-max} {
      @content;
    }
  }

  @else if $point==md-min {
    @media #{$md-min} {
      @content;
    }
  }

  @else if $point==md-max {
    @media #{$md-max} {
      @content;
    }
  }
  @else if $point==lg-min {
    @media #{$lg-min} {
      @content;
    }
  }
  @else if $point==tablet-max {
    @media #{$tablet-max} {
      @content;
    }
  }
  @else if $point==tablet-min {
    @media #{$tablet-min} {
      @content;
    }
  }
  @else if $point==lg-max {
    @media #{$lg-max} {
      @content;
    }
  }
  @else if $point==xlg-max {
    @media #{$xlg-max} {
      @content;
    }
  }
  @else if $point==xl-min {
    @media #{$xl-min} {
      @content;
    }
  }
  @else if $point==xl-max {
    @media #{$xl-max} {
      @content;
    }
  }
  @else if $point==xxl-max {
    @media #{$xxl-max} {
      @content;
    }
  }
  @else if $point==xxl-min {
    @media #{$xxl-min} { 
      @content;
    }
  }
}