.top-header {
  background-color: $light-gray-01;
  display: flex;
  justify-content: space-between;
  @include transition(all 0.3s);
  @include bp(lg-max) {
    display: none;
  }
  ul {
    display: flex;
    li {
      a {
        font-family: $roboto;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 1px;
        font-weight: 500;
        padding: 11px 15px;
        display: inline-block;
        color: $dark-gray-03;
        text-transform: uppercase;
      }
    }
    &.right-header {
      li {
        a {
          &:hover {
            color: $primary-color;
          }
        }
      }
    }
    &.left-header {
      li {
        a {
          padding: 11px 30px;
          &.active {
            background-color: $white;
            color: $primary-color;
          }
        }
      }
    }
  }
}
header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 101;
  background-color: $white;
  @include transition(all 0.5s);
  @include bp(lg-max) {
    position: static;
  }

  &.small-header {
    top: -40px;
    .bottom-header {
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
      -webkit-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
      -ms-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
      min-height: 88px;
      padding: 10px 15px 10px 15px;
      .primary-logo {
        display: none;
      }
      .nav {
        .nav-item {
          .nav-link{
            padding: 10px 25px 20px 25px;
          }
        }
      }
      .login-block {
        margin: 0 0 0 0;
        a{
          padding: 10px 10px 10px 10px;
        }
      }
    }
  }
  .bottom-header {
    background: $white;
    @include transition(all 0.5s);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 15px 15px 15px;
    @include bp(lg-max) {
      min-height: 100px;
      padding: 10px 15px 10px 15px;
    }
    @include bp(sm-max) {
      padding: 10px 0;
    }
    @include bp(xs-max) {
      min-height: 80px;
    }
    .container-fluid {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .left-bottom-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .logo {
    display: flex;
    align-items: center;
    margin: 0 30px 0 0;
    @include bp(lg-max) {
      margin: 0 auto;
      max-width: 150px !important;
    }
    @include bp(xs-max) {
      margin: 0 0 0 -15px;
      max-width: 131px !important;
    }
    img {
      width: 100%;
      height: auto;
      max-width: 200px;
    }
  }
  .nav {
    height: 100%;
    @include transition(all 0.3s);
    @include bp(lg-max) {
      position: fixed;
      background: $white;
      top: 0;
      height: auto;
      max-height: 100%;
      left: 0;
      width: 0;
      z-index: 9;
      right: 0;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      overflow-y: auto;
      padding: 0 0 0 0;
    }
    .menu-header {
      display: flex;
      align-items: center;
      padding: 10px 25px 10px 25px;
    }
    .close-btn {
      position: absolute;
      top: 17px;
      right: 22px;
      display: block;
      cursor: pointer;
    }

    .top-header-link {
      display: none;
      border-bottom: 1px solid $light-gray-01;
      @include bp(lg-max) {
        display: flex;
        width: 100%;
        background-color: $light-gray-06;
        i {
          width: 16px;
        }
        a.nav-link {
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: space-between;
          text-transform: uppercase;
          font-weight: 400;
        }
      }
    }
    .nav-item {
      position: relative;
      @include bp(lg-max) {
        border-top: 1px solid $light-gray-01;
        &:last-child {
          border-bottom: 1px solid $light-gray-01;
        }
      }
      .nav-link {
        font-family: $roboto;
        color: $dark-gray-04;
        font-size: 18px;
        line-height: 21px;
        padding: 31px 25px 31px 25px;
        text-transform: none;
        position: relative;
        @include bp(xlg-max) {
          padding: 20px 15px;
        }
        @include bp(lg-max) {
          display: block;
          padding: 15px 25px;
          font-weight: 700;
        }
        &:after {
          content: "";
          left: 24px;
          right: 24px;
          height: 2px;
          width: 0;
          position: absolute;
          background: $primary-color;
          bottom: 0;
          @include bp(lg-max) {
            display: none;
          }
        }
        &:hover,
        &.active {
          color: $primary-color;
          &:after {
            width: calc(100% - 48px);
          }
        }
      }

      &.has-submenu {

        .nav-link {
          @include bp(lg-max) {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
          }
          &.active {
            ~ .down-arrow {
              &:after {
                border-color: $primary-color;
              }
            }
          }
        }

        &.open {
          .down-arrow {
            @include rotate(180deg);
          }
        }

        .down-arrow {
          display: none;
          width: 17px;
          height: 15px;
          position: relative;
          margin-left: 10px;
          cursor: pointer;
          @include transition(all 0.5s);

          &:after {
            content: "";
            position: absolute;
            width: 10px;
            height: 10px;
            display: block;
            top: 0;
            right: 3px;
            border: 2px solid $dark-gray-04;
            border-top: 0;
            border-left: 0;
            @include rotate(45deg);
            @include transition(all 0.3s);
          }

          @include bp(lg-max) {
            display: block;
          }
        }

        &:hover {
          .nav-link {
            &:after {
              width: calc(100% - 48px);
            }
          }
          .hover-block {
            opacity: 1;
            visibility: visible;
            pointer-events: auto;

            .inner-links {
              @include rotateX(0deg);
            }
          }
        }

        .hover-block {
          position: absolute;
          top: 100%;
          left: 25px;
          min-width: 200px;
          perspective: 1000px;
          opacity: 0;
          visibility: hidden;
          pointer-events: none;
          @include transition(all 0.3s ease-in-out);

          @include bp(xlg-max) {
            left: 15px;
          }

          @include bp(lg-max) {
            perspective: none;
            opacity: 1;
            visibility: visible;
            position: static;
            min-width: auto;
            width: 100%;
            @include translateX(0);
            @include transition(none);
            display: none;
          }

          &.has-high-width {
            min-width: 300px;

            @include bp(lg-max) {
              min-width: auto;
            }
          }

          .inner-links {
            list-style: none;
            background-color: #ffffff;
            padding: 25px 20px;
            border-radius: 5px;
            box-shadow: 0 0 10px rgba(50,50,93,0.2), 0 20px 10px -20px rgba(0,0,0,0.3);
            @include rotateX(-15deg);
            @include transition(all 0.3s ease-in-out);

            @include bp(lg-max) {
              padding: 0;
              padding-bottom: 25px;
              padding-left: 35px;
              border-radius: 0;
              box-shadow: none;
              @include rotateX(0deg);
            }

            li {
              list-style: none;
              margin: 0 0 17px;

              @include bp(lg-max) {
                margin: 0 0 12px;
              }

              &:last-child {
                margin: 0;
              }

              a {
                font-size: 16px;
                line-height: 1.3;
                color: $secondary-color;
                display: block;

                @include bp(lg-max) {
                  font-weight: 500;
                }

                &:hover {
                  color: $primary-color;
                }

                &:after {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
  .login-block {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    a {
      color: $yellow-01;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: -0.2px;
      padding: 33px;
      @include bp(xlg-max) {
        padding: 0;
      }
      i {
        width: 16px;
        display: none;
        @include bp(lg-max) {
          display: block;
        }
      }
      span {
        @include bp(lg-max) {
          display: none;
        }
      }
      &:hover {
        color: $primary-color;
      }
    }
  }
}

.hamburger-icon {
  padding-top: 35px;
}

.hamburger-menu {
  position: relative;
  height: 30px;
  width: 30px;
  @include transition(all 0s);
  &.fixed {
    position: fixed;
    background: $primary-color;
    top: 55px;
    height: 48px;
    right: 20px;
    width: 48px;
    z-index: 5;
    border-radius: 100%;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.24);
    z-index: 5;
    @include transition(all 0.3s);
    .hamburger {
      background: $white;
      &::after,
      &::before {
        background: $white;
      }
    }
  }
  .hamburger {
    top: 50%;
    transform: translateY(-50%);
  }
}

.hamburger {
  display: block;
  position: absolute;
  top: 13px;
  width: 18px;
  height: 2px;
  background: $primary-color;
  border-radius: 5px;

  &:before,
  &:after {
    content: "";
    position: absolute;
    display: block;
    width: 18px;
    height: 2px;
    background: $primary-color;
    border-radius: 5px;
    @include transition(all 0s);
  }

  &:before {
    top: 4px;
  }

  &:after {
    bottom: 4px;
  }
}

.clicked {
  .hamburger {
    background: transparent;
    @include transition(all 0.3s);

    &:before {
      top: 0;
      @include rotate(45deg);
      @include transition(all 0.3s);
    }
    &:after {
      bottom: 0;
      @include rotate(-45deg);
      @include transition(all 0.3s);
    }
  }
}
.open-menu {
  header {
    .nav {
      width: 100%;
    }
  }
}
.secondary-header{
  .logo{
    max-width: 113px;
  }
  .bottom-header{
    padding: 10px 15px;
    min-height: 87px;
  }
  .login-block {
    a{
      padding: 10px 10px 10px 10px;
    }
  }
  .nav {
    .nav-item {
      .nav-link{
        padding: 15px 25px;
        font-size: 18px;
        line-height: 21px;
      }
    }
  }
}