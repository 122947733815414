footer {
  background-color: $white;
  padding: 90px 0 90px 0;
  @include bp(sm-max) {
    padding: 45px 0 50px 0;
  }
  border-top: 1px solid $light-gray-01;
  .footer-logo {
    display: inline-block;
    margin: 0 0 10px 0;
    @include bp(sm-max) {
      max-width: 130px;
      img {
        max-width: 100%;
      }
    }
  }
  ul {
    @include bp(sm-max) {
      margin: 0 0 40px 0;
    }
    &.more-space {
      li {
        margin: 0 0 17px 0;
        &:last-child {
          margin: 0 0 0 0;
        }
      }
    }
    li {
      margin: 0 0 7px 0;
      @include bp(sm-max) {
        margin: 0 0 10px 0;
      }
      a,
      p {
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.2px;
        color: $dark-gray-01;
        display: flex;
        align-items: flex-start;
        &.align-center {
          align-items: center;
        }
        i {
          margin: 0 15px 0 0;
          display: inline-block;
          width: 20px;
          min-width: 20px;
          text-align: center;
          @include bp(sm-max) {
            width: 16px;
            min-width: 16px;
          }
          img {
            height: auto;
            width: auto;
            @include bp(sm-max) {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      p {
        &.address-line {
          max-width: 190px;
        }
      }
      &.footer-title {
        margin: 0 0 25px;
        @include bp(sm-max) {
          margin: 0 0 20px 0;
        }
        a, span {
          font-size: 18px;
          color: $primary-color;
          font-weight: 500;
          @include bp(sm-max) {
            font-weight: 700;
          }
        }
      }
    }
  }
  .top-footer {
    padding: 0 0 35px;
    @include bp(sm-max) {
      padding: 0 0 0 0;
    }
    .right-footer {
      @include bp(sm-max) {
        order: 0;
        flex: 0 0 100%;
        max-width: 100%;
        margin: 0 0 20px 0;
      }
    }
    .media-icon {
      display: flex;
      justify-content: flex-end;
      a {
        i {
          margin: 0 5px 0 0;
          @include bp(sm-max) {
            margin: 0 10px 0 0;
          }
          img {
            width: 18px;
          }
        }
        &:last-child {
          i {
            margin: 0 0 0 0;
          }
        }
      }
      @include bp(sm-max) {
        justify-content: flex-start;
      }
    }
  }
  .bottom-footer {
    padding: 35px 0 0 0;
    border-top: 1px solid $light-gray-03;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include bp(sm-max) {
      flex-wrap: wrap;
      padding: 30px 0 0 0;
    }
    .left-footer {
      flex: 0 0 100%;
      max-width: 100%;
      display: flex;
      @include bp(sm-max) {
        flex-direction: column-reverse;
        align-items: flex-start;
      }
      img {
        @include bp(sm-max) {
          max-width: 113px;
        }
      }
      .content {
        @include bp(sm-max) {
          margin: 0 0 20px 0;
          width: 100%;
        }
      }
      img {
        margin: 0 32px 0 0;
      }
    }
    p {
      color: $dark-gray-01;
      font-size: 12px;
      line-height: 20px;
    }
  }
  .rounded-btn {
    height: 35px;
    width: 35px;
  }
}