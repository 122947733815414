.standalone-banner {
  background-image: url(../images/star-graphic-light.svg);
  background-position: top left;
  background-repeat: no-repeat;
  background-size: auto;
  padding: 40px 0 0;

  .content-block {
    justify-content: flex-start;
    max-width: 450px;
    width: 100%;

    .btn {
      z-index: 1;
    }

    h1 {
      margin: 0 0 20px 0;
    }
  }
}
.sfp-theme {
  &.standalone-banner {
    background-image: url(../images/star-graphic-light.svg);
    background-position: top left, center center;
    background-repeat: no-repeat, no-repeat;
    background-size: auto, cover;
  }
}

.profile-block {
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 4px;
  background-color: $white;
  box-shadow: 0 11px 20px 0 rgba(7, 25, 37, 0.08);
  padding: 32px 40px 32px 40px;
  margin-bottom: -20px;
  @include bp(sm-max) {
    padding: 30px 20px 30px 20px;
  }
  .img-block {
    height: 188px;
    max-width: 188px;
    margin: 0 auto 32px;
  }
  .content-block {
    @include bp(xl-min) {
      padding: 0 0 0 0;
    }
    @include bp(sm-max) {
      .btn-secondary {
        min-width: 100%;
      }
    }
    h4 {
      text-align: center;
      font-weight: 600;
      letter-spacing: -0.8px;
      margin: 0 0 5px 0;
      font-size: 28px;
      @include bp(sm-max) {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.6px;
      }
    }
    .sub-title {
      font-size: 28px;
      font-weight: 300;
      letter-spacing: -0.8px;
      line-height: 32px;
      text-align: center;
      display: block;
      margin: 0 0 20px;
      @include bp(md-max) {
        font-size: 26px;
      }
      @include bp(sm-max) {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.6px;
      }
    }
    .social-list {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0 15px 0;
      flex-wrap: wrap;
      li {
        padding: 0 5px;
        position: relative;
        &:first-child {
          padding: 0 5px 0 0;
        }
        &:last-child {
          padding: 0 0 0 5px;
          border-right: none;
          @include bp(md-max) {
            padding: 0 0 0 5px;
          }
          &:after {
            display: none;
          }
        }
        &:after {
          content: "";
          position: absolute;
          height: 20px;
          width: 1px;
          background: $light-gray-01;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        a {
          display: inline-block;
          i {
            img {
              width: 20px;
              height: 20px;
              &.light {
                display: block;
              }
              &.dark {
                display: none;
              }
            }
          }
        }
      }
    }
    .btn {
      min-width: 190px;
      margin: auto;
    }
  }
}
.tab-block {
  background-color: $light-gray-05;
  margin: 0px 0 0 0;
  padding: 0 0 128px;
  @include bp(sm-max) {
    margin: 0 0 0 0;
    padding: 0 0 0px;
    background: none;
  }
  .row {
    @include bp(sm-max) {
      flex-direction: column-reverse;
    }
  }
  .nav-tabs {
    position: relative;
    top: -77px;
    margin: 0 -24px;
    @include bp(md-max) {
      margin: 0 -18px;
    }
    @include bp(sm-max) {
      top: 0;
    }
    @include bp(xxxs-min) {
      margin: 0 -8px;
      justify-content: space-between;
      flex-wrap: nowrap;
    }
    .nav-item {
      @include bp(tablet-max) {
        min-width: auto;
      }
    }
    .nav-link {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      padding: 10px 18px;
      letter-spacing: -0.2px;
      text-transform: none;
      border: none;
      position: relative;
      display: inline-block;
      @include bp(md-max) {
        padding: 10px 16px;
      }
      @include bp(sm-max) {
        font-size: 14px;
        line-height: 20px;
        color: $black !important;
      }
      @include bp(xxxs-min) {
        padding: 10px 8px;
      }
      .ripple-container {
        display: none;
      }
      &:after {
        content: "";
        left: 24px;
        right: 24px;
        height: 2px;
        width: 0;
        position: absolute;
        background: $primary-color;
        bottom: 0;
        @include bp(md-max) {
          left: 20px;
          right: 20px;
        }
        @include bp(xxxs-min) {
          left: 8px;
          right: 8px;
        }
      }
      &:hover,
      &.active {
        &:after {
          width: calc(100% - 48px);
          @include bp(md-max) {
            width: calc(100% - 40px);
          }
          @include bp(xxxs-min) {
            width: calc(100% - 16px);
          }
        }
      }
    }
  }
  .tab-content {
    padding: 55px 0 0 0;
    @include bp(sm-max) {
      background-color: $light-gray-05;
      margin: 20px -20px 0;
      padding: 85px 20px 50px;
    }
    .title {
      margin: 0 0 24px 0;
    }
    .description {
      padding: 0 90px 0 0;
      @include bp(md-max) {
        padding: 0 0 0 0;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.2px;
        margin: 0 0 12px 0;
        color: $dark-gray-05;
      }
      a {
        color: $primary-color;
        font-weight: 600;
        display: inline-block;
        margin: 3px 0 0 -7px;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.2px;

      }
      .text-btn {
        display: inline-flex;
      }
    }
  }
  .contact-detail-outer {
    position: relative;
  }
  &.with-profile-outer {
    margin: 0 0 0 0;
    background-color: transparent;
    padding: 285px 0 0 0;
    background-image: url(../images/star-graphic.svg);
    background-repeat: no-repeat;
    @include bp(sm-max) {
      padding: 0 0 0 0;
      background: none;
    }
    .tab-content {
      padding: 23px 0 0 0;
      @include bp(sm-max) {
        padding: 70px 20px 50px 20px;
      }
      .description {
        padding: 0 0 0 0;
      }
    }
  }
  .with-profile {
    background-color: $light-gray-05;
    padding: 0 0 140px 0;
    @include bp(sm-max) {
      margin: 0 0 0 0;
      padding: 0 0 0 0;
      background: none;
    }
    .row {
      @include bp(sm-max) {
        flex-direction: column;
      }
    }
    .profile-block {
      margin: -250px 0 0 0;
      padding: 30px 40px 30px;
      @include bp(sm-max) {
        margin: 0 0 0 0;
        padding: 30px 20px 30px 20px;
      }
      .content-block {
        display: flex;
        flex-direction: column;
      }
    }
    .custom-list {
      margin: 0 0 20px 0;
      li {
        padding: 3px 0 3px 35px;
        &:before {
          top: 6px;
        }
      }
    }
    .tabs-outer {
      padding: 0 0 0 112px;
      @include bp(lg-max) {
        padding: 0 0 0 30px;
        @include bp(sm-max) {
          padding: 40px 0 0 0px;
        }
      }
    }
    .contact-detail-tiles {
      margin: 15px 0 0 0;
      @include bp(sm-max) {
        padding: 18px 15px;
        margin: 25px 0 0 0;
      }
    }
  }
}
.contact-detail-tiles {
  background-color: $white;
  margin: 37px 0 0 0;
  padding: 33px 33px 15px 33px;
  box-shadow: 0 11px 20px rgba(7, 25, 37, 0.08);
  border-radius: 4px;
  position: relative;
  z-index: 1;
  @include bp(sm-max) {
    margin: 25px 0 50px 0;
    padding: 30px 15px;
  }
  h5 {
    line-height: 24px;
    font-size: 18px;
    color: $white;
    letter-spacing: -0.4px;
    margin: 0 0 22px 0;
    @include bp(sm-max) {
      font-size: 18px;
      line-height: 24px;
      font-weight: 700;
    }
  }
  .info-btn-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
      flex: 0 0 100%;
      width: 100%;
      a {
        margin: 0 0 16px 0;
        @include bp(xxxs-max) {
          padding: 15px 10px;
          font-size: 14px;
        }
        i {
          width: 20px;
          min-width: 20px;
          text-align: center;
          &.sm-icon {
            img {
              width: 14px;
              min-width: 14px;
            }
          }
          &.location-icon {
            margin: 0px 10px 0 0;
          }
        }
      }
      &.width-50 {
        flex: 0 0 calc(50% - 8px);
        width: calc(50% - 8px);
        @include bp(lg-max) {
          flex: 0 0 100%;
          width: 100%;
        }
      }
    }
  }
  .tooltip-wrapper {
    display: none;
    @include bp(sm-max) {
      display: block;
    }
  }
}
.tools-calculators {
  &.less-spacing {
    padding: 122px 0 150px;
    @include bp(lg-max) {
      padding: 100px 0 50px;
    }
    @include bp(sm-max) {
      padding: 50px 0 50px;
    }
  }
}
.custom-list {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.2px;
  margin: 0 0 30px 0;
  list-style: none;
  li {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.2px;
    margin: 0 0 10px 0;
    padding: 0 0 0 35px;
    position: relative;
    &:before {
      content: "";
      height: 12px;
      width: 12px;
      border: 3px solid $primary-color;
      border-radius: 100%;
      display: inline-block;
      margin-right: 10px;
      position: absolute;
      top: 3px;
      left: 8px;
    }
  }
}
.custom-ordered-list {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.2px;
  margin: 0 0 30px 0;
  list-style: -moz-ethiopic-numeric;
  li {
    color: $dark-gray-02;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.2px;
    margin: 0 0 10px 0;
    padding: 0 0 0 10px;
    position: relative;
    left: 25px;
    &::marker {
      color: $dark-gray-05;
    }
  }
}
.view-more-less {
  a {
    font-size: 16px;
    color: $primary-color;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.2px;
    margin: 0 0 14px 0;
    display: block;
  }
  .text-block {
    padding: 30px 25px 35px 40px;
    background-color: $light-gray-06;
    margin: 0 0 30px 0;
    p {
      font-size: 14px;
      color: $dark-gray-02;
      line-height: 20px;
      letter-spacing: -0.2px;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        height: 6px;
        width: 6px;
        background-color: $primary-color;
        border-radius: 100%;
        left: -20px;
        top: 6px;
      }
    }
  }
  .less-btn {
    display: none;
  }
  .view-less-wrap {
    display: none;
  }
  &.read-more {
    .show-more {
      display: none;
    }
  }
  .show-btn {
    border: 2px solid $primary-color;
    background-color: transparent;
    border-radius: 4px;
    padding: 8px 10px 8px 10px;
    margin: 10px 0 30px 0;
    width: 162px;
    font-size: 14px;
    font-weight: 500;
    color: $primary-color;
    line-height: 20px;
    letter-spacing: -0.2px;
    cursor: pointer;
    text-transform: none;
    &:hover {
      border: 2px solid $primary-color;
      background-color: $light-gray-07 !important;
    }
    &:focus {
      border: 2px solid $primary-color;
      background-color: transparent !important;
    }
    @include bp(sm-max) {
      width: 100%;
    }
    &.show-more {
      span {
        &:after {
          content: "";
          width: 14px;
          height: 2px;
          background-color: $primary-color;
          display: inline-block;
          position: absolute;
          top: 8px;
          left: 0px;
          @include rotate(90deg);
        }
        &:before {
          top: 6px;
        }
      }
    }
    &.show-less,
    &.show-more {
      span {
        position: relative;
        width: 20px;
        display: inline-flex;
        height: 14px;
        &:before {
          content: "";
          width: 14px;
          height: 2px;
          background-color: $primary-color;
          display: inline-block;
          position: absolute;
          top: 8px;
          left: 0px;
        }
      }
    }
  }
  .more-less-btn {
    display: block;
    width: 100%;
  }
}
.tooltip-wrapper {
  position: absolute;
  right: 20px;
  .btn-tooltip {
    border: none;
    background: none;
    i {
      max-width: 10px;
      height: 16px;
    }
  }
  .tooltip-modal {
    position: absolute;
    margin: 10px 0 0 0;
    padding: 15px 20px 15px 20px;
    background-color: $white;
    color: $black-shade-02;
    min-width: 260px;
    right: 0;
    border-radius: 4px;
    box-shadow: 0 0 11px 2px rgba(7, 25, 37, 0.08);
    opacity: 0;
    visibility: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
    }
    button {
      border: none;
      background: transparent;
    }
    i {
      max-width: 14px;
    }
  }
  &.show {
    .tooltip-modal {
      opacity: 1;
      visibility: visible;
    }
  }
}

.theme-grey {
  .three-cols-block {
    margin-top: 30px;
    .row {
      .column {
        .column-inner {
          background-color: $white;
        }
      }
    }
  }
}

.three-cols-block {
  margin-top: 30px;
  .row {
    display: flex;
    margin: 0 -24px;
    @include bp(md-max) {
      margin: 0 -20px;
    }
    @include bp(md-max) {
      margin: 0;
    }

    .column {
      flex: 0 0 33.3333%;
      max-width: 33.33333%;
      padding: 0 24px;
      @include bp(md-max) {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 20px;
        margin-bottom: 16px;
      }
      @include bp(md-max) {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0;
        margin-bottom: 16px;
      }
      .column-inner {
        background-color: $white;
        padding: 35px 40px 41px;
        height: 100%;
        @include bp(sm-max) {
          padding: 30px 25px;
        }
        h5 {
          color: $primary-color;
          font-size: 14px;
          font-weight: 600;
          line-height: 15.4px;
          letter-spacing: 2px;
          margin-bottom: 33px;
          text-transform: uppercase;
        }
        .inner-col-block {
          margin-bottom: 27px;
          h6 {
            font-size: 16px;
            line-height: 1.5;
            font-weight: 700;
            letter-spacing: -0.18px;
            color: $black;
            a {
              font-size: 16px;
              line-height: 1.5;
              font-weight: 700;
              letter-spacing: -0.18px;
              color: $black;
            }
          }
          p {
            font-size: 14px;
            line-height: 1.5;
            letter-spacing: -0.17px;
            color: $dark-gray-01;
            text-transform: uppercase;
          }
        }
        .read-more {
          a {
            // font-size: 14px;
            // color: $primary-color;
            // font-weight: 600;
            letter-spacing: -0.17px;
            line-height: 1.71;
          }
        }
      }
    }
  }
}
.blog-listing-wrap {
  .col-md-8 {
    .news-cards {
      .row {
        &.blog-listings {
          @include bp(sm-max) {
            margin: 0 0 0 0;
          }  
        }
      }
      .no-blogs {
        display: flex;
        margin-top: 50px;
        
        @include bp(sm-max) {
          font-size: 16px;
        }
      }
      .news-card {
        .card-content {
          .text-btn {
            letter-spacing: -0.17px;
          }
        }
      }
    }
  }
  .category-listing-wrapper {
    .category-listing-block {
      .btn-block {
        .show-btn {
          line-height: 24px;
          letter-spacing: -0.17px;
        }
      }
    }
  }
}
.three-cols-block {
  margin-top: 45px;
  .row {
    display: flex;
    margin: 0 -24px;
    @include bp(md-max) {
      margin: 0 -20px;
    }
    @include bp(md-max) {
      margin: 0;
    }

    .column {
      flex: 0 0 33.3333%;
      max-width: 33.33333%;
      padding: 0 24px;
      @include bp(md-max) {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 20px;
        margin-bottom: 16px;
      }
      @include bp(md-max) {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0;
        margin-bottom: 16px;
      }
      .column-inner {
        background-color: $light-gray-05;
        padding: 33px 40px 23px;
        height: 100%;
        @include bp(sm-max) {
          padding: 30px 25px;
        }
        h5 {
          color: $primary-color;
          font-size: 14px;
          font-weight: 600;
          line-height: 15.4px;
          letter-spacing: 2px;
          margin-bottom: 33px;
          text-transform: uppercase;
        }
        .inner-col-block {
          margin-bottom: 29px;
          h6 {
            font-size: 16px;
            line-height: 1.5;
            font-weight: 700;
            letter-spacing: -0.18px;
            color: $black;
            margin: 0 0 3px;
            a {
              font-size: 16px;
              line-height: 1.5;
              font-weight: 700;
              letter-spacing: -0.18px;
              color: $black;
            }
          }
          p {
            font-size: 14px;
            line-height: 1.5;
            letter-spacing: -0.17px;
            color: $secondary-color;
            text-transform: uppercase;
          }
        }
        .read-more {
          a {
            // font-size: 14px;
            // color: $primary-color;
            // font-weight: 600;
            letter-spacing: -0.17px;
            line-height: 1.71;
          }
        }
      }
    }
  }
}
