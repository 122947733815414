#assesment-detail-modal {
  &.custom-modal {
    .modal-wrap {
      .inner-content {
        padding-top: 25px;
        display: block;
      }
    }
  }
}

.meeting-modal {
  .modal-dialog {
    @include bp(sm-max) {
      margin: 0;
    }
  }
  .modal-content {
    border-radius: 8px;
    @include bp(sm-max) {
      border-radius: 0;
    }
    .modal-header {
      padding: 38px 25px 0 32px;
      @include bp(sm-max) {
        padding: 25px 25px 0;
      }
      h4 {
        font-size: 28px;
        line-height: 32px;
      }
    }
    .modal-body {
      padding: 20px 32px;
      @include bp(sm-max) {
        padding: 15px 25px;
      }
      p {
        margin-bottom: 16px;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.2px;
        color: $dark-gray-02;
      }
    }
  }
  .contact-form {
    margin: 0;
    .btn-wrap {
      p {
        margin-bottom: 0;
      }
    }
  }
}

.cta-home-screen-modal {
  .modal-dialog {
    @include bp(sm-max) {
      margin: 0;
    }
  }
  .modal-content {
    border-radius: 8px;
    @include bp(sm-max) {
      border-radius: 0;
    }
    .modal-header {
      padding: 38px 25px 0 32px;
      @include bp(sm-max) {
        padding: 25px 25px 0;
      }
      h4 {
        font-size: 28px;
        line-height: 32px;
      }
    }
    .modal-body {
      padding: 20px 32px;
      @include bp(sm-max) {
        padding: 15px 25px;
      }
      p {
        margin-bottom: 16px;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.2px;
        color: $dark-gray-02;
      }
    }
  }
  .contact-form {
    margin: 0;
    .btn-wrap {
      p {
        margin-bottom: 0;
      }
    }
  }
}

.modal-text {
  color: $dark-gray-05 !important;
}
