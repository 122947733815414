p {
  font-size: 14px;
  line-height: 18px;
  font-family: $roboto;
  font-weight: 400;
  margin: 0;
}

p strong {
  font-weight: 700;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: $roboto;
}

h1 {
  font-size: 64px;
  line-height: 72px;
  font-weight: 700;
  @include bp(lg-max) {
    font-size: 58px;
    line-height: 64px;
  }
  @include bp(md-max) {
    font-size: 48px;
    line-height: 54px;
  }
  @include bp(sm-max) {
    font-size: 40px;
    line-height: 48px;
  }
}

h2 {
  font-size: 40px;
  line-height: 48px;
  font-weight: 600;
  @include bp(lg-max) {
    font-size: 36px;
    line-height: 42px;
  }
  @include bp(sm-max) {
    font-size: 28px;
    line-height: 32px;
  }
  @include bp(xs-max) {
    font-size: 24px;
    line-height: 30px;
  }
}
h4 {
  font-size: 28px;
  line-height: 32px;
  font-weight: 700;
  @include bp(lg-max) {
    font-size: 22px;
    line-height: 26px;
  }
}
h5 {
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;

  @include bp(sm-max) {
    font-size: 16px;
    line-height: 22px;
  }
}
