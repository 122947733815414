@import "../base/colors";

.share-wrapper {
  text-align: center;

  input[type=checkbox] {
    display: none;
  }
}

.share-input:checked + .share-label {
  background: $light-gray-06;
}

.share-input:checked ~ .share-items {
  display: block;
  opacity: 1;

  transform: translate(-50%, -48px);
  position: absolute;
  left: 50%;
}

.share-label {
  background: $white;

  cursor: pointer;
  margin: 0;
  border-radius: 4px;

  i {
    img {
      width: 20px;
      height: 20px;
    }
  }
}

.share-items {
  display: none;
  opacity: 0;
  width: max-content;

  transform: translate(-50%, -16px);
  position: absolute;
  top: -25%;
  left: 50%;

  z-index: 10;

  background: white;

  padding: 6px 4px 0 4px;
  border-radius: 4px;
  border: $light-gray-01 solid 2px;
}