.about-banner {
  padding: 93px 0 85px;
  @include bp(md-max) {
    padding: 35px 0 55px;
  }
  @include bp(sm-max) {
    padding: 35px 0 55px;
  }
  .content-block {
    h1 {
      span {
        display: inline;
      }
      @include bp(sm-max) {
        margin: 0 0 24px 0;
      }
    }
    p {
      @include bp(sm-max) {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.2px;
      }
    }
    .btn-wrap {
      margin: 32px 0 0 0;
      @include bp(sm-max) {
        margin: 0;
      }
      .btn-primary {
        min-width: 140px;
        margin: 0 15px 0 0;
        @include bp(tablet-max) {
          margin: 0 0 15px 0;
        }
        span {
          display: flex;
          align-items: center;
        }
      }
      button,
      .btn-primary {
        @include bp(md-max) {
          margin: 0 15px 15px 0;
        }
      }
      .btn-secondary {
        i {
          width: 14px;
          display: inline-block;
          margin: 0 13px 0 0;
        }
      }
    }
  }
}

.financial-planner {
  padding: 105px 0 65px;
  @include bp(md-max) {
    padding: 44px 0 10px;
  }
}

.team-listing {
  > ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -16px 15px;
    max-width: 1008px;
    @include bp(sm-max) {
      margin: 0 -12px 19px;
    }
    > li {
      -ms-flex: 0 0 33.33%;
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 33.33%;
      flex: 0 0 33.33%;
      max-width: 33.33%;
      padding: 0 16px;
      margin: 0 0 48px 0;
      @include bp(sm-max) {
        -ms-flex: 0 0 50%;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 12px;
        margin: 0 0 23px 0;
      }
      .img-div {
        @include transition(all 0.3s);
        position: relative;
        overflow: hidden;
        cursor: pointer;
        .img-wrap {
          display: block;
        }
        .hover-div {
          position: absolute;
          background-color: rgba(245, 247, 250, 0.9);
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          position: absolute;
          display: flex;
          align-items: center;
          height: 100%;
          padding: 15px 9px;
          opacity: 0;
          visibility: hidden;
          transition: all 0.3s;
          box-shadow: 0 10px 20px 0 rgba(7, 25, 37, 0.25);
          @include bp(sm-max) {
            padding: 10px 0;
          }
          .text-btn {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 55px;
            min-width: 100px;
            display: flex;
            justify-content: center;
            @include bp(md-max) {
              bottom: 30px;
            }
            @include bp(xs-max) {
              bottom: 10px;
            }
          }
          ul {
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -ms-flex-wrap: wrap;
            -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: center !important;
            width: 100%;
            li {
              position: relative;
              padding: 0;
              width: 25%;
              display: flex;
              justify-content: center;
              a {
                display: -webkit-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                justify-content: center;
                img {
                  display: block;
                }
                i {
                  &.call-icon {
                    img {
                      height: 18px;
                      width: 18px;
                    }
                  }
                  &.mail-icon {
                    img {
                      height: 16px;
                      width: 20px;
                    }
                  }
                  &.linkedin-icon {
                    img {
                      height: 20px;
                      width: 20px;
                    }
                  }
                  &.Whatsapp-icon {
                    img {
                      height: 24px;
                      width: 24px;
                    }
                  }
                }
                &:focus {
                  @include bp(xxs-max) {
                    .rounded-btn {
                      border: none;
                    }
                  }
                }
              }
              .rounded-btn {
                @include bp(md-max) {
                  height: 40px;
                  width: 40px;
                }
                @include bp(xxs-max) {
                  height: auto;
                  width: auto;
                  border-radius: 0;
                  &:hover {
                    background-color: transparent;
                  }
                }
              }
              &::after {
                content: "";
                position: absolute;
                top: 18px;
                right: 0;
                background-color: #e0e4e9;
                width: 1px;
                height: 20px;
                @include bp(md-max) {
                  top: 10px;
                }
                @include bp(sm-max) {
                  height: 15px;
                }
                @include bp(xxs-max) {
                  top: 0;
                }
              }
              &:last-child {
                &::after {
                  display: none;
                }
              }
            }
          }
        }
      }
      p {
        letter-spacing: -0.2px;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        color: #0075c9;
        margin: 16px 0 8px;
        text-transform: uppercase;
        @include bp(sm-max) {
          margin: 13px 0 5px;
        }
      }
      h3 {
        letter-spacing: -0.8px;
        font-size: 28px;
        font-weight: 700;
        line-height: 32px;
        color: #000000;
        @include bp(sm-max) {
          letter-spacing: -0.2px;
          font-size: 16px;
          line-height: 20px;
        }
      }
      .detail-wrapper {
        @include transition(all 0.3s);
        &:hover {
          .img-div {
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.24);
          }
          .hover-div {
            opacity: 1;
            visibility: visible;
            bottom: 0;
          }
        }
      }
    }
  }
}

.about-section {
  padding: 90px 0 90px;
  @include bp(tablet-max) {
    padding: 50px 0 50px;
  }
  @include bp(sm-max) {
    padding: 50px 0 30px;
  }
  .about-text-wrap {
    max-width: 545px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    @include bp(md-max) {
      padding: 0;
    }
    @include bp(sm-max) {
      max-width: 100%;
    }
    .title {
      margin: 0 0 32px 0;
      @include bp(sm-max) {
        margin: 0 0 14px 0;
      }
      .text-bllue {
        margin: 0 0 17px 0;
      }
      .line-bottom {
        margin: 33px 0 0;
        @include bp(sm-max) {
          margin: 19px 0 0;
        }
      }
    }
    .about-text {
      p {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.2px;
        color: $dark-gray-05;
        margin: 0 0 24px 0;
        @include bp(sm-max) {
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.2px;
          margin: 0 0 20px 0;
        }
        &:last-child {
          margin: 0;
        }
        &.text-hide-show {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
        }
      }
      .read-more {
        .text-hide-show {
          display: block;
        }
      }
    }
    .custom-list {
      margin: 0 0 34px;
      &:last-child {
        margin: 0;
  }
    }
    .expandable-block {
      padding: 24px;
      background-color: $light-gray-05;
      border-radius: 4px;
      margin: 0 0 32px;
      display: none;
      .custom-list {
        li {
          font-size: 14px;
          line-height: 1.45;
          letter-spacing: -0.2px;
          color: $dark-gray-02;
        }
      }
    }
  }
  .btn-wrapper {
    .view-btn {
      font-size: 14px;
      line-height: 1.75;
      padding: 6px 31px;
    }
  }
  .custom-list {
    @include bp(sm-max) {
      margin: 0;
    }
  }
  .view-less-wrap {
    .text-wrap {
      margin-bottom: 20px;
    }
  }
}
.stand-about-banner {
  background: none;
  .img-block {
    position: relative;
    &:after {
      content: "";
      background: url("../../assets/images/squares-dots.svg");
      background-repeat: no-repeat;
      background-position: bottom left;
      position: absolute;
      left: -73px;
      bottom: -108px;
      height: 100%;
      width: 100%;
      z-index: 0;
      @include bp(sm-max) {
        display: none;
      }
    }
  }
}
.has-left-space {
  .img-block {
    margin: 0 -180px 0 100px;
    @include bp(xl-max) {
      margin: 0 -180px 0 50px;
    }
    @include bp(xlg-max) {
      margin: 0 -140px 0 50px;
    }
    @include bp(tablet-max) {
      margin: 0 -30px 0 0;
    }
    @include bp(sm-max) {
      margin: 0 0 40px 0;
    }
  }
}
.has-right-space {
  .img-block {
    margin: 0 100px 0 -180px;
    @include bp(xl-max) {
      margin: 0 50px 0 -180px;
    }
    @include bp(xlg-max) {
      margin: 0 50px 0 -140px;
    }
    @include bp(tablet-max) {
      margin: 0 0 0 -30px;
    }
    @include bp(sm-max) {
      margin: 0 0 30px 0;
      order: 1;
    }
  }
}
.has-left-space,
.has-right-space {
  .fix-layout {
    max-width: 1440px;
    margin: 0 auto;
  }
  &.about-section {
    .about-text-wrap {
      @include bp(tablet-max) {
        .title {
          margin: 0 0 15px 0;
          .text-blue {
            margin: 0 0 6px 0;
          }
          .line-bottom {
            margin: 10px 0 0 0;
          }
          h2 {
            font-size: 32px;
            line-height: 36px;
          }
        }
      }
    }
    .img-block-outer {
      @include bp(sm-max) {
        order: 1;
      }
    }
    .text-block-outer {
      @include bp(sm-max) {
        order: 2;
      }
    }
    .row-reverse {
      .img-block-outer {
        @include bp(sm-max) {
          order: 2;
          .img-block {
            margin: 0;
          }
        }
      }
      .text-block-outer {
        @include bp(sm-max) {
          order: 1;
          margin: 0 0 40px;
        }
      }
    }
  }
}