/*color variables */

$white: #fff;
$black: #000;
$black-shade-01: #080c0a;
$black-shade-02: #2c2c2c;
$blue-shade-01: #0151a3;
$blue-shade-02: #0163b8;
$blue-shade-03: #acd2ed;
$primary-color: #0075c9;
$secondary-color: #333d47;
$light-gray-01: #e0e4e9;
$light-gray-02: #e5e5e5;
$light-gray-03: #d5dae1;
$light-gray-04: #d8d8d8;
$light-gray-05: #f5f7fa;
$light-gray-06: #ebeef1;
$light-gray-07: #e6f1fa;
$light-gray-08: #f3f5f7;
$light-gray-09: #F6F8FB;
$dark-gray-01: #4c5256;
$dark-gray-02: #464e58;
$dark-gray-03: #8a929c;
$dark-gray-04: #717983;
$dark-gray-05: #333e48;
$dark-gray-06: #555d61;
$dark-gray-07: #bcc4ce;
$dark-gray-08: #a0a8b2;
$dark-gray-09:#646F77;
$dark-gray-10:#444E59;
$dark-gray-11: #9ea2a0;
$blue-01: #4a90e2;
$blue-02: #4285f4;
$blue-03: #1a73e8;
$blue-04:#2E8ED3;
$yellow-01: #f2a900;
$yellow-02: #fbbc04;
$yellow-03: rgba(242, 169, 0, 0.2);
$green-01: #34a853;
$green-02: #40c351;
$green-03: #008755;
$dark-orange: #ea4335;
$theme-dark: #333e48;
$theme-dark-1:#414D59;

/*sfp color variables */

$sfp-blue-shade-01: #005C6C;
$blue-shade-04: #08acc7;
$sfp-primary-color: #00778B;
$sfp-light-gray-05: #DCDDDE;
$sfp-light-green: #E6F0F2;
$light-gray-10: #41afc1;
$light-gray-11: #006a7f;
$light-gray-12: #c1c5ca;
$light-gray-13:#f5f7fa;
$sfp-blue-04:#005C6C;
$blue-05:#63AEBC;
$yellow-04: #E8A713;
$light-white: #EFF2F5;