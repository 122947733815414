.theme-dark {
  .banner-section {
    background-color: $theme-dark;
    background-image: url(../../assets/images/black-star.svg);
    @include bp(lg-max) {
      background-image: none;
    }
    .content-block {
      h1,
      p {
        color: $white !important;
      }
    }
    .contact-block {
      .detail-wrapper {
        box-shadow: none;
        background-color: $theme-dark-1;
        li {
          a {
            color: $white;
            &:after {
              background-color: $dark-gray-09;
            }
            i {
              &.call-btn {
                img {
                  display: none;
                  &.call-white {
                    display: inline-block;
                  }
                }
              }
            }
          }
        }
        .rounded-btn {
          &:hover {
            background-color: $dark-gray-09;
          }
        }
      }
    }
    &.secondary-banner {
      .more-info {
        color: $white;
      }
    }
  }
  .about-banner {
    .btn-secondary {
      background: transparent !important;
      color: $white !important;
      img{
        display: none;
      }
      .dark-theme-img{
        display: inline-block;
      }
      &:hover{
        background: transparent !important;
      }
    }
  }
  .offer-block {
    background-color: $theme-dark-1;
    .offer-tiles-wrapper {
      .offer-tiles {
        background-color: $theme-dark;
        &:hover {
          background-color: $primary-color;
          p {
            color: $white;
          }
        }
        h5 {
          color: $white;
        }
        p {
          color: $dark-gray-08;
        }
      }
    }
  }
  .product-offer-block {
    background-color: $theme-dark-1;
    .product-offer-tiles-wrapper {
      .product-offer-tiles {
        background-color: $theme-dark;
        &:hover {
          background-color: $primary-color;
          p {
            color: $white;
          }
        }
        h5 {
          color: $white;
        }
        p {
          color: $dark-gray-08;
        }
      }
    }
  }
  .slider-section {
    background-color: $theme-dark;
    
    .title {
      .planner-navigation {
        p {
          color: $white;
        }
      }  
    }
    
    .swiper-wrapper {
      .swiper-slide {
        p {
          color: $light-gray-03;
        }
        h4 {
          color: $white;
        }
      }
    }
  }
  .financial-goals {
    background-size: cover;
    background: url("../../assets/images/get-advice-cta.jpg") no-repeat center;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.43);
    }
    .container {
      z-index: 1;
    }
    .speak-to-planner-text {
      p {
        color: $white !important;
      }  
    }
    h2 {
      color: $white;
      span {
        font-weight: 500;
      }
    }
    .contact-planner-slice {
      .custom-ordered-list {
        li {
          color: $white !important;
          &::marker {
            color: $white !important;
          }
        }
      }
      .custom-list {
        li {
          color: $white !important;
        }
      }
      p {
        color: $white;
      }  
    }
  }
  .tools-calculators {
    background-color: $theme-dark-1;
    .tools-wrapper {
      .col-sm {
        background-color: $theme-dark;
        &:hover {
          background-color: $primary-color;
        }
        h4 {
          color: $white;
        }
        h5 {
          color: $white;
        }
        a {
          color: $white;
        }
        p {
          color: $light-gray-05;
        }
      }
    }
  }
  .about-section {
    background-color: $light-gray-05;
    .title {
      h2 {
        color: $black;
      }
      .text-blue {
        color: $primary-color;
      }
      .line-bottom {
        background-color: $primary-color;
      }
    }
  }
  .financial-planner {
    background-color: $theme-dark;
    .team-listing > ul > li {
      p {
        color: $light-gray-03;
      }
      h3 {
        color: $white;
      }
    }
  }
  .contact-wrapper {
    background-color: $theme-dark !important;
  }
  .form-group,
  .form-group.bmd-form-group {
    label {
      color: $white;
    }
    .form-control {
      background-color: $white;
    }
  }
  .tab-block {
    &.with-profile-outer {
      background-image: url(../../assets/images/black-star.svg);
      background-color: $theme-dark;
      @include bp(lg-max) {
        background-image: none;
      }
      @include bp(sm-max) {
        padding: 30px 0 0 0;
      }
    }
    .nav-tabs {
      .nav-link {
        @include bp(sm-max) {
          color: $white !important;
        }
        color: $white;
      }
    }
    .title {
      h2 {
        color: $black;
      }
      .text-blue {
        color: $primary-color;
      }
      .line-bottom {
        background-color: $primary-color;
      }
    }
  }
  .title {
    h2 {
      color: $white;
    }
    .text-blue {
      color: $light-gray-05;
    }
    .line-bottom {
      background-color: $blue-04;
    }
  }
  .contact-block {
    .detail-wrapper {
      li {
        padding: 12px 50px;
      }
    }
    .detail-wrapper li:nth-child(1), .detail-wrapper li:nth-child(2){
      @include bp(sm-max){
        border-bottom: 1px solid $dark-gray-09;
      }
    }
  }
  .Read-more-btn{
    color: $white;
    border-bottom: 1px solid $white;
  }
  .connection-section {
    .content-block {
      h2 {
        color: $white;
      }
    }
  }
  .news-section {
    background-color: $theme-dark;
  }
  .download-link {
    display: inherit;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 14px;
    line-height: 1.45;
    letter-spacing: -0.2px;
    color: $yellow-01 !important;
    font-family: $roboto;
    font-weight: 500;
    margin-right: 19px;

    &:last-child {
      margin-right: 0;
    }
    &:hover {
      background-color: $yellow-03;
    }

    &:focus {
      border-color: $yellow-01;
    }
  }
  .pdf-download-section {
    background-color: $theme-dark-1;
  }
  .news-cards {
    .news-card {
      background-color: $light-gray-05;
    }
    p {
      color: $dark-gray-10;
    }
  }
  .blog-listing-section {
    background-color: $theme-dark !important;
    h5{
      color: $white;
    }
    .tags-block {
      p{
        color: $dark-gray-08;
      }
      a {
        color: $dark-gray-08;

        &:hover {
          color: $yellow-01;
        }
      }
    }
    .content {
      p {
        color: $light-gray-06;
      }
    }
    .category-listing-block{
      background-color: $theme-dark-1;
    }
  }
  .blog-listing-wrap {
    background-color: $theme-dark;
  }
  .content-with-video-text {
    .custom-ordered-list {
      li {
        color: $dark-gray-02 !important;
      }
    }
    .custom-list {
      li {
        color: $dark-gray-02 !important;
      }
    }
  }
  .hero-text {
    .custom-ordered-list {
      li {
        color: $white !important;
        &::marker {
          color: $white !important;
        }
      }
    }
    .custom-list {
      li {
        color: $white !important;
      }
    }
  }
  .about-text, {
    color: $white;
  }
 .content-slice, .tool-launcher, .form-download-section {
   background-color: $light-gray-05;
   .title {
     h2 {
       color: $black;
     }
     .text-blue {
       color: $primary-color;
     }
     .line-bottom {
       background-color: $primary-color;
     }
   }
 }
  .custom-list {
    color: $dark-gray-02;
  }
  .modal-content {
    .modal-body {
      p {
        color: $dark-gray-05 !important;
      }
    }
  }
  .content-slice {
    &.media-heading,&.contact-form  {
      background-color: $theme-dark-1;
      .title {
        .text-blue {
          color: $light-gray-05 !important;
        }
        h1 {
          color: $white;
        }
        h2 {
          color: $white;
        }
        .line-bottom {
          background-color: $blue-04;
        }
        .grey-text {
          color: $light-gray-06;
        }
      }
      .content-slice-detail {
        p { color: $white;}
        li { 
          list-style-type: unset;
          color: $white;
        }
      }
      .content-slice-detail-blog {
        p { color: $white;}
        li { 
          color: $white;
        }
      }
    }
    &.embedded-product-tool {
      background-color: $white;
    }
  }
  .related-blog-theme-grey {
    .view-all {
      color: $white;
    }
    background-color: $theme-dark-1 !important;
  }
  .content-card-block {
    background-color: $white !important;
  }
}