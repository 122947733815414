.wrapper {
  /*overflow-x: hidden;*/
}
.main-wrap {
  padding: 150px 0 0 0;
  @include bp(xlg-max) {
    padding: 146px 0 0;
  }
  @include bp(lg-max) {
    padding: 0;
  }
}
.banner-section {
  background-image: url(../../assets/images/star-graphic.svg);
  background-repeat: no-repeat;
  padding: 60px 0 160px 0;
  position: relative;
  background-position: 0 0;
  @include bp(lg-max) {
    background: none;
  }
  @include bp(sm-max) {
    padding: 50px 0 100px 0;
  }
  &.has-left-space {
    @include bp(tablet-max) {
      padding: 50px 0 100px 0;
    }
    @include bp(sm-max) {
      padding: 50px 0 20px 0;
    }
    &.about-banner {
      @include bp(sm-max) {
        padding: 50px 0 20px 0;
      }
    }
    .img-block {
      margin: 0 -180px 0 0;
      @include bp(xl-max) {
        margin: 0 -180px 0 0;
      }
      @include bp(xlg-max) {
        margin: 0 -140px 0 0;
      }
      @include bp(tablet-max) {
        margin: 0 -30px 0 0;
      }
      @include bp(sm-max) {
        margin: 0 0 40px 0;
      }
    }
  }
  .content-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    h1 {
      font-family: $roboto;
      font-weight: 300;
      letter-spacing: -2px;
      margin: 0 0 50px 0;
      @include bp(lg-max) {
        margin: 0 0 30px 0;
      }
      @include bp(md-max) {
        font-size: 40px;
        line-height: 48px;
        margin: 0 0 20px 0;
      }
      @include bp(sm-max) {
        letter-spacing: -1.6px;
      }
      span {
        font-weight: 500;
        display: block;
        @include bp(md-max) {
          font-weight: 300;
        }
        @include bp(sm-max) {
          display: inline;
        }
      }
    }
    p {
      font-size: 20px;
      color: $dark-gray-02;
      line-height: 32px;
      letter-spacing: -0.2px;
      padding: 0 80px 0 0;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      margin-bottom: 10px;
      @include bp(lg-max) {
        font-size: 18px;
        line-height: 32px;
      }
      @include bp(md-max) {
        padding: 0 0 0 0;
        font-size: 16px;
        line-height: 24px;
      }
      @include bp(sm-max) {
        margin: 0 0 30px 0;
      }
    }
  }
  .btn-wrap{
    margin: 32px 0 0;
    @include bp(sm-max) {
      margin: 0 0 16px;
    }
  }
  .read-more-btn {
    @include bp(sm-max) {
      margin-bottom: 20px;
    }
  }
  .img-block {
    margin: 0 -100px 0 0;
    @include bp(lg-max) {
      margin: 0;
    }
  }
  .info-block {
    @include bp(sm-max) {
      margin: 0 0 28px;
    }
  }
}
.contact-block {
  position: absolute;
  bottom: 0px;
  left: 0;
  z-index: 3;
  right: 0;
  @include bp(sm-max) {
    position: static;
    margin: 0 0 30px 0;
    display: flex;
    width: 100%;
    .container {
      padding: 0 15px 0 15px;
    }
  }
  .detail-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    position: relative;
    border-radius: 4px;
    box-shadow: 0 10px 20px 0 rgba(7, 25, 37, 0.25);
    @include bp(xxl-min){
      inline-size: max-content !important;  
    }
    @include bp(sm-max) {
      flex-wrap: wrap;
      padding: 0 10px;
    }
    li {
      padding: 23px 50px 23px 50px;
      .qr-code-banner {
        min-width: max-content !important;
      }
      @include bp(lg-max) {
        padding: 23px 45px 23px 45px !important;
      }
      @include bp(tablet-max) {
        font-size: 18px;
        padding: 10px 40px !important;
      }
      @include bp(md-max) {
        padding: 20px 27px !important;
      }
      @include bp(sm-max) {
        &:nth-child(1),
        &:nth-child(2) {
          border-bottom: 1px solid $light-gray-01;
          flex: 0 0 100%;
          max-width: 100%;
          a {
            &:after {
              display: none;
            }
          }
        }
        &:nth-last-child(1),
        &:nth-last-child(2) {
          flex: 0 0 25%;
          max-width: 25%;
          &.homepage-contact {
            flex: 0 0 75%;
            max-width: 75%;
          }
        }
      }
      @include bp(xxs-max) {
        padding: 10px 5px 10px 5px !important;
        &:nth-last-child(1),
        &:nth-last-child(2) {
          flex: 0 0 21%;
          max-width: 21%;
          i {
            img {
              max-width: 20px;
            }
          }
          &.homepage-contact {
            flex: 0 0 75%;
            max-width: 75%;
          }
        }
      }
      a {
        color: $primary-color;
        font-weight: 500;
        position: relative;
        font-size: 18px;
        line-height: 34px;
        letter-spacing: -0.7px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include bp(lg-max) {
          font-size: 18px;
        }
        @include bp(sm-max) {
          font-size: 16px;
          line-height: 32px;
          letter-spacing: normal;
        }
        @include bp(xxxs-max) {
          font-size: 15px;
        }
        &:after {
          content: "";
          position: absolute;
          width: 1px;
          height: 40px;
          background-color: $light-gray-01;
          right: -55px;
          top: 50%;
          @include translateY(-50%);
          @include bp(tablet-max) {
            right: -40px;
          }
          @include bp(md-max) {
            right: -27px;
          }
          @include bp(sm-max) {
            height: 26px;
          }
          @include bp(xxs-max) {
            right: -5px;
          }
        }
        .call-text {
          display: none !important;

          @include bp(xxl-min) {
            display: inline-flex !important;
          }
        }
        i {
          &.rounded-btn {
            @include bp(md-max) {
              height: 40px;
              width: 40px;
            }
          }
          img {
            max-width: 24px;
          }
          &.call-btn {
            display: flex !important;
            .call-white {
              display: none;
            }
          }
          &.has-margin {
            @include bp(xxl-min) {
              margin: 0 0 0 15px;
            }
            margin: 0;
            @include bp(xxs-max) {
              margin: 0 0 0 8px;
            }
          }
        }
      }
      &:last-child {
        a {
          &:after {
            display: none;
          }
        }
      }
    }
  }
}
.fund-fact-sheet {
  h1 {
    span {
      @include bp(md-max) {
        font-weight: 500 !important;
      }
    }
  }
}
.campaigns {
  h1 {
    span {
      @include bp(md-max) {
        font-weight: 500 !important;
      }
    }
  }
}
.market-indicators {
  h1 {
    span {
      @include bp(md-max) {
        font-weight: 500 !important;
      }
    }
  }
}
.offer-block {
  background-color: $light-gray-05;
  margin: -40px 0 0;
  padding: 110px 0 100px 0;
  position: relative;
  @include bp(sm-max) {
    padding: 50px 0 50px;
    margin: 0 0 0 0;
  }
  .offer-tiles-wrapper {
    display: flex;
    margin: 0 -10px 0 -10px;
    position: relative;
    flex-wrap: wrap;
    .offer-tiles {
      flex: 0 0 calc(20% - 20px);
      max-width: calc(20% - 20px);
      background-color: white;
      margin: 0 10px 20px 10px;
      padding: 25px 25px 25px 25px;
      border-radius: 4px;
      z-index: 1;
      display: block;
      cursor: default;
      min-height: 216px;
      @include bp(lg-max) {
        padding: 25px 15px 25px 15px;
      }
      @include bp(md-max) {
        flex: 0 0 calc(33.33% - 20px);
        max-width: calc(33.33% - 20px);
        min-height: 196px;
      }
      @include bp(xs-max) {
        flex: 0 0 calc(50% - 20px);
        max-width: calc(50% - 20px);
        padding: 16px 16px 16px 16px;
        min-height: 144px;
        height: auto !important;
      }

      @include transition(all 0.3s);
      i {
        max-width: 40px;
        height: 40px;
        margin: 0 0 12px 0;
        img {
          width: 100%;
          height: auto;
        }
      }
      h5 {
        color: $black;
        margin: 0 0 10px 0;
        letter-spacing: -0.4px;
        line-height: 24px;
        @include bp(sm-max) {
          line-height: 22px;
          margin: 0 0 0 0;
        }
        @include bp(xxs-max) {
          min-height: 45px;
          letter-spacing: -0.2px;
        }
      }
      p {
        color: $dark-gray-02;
        line-height: 20px;
        margin: 0 0 7px;
        letter-spacing: -0.2px;
        &:last-child {
          margin: 0;
        }
        @include bp(sm-max) {
          min-height: 80px;
        }
        @include bp(xs-max) {
          display: none;
        }
      }
      .btn.btn-raised.btn-secondary {
        border-color: transparent;
        font-size: 14px !important;
        line-height: 1.45 !important;
        height: auto;
        display: none;
      }
      &:hover {
        background-color: $primary-color;
        i {
          display: none;
        }
        h5 {
          color: $white;
        }
        p {
          @include bp(xs-max) {
            display: block;  
          }
          color: $white;
        }
        .btn.btn-raised.btn-secondary {
          display: inline-flex;

          &:focus {
            background-color: $white !important;
          }
        }
      }
    }
  }

  &.has-tools {
    .offer-tiles-wrapper {
      @include bp(xs-max) {
        margin: 0;
      }
      .offer-tiles {
        @include bp(xs-max) {
          flex: 0 0 100%;
          max-width: 100%;
          padding: 32px;
          min-height: auto;
          margin: 0 0 24px;

          &:hover {
            .btn {
              &.btn-raised {
                &.btn-secondary {
                  @include bp(xs-max) {
                    background-color: $white !important;

                    &:hover {
                      background-color: $light-gray-07 !important;
                    }
                  }
                }
              }
            }
          }
        }

        &:last-child {
          margin: 0;
        }

        i {
          @include bp(xs-max) {
            max-width: 30px;
            height: 33px;
            margin: 0 0 20px;
          }
        }

        h5 {
          @include bp(xs-max) {
            font-size: 20px;
            line-height: 24px;
            letter-spacing: -0.4px;
            margin: 0 0 8px;
            min-height: auto;
          }
        }

        p {
          @include bp(xs-max) {
            display: block;
            min-height: auto;
            margin: 0 0 24px;
          }
        }

        .btn {
          &.btn-raised {
            &.btn-secondary {
              @include bp(xs-max) {
                display: -webkit-inline-box;
                display: -webkit-inline-flex;
                display: -ms-inline-flexbox;
                display: inline-flex;
                width: auto;
                background-color: transparent !important;
                border-color: $light-gray-01;
              }
            }
          }
        }
      }
    }
  }
}
.financial-goals {
  background: url("../../assets/images/star-graphic-right.png") no-repeat;
  background-position-x: right;
  background-position-y: center;
  padding: 95px 0 110px 0;
  @include bp(sm-max) {
    padding: 45px 0 45px 0;
  }
  .content-block {
    margin: 0 0 0 -20px;
    padding: 0 75px 0 0;
    @include bp(lg-max) {
      margin: 0 0 0 0;
      padding: 0 15px 0 15px;
    }

    .btn-wrap {
      margin: 0;
    }
  }
  .container {
    position: relative;
  }
  .head-title {
    margin: 0 auto 45px;
    @include bp(xs-max) {
      margin: 0 auto 22px;
    }
  }
  p {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: $dark-gray-05;
    margin: 0 0 25px 0;
    @include bp(lg-max) {
      margin: 0 0 25px 0;
      padding: 0 0 0 0;
    }
  }
  .primary-btn {
    @include bp(lg-max) {
      margin: 0 0 0 0;
    }
    @include bp(xxs-max) {
      width: 100%;
    }
  }
  .blank-div {
    @include bp(sm-max) {
      display: none;
    }
  }
  .content-block {
    @include bp(sm-max) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}
.tools-calculators {
  padding: 96px 0 85px 0;
  @include bp(sm-max) {
    padding: 50px 0 50px 0;
  }
  .title {
    display: flex;
    justify-content: space-between;
    margin: 0 0 53px 0;
    @include bp(md-max) {
      flex-wrap: wrap;
      margin: 0 0 35px 0;
    }
    .left-block {
      @include bp(md-max) {
        margin: 0 0 20px 0;
      }
    }
    .info-block {
      @include bp(md-max) {
        width: 100%;
      }
      p {
        max-width: 425px;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.2px;
        color: $dark-gray-05;
        text-transform: none;
        @include bp(md-max) {
          max-width: 100%;
        }
      }
    }
  }
  .tools-wrapper {
    flex-wrap: nowrap;
    overflow-x: auto;
    margin: 0 -10px;
    &::-webkit-scrollbar {
      height: 0;
    }
    .col-sm {
      background-color: $light-gray-05;
      flex: 0 0 calc(20% - 20px);
      max-width: calc(20% - 20px);
      margin: 0 10px;
      padding: 30px 20px;
      border-radius: 4px;
      cursor: pointer;
      a {
        color: $black;
      }
      @include transition(all 0.3s);
      @include bp(tablet-max) {
        width: 192px;
        min-width: 192px;
      }
      &:hover {
        background-color: $primary-color;
        i {
          display: none;

          &.img-show {
            display: inline-block;

            .normal-img {
              opacity: 0;
              visibility: hidden;
            }

            .hover-img {
              opacity: 1;
              visibility: visible;
            }
          }
        }
        h4 {
          color: $white;
        }
        p {
          color: $white;
        }
      }
    }
  }
  i {
    margin: 0 0 45px 0;
    height: 35px;
    display: inline-block;
    position: relative;

    img {
      @include transition(all 0.3s);
    }

    .hover-img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      opacity: 0;
      visibility: hidden;
    }
  }
  h4 {
    margin: 0 0 10px 0;
    letter-spacing: -0.8px;
    @include bp(xs-max) {
      letter-spacing: -0.4px;
      font-size: 20px;
    }
  }
  p {
    color: $primary-color;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: -0.2px;
  }
}
.slider-section {
  padding: 95px 0 150px 0;
  @include bp(lg-max) {
    padding: 50px 0 100px 0;
  }
  @include bp(tablet-max) {
    padding: 50px 0 100px 0;
  }
  @include bp(sm-max) {
    padding: 65px 0 50px 0;
  }
  h4 {
    letter-spacing: -0.8px;
    @include bp(sm-max) {
      letter-spacing: -0.4px;
    }
  }
  .title {
    margin: 0 0 63px 0;
    
    .planner-navigation {
      display: flex;
      align-items: baseline;
      justify-content: space-between;

      .planner-action {
        color: $primary-color;
      }
      @include bp(sm-max) {
        display: inline-table;
        
        p { 
          margin-top: 20px;
        }
      }
    }
    .text-blue {
      color: $primary-color;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 2px;
      font-weight: 500;
      text-transform: uppercase;
      margin: 0 0 15px 0;
      @include bp(sm-max) {
        letter-spacing: 1.7px;
        font-size: 12px;
      }
    }
  }
  .swiper-scrollbar {
    opacity: 1 !important;
    max-width: 1088px;
    margin: 0;
    background-color: $light-gray-03;
    left: 0;
    width: 100%;
    border-radius: 0;
  }
  .swiper-container {
    cursor: grab;
  }
  .swiper-wrapper {
    margin: 0 0 90px 0;
    cursor: grab;
    -webkit-overflow-scrolling: touch;
    @include bp(sm-max) {
      margin: 0 0 40px 0;
    }
    .swiper-slide {
      width: auto;
      i {
        margin: 0 0 15px 0;
        // width: 100%;
        position: relative;
        &:hover {
          .hover-div {
            opacity: 1;
            visibility: visible;
          }
        }
        img {
          width: 100%;
        }
      }
      p {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.2px;
        text-transform: uppercase;
        color: $primary-color;
        margin: 0 0 15px 0;
      }
    }
  }
  .hover-div {
    position: absolute;
    background-color: rgba(245, 247, 250, 0.9);
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 15px 9px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
    box-shadow: 0 10px 20px 0 rgba(7, 25, 37, 0.25);
    @include bp(sm-max) {
      padding: 10px 0;
    }
    .text-btn {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 55px;
      min-width: 100px;
      display: flex;
      justify-content: center;
      font-style: normal;
      @include bp(md-max) {
        bottom: 30px;
      }
      @include bp(xs-max) {
        bottom: 10px;
      }
    }
    ul {
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -ms-flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: center !important;
      width: 100%;
      li {
        position: relative;
        padding: 0;
        width: 25%;
        display: flex;
        justify-content: center;
        a {
          display: -webkit-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          justify-content: center;
          img {
            display: block;
          }
          i {
            &.call-icon {
              img {
                height: 18px;
                width: 18px;
              }
            }
            &.mail-icon {
              img {
                height: 16px;
                width: 20px;
              }
            }
            &.linkedin-icon {
              img {
                height: 20px;
                width: 20px;
              }
            }
            &.Whatsapp-icon {
              img {
                height: 24px;
                width: 24px;
              }
            }
          }
          &:focus {
            @include bp(xxs-max) {
              .rounded-btn {
                border: none;
              }
            }
          }
        }
        .rounded-btn {
          @include bp(md-max) {
            height: 40px;
            width: 40px;
          }
          @include bp(xxs-max) {
            height: auto;
            width: auto;
            border-radius: 0;
            &:hover {
              background-color: transparent;
            }
          }
        }
        &::after {
          content: "";
          position: absolute;
          top: 18px;
          right: 0;
          background-color: #e0e4e9;
          width: 1px;
          height: 20px;
          @include bp(md-max) {
            top: 10px;
          }
          @include bp(sm-max) {
            height: 15px;
          }
          @include bp(xxs-max) {
            top: 0;
          }
        }
        &:last-child {
          &::after {
            display: none;
          }
        }
      }
    }
  }
}
.dark-theme-img {
  display: none;
}
.product-image {
  width: 35px;
  height: 35px;
}

.product-offer-block {
  background-color: $white;
  margin: -40px 0 0;
  padding: 110px 0 100px 0;
  position: relative;
  @include bp(sm-max) {
    padding: 50px 0 50px;
    margin: 0 0 0 0;
  }
  .product-offer-tiles-wrapper {
    display: flex;
    margin: 0 -10px 0 -10px;
    position: relative;
    flex-wrap: wrap;
    .product-offer-tiles {
      flex: 0 0 calc(20% - 20px);
      max-width: calc(20% - 20px);
      background-color: $light-gray-05;
      margin: 0 10px 20px 10px;
      padding: 25px 25px 25px 25px;
      border-radius: 4px;
      z-index: 1;
      display: block;
      cursor: pointer;
      min-height: 216px;
      @include bp(lg-max) {
        padding: 25px 15px 25px 15px;
      }
      @include bp(md-max) {
        flex: 0 0 calc(33.33% - 20px);
        max-width: calc(33.33% - 20px);
        min-height: 196px;
      }
      @include bp(xs-max) {
        flex: 0 0 calc(50% - 20px);
        max-width: calc(50% - 20px);
        padding: 16px 16px 16px 16px;
        min-height: 144px;
      }

      @include transition(all 0.3s);
      i {
        max-width: 40px;
        height: 40px;
        margin: 0 0 12px 0;
        img {
          width: 100%;
          height: auto;
        }
      }
      h5 {
        font-size: 24px;
        font-weight: bold;
        letter-spacing: -0.6px;
        line-height: 28px;
        color: $black;
        margin: 0 0 10px 0;
        @include bp(sm-max) {
          line-height: 22px;
          margin: 0 0 0 0;
        }
        @include bp(xxs-max) {
          min-height: 45px;
          letter-spacing: -0.2px;
        }
      }
      p {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1.71px;
        line-height: 15.4px;
        color: $primary-color;
        margin: 0 0 7px;
        &:last-child {
          margin: 0;
        }
        @include bp(sm-max) {
          min-height: 80px;
        }
        @include bp(xs-max) {
          display: none;
        }
      }
      .btn.btn-raised.btn-secondary {
        border-color: transparent;
        font-size: 14px !important;
        line-height: 1.45 !important;
        height: auto;
        display: none;
      }
      &:hover {
        background-color: $primary-color;
        i {
          display: none;
        }
        h5 {
          color: $white;
        }
        p {
          color: $white;
        }
        .btn.btn-raised.btn-secondary {
          display: inline-flex;

          &:focus {
            background-color: $white !important;
          }
        }
      }
    }
  }

  &.has-tools {
    .product-offer-tiles-wrapper {
      @include bp(xs-max) {
        margin: 0;
      }
      .product-offer-tiles {
        @include bp(xs-max) {
          flex: 0 0 100%;
          max-width: 100%;
          padding: 32px;
          min-height: auto;
          margin: 0 0 24px;

          &:hover {
            .btn {
              &.btn-raised {
                &.btn-secondary {
                  @include bp(xs-max) {
                    background-color: $white !important;

                    &:hover {
                      background-color: $light-gray-07 !important;
                    }
                  }
                }
              }
            }
          }
        }

        &:last-child {
          margin: 0;
        }

        i {
          @include bp(xs-max) {
            max-width: 30px;
            height: 33px;
            margin: 0 0 20px;
          }
        }

        h5 {
          @include bp(xs-max) {
            font-size: 20px;
            line-height: 24px;
            letter-spacing: -0.4px;
            margin: 0 0 8px;
            min-height: auto;
          }
        }

        p {
          @include bp(xs-max) {
            display: block;
            min-height: auto;
            margin: 0 0 24px;
          }
        }

        .btn {
          &.btn-raised {
            &.btn-secondary {
              @include bp(xs-max) {
                display: -webkit-inline-box;
                display: -webkit-inline-flex;
                display: -ms-inline-flexbox;
                display: inline-flex;
                width: auto;
                background-color: transparent !important;
                border-color: $light-gray-01;
              }
            }
          }
        }
      }
    }
  }
}
.campaign-card-content {
   justify-content: flex-end !important;
 }
.campaign-read-more {
  font-size: 14px !important;
  line-height: 20px !important;
  letter-spacing: normal !important;
  margin: 0 0 -10px 0 !important;
  display: inline-flex !important;
} 
.mobile-show {
  display: none;
  @include bp(xs-max) {
    display: unset;
  }
}
.homepage-contact-nav-links {
  @include bp(xs-max) {
    min-width: 100%;  
  }
}
.phone-number {
  @include bp(xs-max) {
   padding-right: 10px;
  }
}
.market-indicators-table {
  @media (min-width: 335px) and (max-width: 358px) {
    overflow-x: unset;
  }  
}
