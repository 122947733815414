.btn {
  &.auto-width {
    min-width: auto !important;
    margin: 0;
    padding: 8px 30px;
  }
  &.disable {
    color: $dark-gray-07 !important;
    background-color: $light-gray-06 !important;
    border-color: $light-gray-06;
    pointer-events: none;
  }
  &.btn-raised {
    &.disable {
      color: $dark-gray-07 !important;
      background-color: $light-gray-06 !important;
      border-color: $light-gray-06;
      pointer-events: none;
    }
    &.btn-primary {
      height: 60px;
      min-width: 200px;
      background-color: $primary-color !important;
      background: $primary-color !important;
      color: $white !important;
      border-radius: 4px;
      border: 2px solid $primary-color;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      text-transform: none;
      font-size: 16px !important;
      box-shadow: none;
      @include transition(all 0.3s);
      &:hover {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
      }
      &:focus {
        box-shadow: none;
        border: 2px solid $blue-shade-01;
      }
      @include bp(sm-max) {
        height: 40px;
        line-height: 40px !important;
        width: 100%;
        font-size: 14px !important;
        line-height: 24px;
        letter-spacing: -0.2px;
      }
      &.disable {
        color: $dark-gray-07 !important;
        background-color: $light-gray-06 !important;
        border-color: $light-gray-06;
        pointer-events: none;
      }
      .ripple-container {
        .ripple-decorator {
          &.ripple-on {
            background-color: $blue-shade-02 !important;
            opacity: 0.8;
          }
          &.ripple-out {
            opacity: 0;
          }
        }
      }
      span {
        z-index: 2;
      }
    }
    &.btn-secondary {
      height: 60px;
      background-color: $white !important;
      background: $white !important;
      color: $primary-color !important;
      border-radius: 4px;
      border: 2px solid $light-gray-01;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      text-transform: none;
      font-size: 16px !important;
      box-shadow: none;
      cursor: pointer;
      @include transition(all 0.3s);
      min-width: 250px;
      margin: 0 auto;
      &:hover {
        border: 2px solid $light-gray-01;
        background-color: $light-gray-07 !important;
      }
      &:focus {
        box-shadow: none;
        border: 2px solid $primary-color;
        background-color: transparent !important;
        &:active {
          box-shadow: none;
          border: 2px solid $primary-color;
        }
      }
      &:active {
        box-shadow: none;
        border: 2px solid $primary-color;
        &:hover {
          box-shadow: none;
          border: 2px solid $primary-color;
        }
      }
      &.disable {
        color: $dark-gray-07 !important;
        background-color: $light-gray-06 !important;
        border-color: $light-gray-06;
        pointer-events: none;
      }
      @include bp(sm-max) {
        height: 40px;
        line-height: 40px !important;
        width: 100%;
        font-size: 14px !important;
        line-height: 24px;
        letter-spacing: -0.2px;
      }
      .ripple-container {
        .ripple-decorator {
          &.ripple-on {
            background-color: $light-gray-07 !important;
            opacity: 0.8;
          }
          &.ripple-out {
            opacity: 0;
          }
        }
      }
      span {
        z-index: 2;
      }
    }
  }
}
.form-group,
.form-group.bmd-form-group {
  margin: 0 0 30px;
  padding: 0;
  position: relative;
  .form-control {
    border: 2px solid $dark-gray-07;
    border-radius: 4px;
    background: none;
    height: 60px;
    color: $black-shade-01;
    line-height: 1;
    font-size: 16px;
    font-family: $roboto;
    padding: 20px 20px 20px 20px;
    @include transition(all 0.3s);
    &:focus {
      border-color: $primary-color;
    }

    @include placeholder {
      font-size: 16px;
    }
  }
  label {
    top: 0;
    margin: 0 0 10px 0;
    color: $black-shade-01;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.2px;
    font-weight: 700;
    font-family: $roboto;
    position: relative;
    display: block;
  }
  &.has-error {
    .form-control {
      border-color: red !important;
    }
    .custom-select {
      .dk-selected {
        border-color: red !important;
        &::before {
          border-color: red !important;
        }
      }
    }
    .select2-container {
      .select2-selection--single {
        border-color: red;

        .select2-selection__arrow {
          border-color: red;
        }
      }
    }
    .dropdown-wrap {
      &:after {
        border-color: red;
      }

      .page-dropdown {
        border-color: red;
      }
    }
    .error-msg {
      display: block;
    }
  }
  textarea {
    resize: none;
    min-height: 200px;
  }
  select.custom-select {
    display: block;
    position: absolute;
    bottom: 0;
    opacity: 0;
    visibility: hidden;
  }
  .custom-select {
    /*height: 60px;
    color: $black-shade-01;
    line-height: 1;
    font-size: 16px;
    font-family: $roboto;
    box-shadow: none;
    border: none;
    width: 100%;
    padding: 0;*/

    &:focus {
      box-shadow: none;
    }
    .dk-option {
      padding: 20px 20px 20px 20px;
      &.dk-option-highlight {
        background-color: $light-gray-08;
        color: $black;
      }
      &.dk-option-selected {
        background-color: $primary-color;
        color: $white;
      }
    }
    .dk-select-options {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.24);
      border: none;
      border-radius: 0;
      max-height: 180px;
      margin: 0 0 0 0;
    }
    .dk-selected {
      height: 100%;
      border-radius: 0;
      padding: 20px 20px 20px 20px;
      border: 2px solid $dark-gray-07;
      border-radius: 4px;
      display: flex;
      align-items: center;
      &:focus {
        border-color: $primary-color;
        &:before {
          border-color: $primary-color;
        }
      }
      &:after {
        display: none;
      }
      &:before {
        content: "";
        height: 10px;
        width: 10px;
        display: block;
        top: 19px;
        right: 15px;
        margin: 2px 0 0;
        border: 2px solid #0075c9;
        border-top: 0;
        border-left: 0;
        @include rotate(45deg);
      }
    }
    &.dk-select-open-down,
    &.dk-select-open-up {
      .dk-selected {
        &:before {
          @include rotate(225deg);
          top: 25px;
        }
      }
    }
  }
  .dropdown-wrap {
    width: 100%;
    height: 60px;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      height: 10px;
      width: 10px;
      display: block;
      top: 19px;
      right: 15px;
      margin: 2px 0 0;
      border: 2px solid #0075c9;
      border-top: 0;
      border-left: 0;
      @include rotate(45deg);
      @include transition(none);
    }

    &.open {
      &:after {
        top: 25px;
        @include rotate(225deg);
      }
    }

    .page-dropdown {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      font-size: 16px;
      line-height: 1;
      color: $black-shade-01;
      font-family: $roboto;
      width: 100%;
      height: 100%;
      padding: 20px;
      border: 2px solid $dark-gray-07;
      border-radius: 4px;
      outline: none;
      box-shadow: none;
      position: relative;
      cursor: pointer;
    }
  }
  .dateselect {
    position: relative;
    z-index: 1;
    &::placeholder {
      font-size: 16px;
      font-weight: normal;
      color: $black-shade-01;
    }
  }
  .calendar-icon {
    position: absolute;
    right: 20px;
    top: 47px;
    height: 24px;
    width: 24px;
    cursor: pointer;
    z-index: 0;
  }
}
.form-check {
  padding: 0;

  .form-check-input {
    margin: 0;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    cursor: pointer;
    margin: 0;
    z-index: 1;

    &:checked ~ {
      .form-check-label {
        &:before {
          background-color: $primary-color;
          border-color: $primary-color;
        }

        &:after {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }

  .form-check-label {
    font-size: 14px;
    line-height: 1.45;
    letter-spacing: -0.2px;
    margin: 0;
    font-weight: 400;
    color: $dark-gray-02;
    padding-left: 35px;
    position: relative;
    display: block;

    &:before, &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 18px;
      width: 18px;
      @include transition(all 0.3s);
    }

    &:before {
      border: 2px solid $dark-gray-03;
      border-radius: 3px;
    }

    &:after {
      background-image: url(../images/check-white.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 12px;
      opacity: 0;
      visibility: hidden;
    }

  }
}
.error-msg {
  color: red;
  margin-top: 5px;
  display: none;
}
// hide arrow for type number input
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.dtp {
  overflow-y: auto;
}
.dtp-date-view {
  .dtp-header {
    position: absolute;
    top: -25px;
  }
  .dtp-header,
  .dtp-date {
    background: $primary-color !important;
  }
  table.dtp-picker-days {
    min-height: 200px;
    tr > td {
      padding: 2px 2px 2px 2px;
    }
    tr > td > a.selected {
      background-color: $primary-color !important;
    }
  }
  .dtp-date {
    .dtp-actual-num {
      margin: 15px 0;
      display: block;
      text-align: center;
    }
    > div {
      display: flex;
      align-items: center;
    }
    .left,
    .right {
      i {
        height: 16px;
        width: 16px;
        font-size: 0;
        display: inherit;
        position: relative;
        &:after {
          content: "";
          position: absolute;
          height: 12px;
          width: 12px;
          border: 2px solid $white;
          border-bottom: 0;
          border-right: 0;
          @include rotate(-45deg);
        }
      }
    }
    .right {
      i {
        &:after {
          border: 2px solid $white;
          border-left: 0;
          border-top: 0;
        }
      }
    }
  }
}
.text-btn {
  font-size: 14px !important;
  line-height: 20px;
  color: $primary-color !important;
  height: 32px;
  border-radius: 4px;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;
  text-transform: none;
  box-shadow: none !important;
  background-color: transparent;
  &:hover {
    background-color: rgba(0, 117, 201, 0.1);
  }
  &.btn:active:hover {
    background-color: rgba(0, 117, 201, 0.1) !important;
  }
  .ripple-container .ripple-decorator.ripple-on {
    background-color: $primary-color !important;
  }
  &:focus {
    background-color: transparent !important;
    border: 1px solid $primary-color;
  }

  &.has-aligned {
    margin-left: -10px;
  }

  &.dark-yellow {
    color: $yellow-01 !important;

    &:hover {
      background-color: $yellow-03;
    }

    &:focus {
      border-color: $yellow-01;
    }

    .ripple-container {
      .ripple-decorator {
        &.ripple-on {
          background-color: $yellow-03 !important;
          opacity: 0.8;
        }
        &.ripple-out {
          opacity: 0;
        }
      }
    }
  }
}


.text-btn {

}

.rounded-btn {
  height: 56px;
  width: 56px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: $primary-color;
  @include transition(all 0.3s);
  overflow: hidden;
  &:hover {
    background-color: $light-gray-06;
  }
}
.qr-btn {
  max-height: 24px;
}
a {
  &:focus {
    .rounded-btn {
      border: 1px solid $primary-color;
      background-color: transparent;
      outline: none;
    }
  }
}
.btn-primary{
  &.read-more-btn{
    min-width: 140px !important;
  }
}

.select2-container {
  outline: none;
  width: 100% !important;

  &.select2-container--open {
    .select2-selection--single {
      .select2-selection__arrow {
        top: 25px;
        @include rotate(225deg);
      }
    }
  }

  .select2-selection--single {
    background-color: $white;
    border-radius: 4px;
    height: auto;
    border: 2px solid $dark-gray-07;
    outline: none;
    @include transition(all 0.3s);

    &:focus {
      border-color: $primary-color;
    }

    .select2-selection__rendered {
      font-size: 16px;
      line-height: 1.5;
      color: $black-shade-01;
      padding: 16px 35px 16px 16px;
      outline: none;
    }

    .select2-selection__arrow {
      position: absolute;
      height: 10px;
      width: 10px;
      display: block;
      top: 20px;
      right: 15px;
      margin: 2px 0 0;
      border: 2px solid #0075c9;
      border-top: 0;
      border-left: 0;
      @include rotate(45deg);
      @include transition(all 0.3s);

      b {
        display: none;
      }
    }
  }
}

.select2-dropdown {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,.24);
  border: none;
  border-radius: 0;

  .select2-results__option {
    font-size: 16px;
    line-height: 1.5;
    color: $black-shade-01;
    padding: 16px;

    &.select2-results__option--highlighted {
      color: $black;
      background-color: $light-gray-08;
    }

    &.select2-results__option--selected {
      color: $white;
      background-color: $primary-color;
    }
  }
}
.sfp-theme {
  .btn {
    &.disable {
      background-color: $light-gray-06 !important;
      background: $light-gray-06 !important;
      color: $dark-gray-07 !important;
      border-color: $light-gray-06 !important;
      pointer-events: none;
    }
    &.btn-raised {
      &.btn-primary {
        background-color: $sfp-primary-color !important;
        background: $sfp-primary-color !important;
        border: 2px solid $sfp-primary-color !important;
        &:focus {
          box-shadow: none;
          border: 2px solid $sfp-blue-shade-01 !important;
        }
        &:active {
          box-shadow: none;
          border: 2px solid $sfp-blue-shade-01 !important;
        }
        .ripple-container {
          .ripple-decorator {
            &.ripple-on {
              background-color: $sfp-blue-shade-01 !important;
            }
          }
        }
        &.disable {
          background-color: $light-gray-06 !important;
          background: $light-gray-06 !important;
          color: $dark-gray-07 !important;
          border-color: $light-gray-06 !important;
          pointer-events: none;
        }
      }
      &.btn-secondary {
        color: $sfp-primary-color !important;
        &:focus {
          border: 2px solid $sfp-primary-color;
          &:active {
            border: 2px solid $sfp-primary-color;
          }
        }
        &:active {
          border: 2px solid $sfp-primary-color;
          &:hover {
            border: 2px solid $sfp-primary-color;
          }
        }
      }
      &.disable {
        background-color: $light-gray-06 !important;
        background: $light-gray-06 !important;
        border-color: $light-gray-06 !important;
        color: $dark-gray-07 !important;
        pointer-events: none;
      }
    }
  }
  .form-group,
  .form-group.bmd-form-group {
    &:focus {
      border-color: $primary-color !important;
    }
  }
  .text-btn {
    color: $sfp-primary-color !important;
    &:focus {
      border: 1px solid $sfp-primary-color !important;
    }
  }
  .rounded-btn {
    color: $sfp-primary-color !important;
  }
  a {
    &:focus {
      .rounded-btn {
        border: 1px solid $sfp-primary-color !important;
      }
    }
  }
  .select2-dropdown {
    .select2-results__option {
      &.select2-results__option--selected {
        background-color: $sfp-primary-color;
      }
    }
  }
}