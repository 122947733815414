.standalone-banner {
  background: none;
  padding: 27px 0 0;
  .content-block {
    justify-content: flex-start;
  }
}
.profile-block {
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 4px;
  background-color: $white;
  box-shadow: 0 11px 20px 0 rgba(7, 25, 37, 0.08);
  padding: 30px 40px 30px 40px;
  @include bp(sm-max) {
    padding: 30px 20px 30px 20px;
  }
  .img-block {
    height: 193px;
    max-width: 193px;
    margin: 0 auto 30px;
  }
  .content-block {
    @include bp(xl-min) {
      padding: 0 0 0 0;
    }
    @include bp(sm-max) {
      .btn-secondary {
        min-width: 100%;
      }
    }
    h4 {
      text-align: center;
      letter-spacing: -0.8px;
      margin: 0 0 5px 0;
      @include bp(sm-max) {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.6px;
      }
    }
    .sub-title {
      font-size: 28px;
      font-weight: 300;
      letter-spacing: -0.8px;
      line-height: 32px;
      text-align: center;
      display: block;
      margin: 0 0 25px;
      @include bp(md-max) {
        font-size: 26px;
      }
      @include bp(sm-max) {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.6px;
      }
    }
    .social-list {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: space-between;
      margin: 0 0 27px 0;
      li {
        flex: 1 1 auto;
        position: relative;
        text-align: center;
        border-left: solid 1px #E0E4E9;
        &:first-child {
          border-left: none;
        }
        &:last-child {
          border-right: none;
          @include bp(md-max) {
          }
          &:after {
            display: none;
          }
        }
        &:after {
          content: "";
          position: absolute;
          height: 20px;
          width: 1px;
          background: $light-gray-01;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        a {
          i {
            img {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }
}
.tab-block {
  background-color: $light-gray-05;
  margin: -75px 0 0 0;
  padding: 0 0 125px;
  @include bp(sm-max) {
    margin: 0 0 0 0;
    padding: 0 0 0px;
    background: none;
  }
  .row {
    @include bp(sm-max) {
      flex-direction: column-reverse;
    }
  }
  .nav-tabs {
    position: relative;
    top: -77px;
    margin: 0 -24px;
    @include bp(md-max) {
      margin: 0 -18px;
    }
    @include bp(sm-max) {
      top: 0;
    }
    @include bp(xxxs-min) {
      margin: 0 -8px;
      justify-content: left;
      flex-wrap: nowrap;
    }
    .nav-link {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      padding: 10px 18px 10px 18px;
      letter-spacing: -0.2px;
      color: $dark-gray-05;
      text-transform: none;
      border: none;
      position: relative;
      @include bp(md-max) {
        padding: 10px 18px;
      }
      @include bp(sm-max) {
        font-size: 14px;
        line-height: 20px;
      }
      @include bp(xxxs-min) {
        padding: 10px 8px;
      }
      .ripple-container {
        display: none;
      }
      &:after {
        content: "";
        left: 24px;
        right: 24px;
        height: 2px;
        width: 0;
        position: absolute;
        background: $primary-color;
        bottom: 0;
        @include bp(md-max) {
          left: 20px;
          right: 20px;
        }
        @include bp(xxxs-min) {
          left: 8px;
          right: 8px;
        }
      }
      &:hover,
      &.active {
        &:after {
          width: calc(100% - 48px);
          @include bp(md-max) {
            width: calc(100% - 40px);
          }
          @include bp(xxxs-min) {
            width: calc(100% - 16px);
          }
        }
      }
      &.active {
        font-weight: 500;
      }
    }
  }
  .tab-content {
    padding: 40px 0 0 0;
    @include bp(sm-max) {
      background-color: $light-gray-05;
      margin: 20px -20px 0;
      padding: 85px 20px 50px;
    }
    .title {
      margin: 0 0 30px 0;
    }
    .description {
      padding: 0 100px 0 0;
      @include bp(md-max) {
        padding: 0 0 0 0;
      }
      p,
      a {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.2px;
        margin: 0 0 14px 0;
      }
      a {
        color: $primary-color;
        font-weight: 500;
        display: inline-block;
        margin: 15px 0 0 0;
      }
      .text-btn {
        display: inline-flex;
      }
    }
  }
  .contact-detail-outer {
    position: relative;
  }
  .contact-detail-outer-events {
    pointer-events: none;
  }
  .contact-detail-tile-events {
    pointer-events: all;
  }
  &.with-profile-outer {
    margin: 0 0 0 0;
    background-color: transparent;
    padding: 285px 0 0 0;
    background-image: url(../../assets/images/star-graphic.svg);
    background-repeat: no-repeat;
    @include bp(sm-max) {
      padding: 0 0 0 0;
      background: none;
    }
    .tab-content {
      padding: 23px 0 0 0;
      @include bp(sm-max) {
        padding: 70px 20px 50px 20px;
      }
      .description {
        padding: 0 0 0 0;
      }
    }
  }
  .with-profile {
    background-color: $light-gray-05;
    padding: 0 0 140px 0;
    @include bp(sm-max) {
      margin: 0 0 0 0;
      padding: 0 0 0 0;
      background: none;
    }
    .row {
      @include bp(sm-max) {
        flex-direction: column;
      }
    }
    .profile-block {
      margin: -250px 0 0 0;
      padding: 30px 40px 30px;
      @include bp(sm-max) {
        margin: 0 0 0 0;
        padding: 30px 20px 30px 20px;
      }
      .content-block {
        display: flex;
        flex-direction: column;
      }
    }
    .custom-list {
      margin: 0 0 20px 0;
      li {
        padding: 3px 0 3px 35px;
        &:before {
          top: 6px;
        }
      }
    }
    .tabs-outer {
      padding: 0 0 0 112px;
      @include bp(lg-max) {
        padding: 0 0 0 30px;
        @include bp(sm-max) {
          padding: 40px 0 0 0px;
        }
      }
    }
    .contact-detail-tiles {
      margin: 15px 0 0 0;
      @include bp(sm-max) {
        padding: 18px 15px;
        margin: 25px 0 0 0;
      }
    }
  }
}
.contact-detail-tiles {
  background-color: $white;
  margin: 95px 0 0 0;
  padding: 33px 33px 15px 33px;
  box-shadow: 0 11px 20px rgba(7, 25, 37, 0.08);
  border-radius: 4px;
  position: relative;
  z-index: 1;
  @include bp(sm-max) {
    margin: 25px 0 50px 0;
    padding: 30px 15px;
  }
  h5 {
    line-height: 24px;
    letter-spacing: -0.4px;
    margin: 0 0 22px 0;
    @include bp(sm-max) {
      font-size: 18px;
      line-height: 24px;
      font-weight: 700;
    }
  }
  .info-btn-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
      flex: 0 0 100%;
      width: 100%;
      a {
        margin: 0 0 16px 0;
        span {
          overflow: auto;
          overflow-wrap: break-word;
        }
        @include bp(xxxs-max) {
          padding: 15px 10px;
          font-size: 14px;
        }
        i {
          width: 20px;
          min-width: 20px;
          text-align: center;
          &.sm-icon {
            img {
              width: 14px;
              min-width: 14px;
            }
          }
          &.location-icon {
            margin: 0px 10px 0 0;
          }
        }
      }
      &.width-50 {
        flex: 0 0 calc(50% - 8px);
        width: calc(50% - 8px);
        @include bp(lg-max) {
          flex: 0 0 100%;
          width: 100%;
        }
      }
    }
  }
  .tooltip-wrapper {
    display: none;
    @include bp(sm-max) {
      display: block;
    }
  }
}
.tools-calculators {
  &.less-spacing {
    padding: 122px 0 150px;
    @include bp(lg-max) {
      padding: 100px 0 50px;
    }
    @include bp(sm-max) {
      padding: 50px 0 50px;
    }
  }
}
.custom-list {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.2px;
  margin: 0 0 30px 0;
  list-style: none;
  li {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.2px;
    margin: 0 0 10px 0;
    padding: 0 0 0 35px;
    position: relative;
    &:before {
      content: "";
      height: 12px;
      width: 12px;
      border: 3px solid $blue-shade-03;
      border-radius: 100%;
      display: inline-block;
      margin-right: 10px;
      position: absolute;
      top: 3px;
      left: 8px;
    }
  }
}
.view-more-less {
  a {
    font-size: 16px;
    color: $primary-color;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.2px;
    margin: 0 0 14px 0;
    display: block;
  }
  .text-block {
    padding: 30px 25px 35px 40px;
    background-color: $light-gray-06;
    margin: 0 0 30px 0;
    p {
      font-size: 14px;
      color: $dark-gray-02;
      line-height: 20px;
      letter-spacing: -0.2px;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        height: 6px;
        width: 6px;
        background-color: $blue-shade-03;
        border-radius: 100%;
        left: -20px;
        top: 6px;
      }
    }
  }
  .less-btn {
    display: none;
  }
  .view-less-wrap {
    display: none;
  }
  &.read-more {
    .show-more {
      display: none;
    }
  }
  .show-btn {
    border: 2px solid $light-gray-01;
    background-color: transparent;
    border-radius: 4px;
    padding: 8px 10px 8px 10px;
    margin: 0 0 30px 0;
    width: 162px;
    font-size: 14px;
    font-weight: 500;
    color: $primary-color;
    line-height: 20px;
    letter-spacing: -0.2px;
    cursor: pointer;
    text-transform: none;
    &:hover {
      border: 2px solid $light-gray-01;
      background-color: $light-gray-07 !important;
    }
    &:focus {
      border: 2px solid $primary-color;
      background-color: transparent !important;
    }
    @include bp(sm-max) {
      width: 100%;
    }
    &.show-more {
      span {
        &:after {
          content: "";
          width: 14px;
          height: 2px;
          background-color: $primary-color;
          display: inline-block;
          position: absolute;
          top: 8px;
          left: 0px;
          @include rotate(90deg);
        }
        &:before {
          top: 6px;
        }
      }
    }
    &.show-less,
    &.show-more {
      span {
        position: relative;
        width: 20px;
        display: inline-flex;
        height: 14px;
        &:before {
          content: "";
          width: 14px;
          height: 2px;
          background-color: $primary-color;
          display: inline-block;
          position: absolute;
          top: 8px;
          left: 0px;
        }
      }
    }
  }
  .more-less-btn {
    display: block;
    width: 100%;
  }
}
.tooltip-wrapper {
  position: absolute;
  right: 20px;
  .btn-tooltip {
    border: none;
    background: none;
    i {
      max-width: 10px;
      height: 16px;
    }
  }
  .tooltip-modal {
    position: absolute;
    margin: 10px 0 0 0;
    padding: 15px 20px 15px 20px;
    background-color: $white;
    color: $black-shade-02;
    min-width: 260px;
    right: 0;
    border-radius: 4px;
    box-shadow: 0 0 11px 2px rgba(7, 25, 37, 0.08);
    opacity: 0;
    visibility: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
    }
    button {
      border: none;
      background: transparent;
    }
    i {
      max-width: 14px;
    }
  }
  &.show {
    .tooltip-modal {
      opacity: 1;
      visibility: visible;
    }
  }
}
.standalone-theme {
  .financial-goals {
    background: none;
  }
  .banner-section {
    background-image: none;
    .img-block {
      position: relative;
      &:after {
        content: "";
        background: url("../../assets/images/squares-dots.svg") no-repeat bottom left;
        position: absolute;
        left: -73px;
        bottom: -108px;
        height: 100%;
        width: 100%;
        z-index: 0;
        @include bp(sm-max) {
          display: none;
        }
      }
      &.profile-img {
        &:after {
          background: none !important;
        }
      }
    }
  }
  .tab-block {
    &.with-profile-outer {
      background-image: none !important;
    }
  }
}
