.banner-section {
  &.secondary-banner {
    padding: 95px 0 100px 0;
    @include bp(sm-max) {
      padding: 35px 0 0px 0;
    }
    .row {
      align-items: center;
    }
    .content-block {
      padding: 0 160px 0 0;
      @include bp(md-max) {
        padding: 0 100px 0 0;
      }
      @include bp(sm-max) {
        padding: 0 0 0 0;
      }
      h1 {
        letter-spacing: -2px;
        @include bp(sm-max) {
          letter-spacing: -1.6px;
        }
      }
    }
    .more-info {
      font-size: 20px;
      line-height: 32px;
      color: $dark-gray-02;
      letter-spacing: -0.2px;
      margin: -50px 0 0 0;
      @include bp(sm-max) {
        margin: 0px;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.2px;
      }
    }
  }
}
.contact-wrapper {
  .row {
    @include bp(sm-max) {
      flex-direction: column-reverse;
    }
  }
}
.contact-form {
  margin: 70px 0 50px 115px;
  @include bp(lg-max) {
    margin: 50px 0 50px 50px;
  }
  @include bp(md-max) {
    margin: 50px 0 50px 0;
  }
  @include bp(sm-max) {
    margin: 0 0 0 0;
  }
  .btn-wrap {
    display: flex;
    align-items: center;
    @include bp(sm-max){
      flex-wrap: wrap;
    }
    p {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.2px;
      color: $dark-gray-08;
      @include bp(sm-max){
        width: 100%;
        margin: 0 0 30px 0;
      }
      a {
        color: $primary-color;
      }
    }
    .btn.btn-raised {
      &.btn-primary {
        min-width: 116px;
        font-size: 16px !important;
        line-height: 24px;
        margin: 0 30px 0 0;
        @include bp(sm-max){
          height: 60px;
          line-height: 60px;
          width: auto;
          margin: 0 0 20px 0;
        }
      }
    }
  }
}
.contact-detail {
  padding: 70px 112px 110px 112px;
  background-color: $light-gray-05;
  @include bp(lg-max) {
    padding: 60px 60px 60px 60px;
  }
  @include bp(md-max) {
    padding: 50px 20px 50px 20px;
  }
  @include bp(sm-max) {
    margin: 0 -20px;
  }
  h4 {
    margin: 0 0 30px 0;
    letter-spacing: -0.8px;
    @include bp(sm-max) {
      font-size: 28px;
      line-height: 32px;
    }
  }
  .accordion {
    margin: 0 0 45px 0;
    .card {
      background: transparent;
      box-shadow: none;
      .card-body {
        padding: 0 0 20px 0;
      }
      .card-header {
        padding: 10px 0;
        margin: 0 0 20px 0;
        border: none;
        a {
          font-size: 20px;
          line-height: 24px;
          letter-spacing: -0.4px;
          color: $black;
          font-weight: 300;
          display: flex;
          justify-content: space-between;
          padding: 0 3px 0 0;
          cursor: pointer;
          i {
            &:after {
              content: "";
              height: 10px;
              width: 10px;
              display: block;
              margin: 2px 0 0 0;
              border: 2px solid $primary-color;
              border-top: 0;
              border-left: 0;
              position: absolute;
              right: 10px;
              top: 12px;
              @include rotate(45deg);
            }
          }
        }
        a[aria-expanded="true"] {
          color: $primary-color;
          i {
            &:after {
              margin: 7px 0 0 0;
              @include rotate(225deg);
            }
          }
        }
      }
    }
  }
}
ul {
  &.info-btn-list {
    a {
      font-size: 16px;
      font-family: $roboto;
      line-height: 20px;
      font-weight: 500;
      letter-spacing: -0.2px;
      color: $dark-gray-05;
      background-color: $light-gray-06;
      padding: 18px 15px 18px 15px;
      display: flex;
      align-items: center;
      margin: 0 0 10px 0;
      border-radius: 4px;
      &.location-wrap {
        align-items: flex-start;
        i {
          margin: 4px 10px 0 0;
        }
      }
      i {
        margin: 0px 10px 0 0;
        width: 18px;
        min-width: 18px;
      }
    }
  }
}