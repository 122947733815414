/* Reset 
-----------*/
::-moz-selection,
::selection,
::-webkit-selection {
  background: $primary-color;
  color: $white;
}

* {
  box-sizing: border-box;
}

.wrapper {
  min-height: 100%;
}

html {
  font-size: 62.5%;
  height: 100%;
  scroll-behavior: smooth;
}

body {
  padding: 0;
  margin: 0;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  overflow-x: hidden;
  font-family: $roboto;
  height: 100%;
  background-color: $white;
  -webkit-font-smoothing: antialiased;

  &.open-menu {
    overflow: hidden;
  }
}

.modal-open {
  &.browser-ios {
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
  }
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style-type: none; 
}


a {
  &:hover,
  &:visited,
  &:active {
    text-decoration: none;
    outline: none;
    box-shadow: none;
  }
}

a,
button,
*::after,
*::before {
  @include transition(0.3s all);
}

i {
  display: inline-block;
  line-height: 0;

  img {
    height: 100%;
    width: 100%;
  }
}

em {
  display: inline-block;
  line-height: 0;

  img {
    max-width: 100%;
    height: auto;
  }
}

button {
  cursor: pointer;
  @include transition(all 0.3s);

  &:hover,
  &:focus {
    outline: none;
  }
}

.link-wrap {
  display: block;
  text-align: center;
}

.container {
  max-width: 1128px;
}

.container-banner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  
  // required to show the background graphic
  z-index: -1;

  img {
    height: 100%;
    min-width: 100%;
    object-fit: cover;
  }
}

.container, .container-fluid {
  padding: 0 20px;
}

body {
  &:after {
    content: "";
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 6;
    opacity: 0;
    visibility: hidden;
  }

  &.open-menu {
    &:after {
      opacity: 1;
      visibility: visible;
    }
  }
}

.btn:focus {
  outline: none;
}
