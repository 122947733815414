.slice-wrapper {
  position: relative;

  .slice-wrapper-anchor {
    position: absolute;
    top: -14rem;
  }
}

.stacked-card-section {
  padding: 96px 0 128px;

  @include bp(md-max) {
    padding: 50px 0;
  }

  .title {
    margin: 0 0 32px;
  }

  .stacked-card-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 100%;
    padding: 30px 24px 40px;
    @include border-radius(4px);
    background-color: $light-gray-05;
    @include transition(all 0.3s);

    @include bp(sm-max) {
      padding: 30px 25px;
    }

    &:hover {
      background-color: $primary-color;

      .img-block {
        .main-img {
          opacity: 0;
          visibility: hidden;
        }

        .hover-img {
          opacity: 1;
          visibility: visible;
        }
      }

      h5 {
        color: $white;
      }

      p {
        color: $light-gray-06;
      }

      .blue-link {
        color: $white;
      }

      .text-btn {
        color: $white !important;

        &:focus {
          border-color: $white;
        }
      }
    }

    .inner-wrap {
      margin: 0 0 42px;

      @include bp(sm-max) {
        margin: 0 0 25px;
      }
    }

    .img-wrap {
      margin: 0 0 30px;

      @include bp(sm-max) {
        margin: 0 0 15px;
      }
    }

    .img-block {
      display: inline-block;
      position: relative;
      max-width: 28px;
      width: 100%;

      img {
        @include transition(all 0.3s);
      }

      .hover-img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
        visibility: hidden;
      }
    }

    h5 {
      line-height: 1.35;
      color: $black;
      letter-spacing: -0.4px;
      font-family: $roboto;
      margin: 0 0 18px;
      @include transition(all 0.3s);

      @include bp(sm-max) {
        margin: 0 0 15px;
      }
    }

    p {
      color: $dark-gray-02;
      line-height: 1.45;
      letter-spacing: -0.2px;
      @include transition(all 0.3s);
    }

    .btn-block {
      margin-top: auto;
    }
  }
}

.card-slider-section {
  padding: 96px 0 128px;

  @include bp(md-max) {
    padding: 50px 0;
  }

  .title {
    margin: 0 0 32px;
  }

  .card-slider {

    .slide-card {
      padding: 33px 25px 60px;

      @include bp(sm-max) {
        padding: 30px 25px;
      }

      @include bp(xs-max) {
        padding: 30px 15px;
      }

      h5 {
        margin: 0 0 8px;
      }
    }
  }
}

.blog-card-section {
  padding: 96px 0;

  @include bp(md-max) {
    padding: 50px 0;
  }

  .title {
    margin: 0 0 32px;
    
    .blog-heading {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      
      .blog-action {
        color: $primary-color;
      }
      
      @include bp(sm-max) {
        display: inline-table;
        
        p {
          margin-top: 20px;
        }
      }
    }
  }

  .card-slider {

    .slide-card {

      .content-block {
        padding: 25px 25px 0;

        @include bp(sm-max) {
          padding: 15px 25px 0;
        }
      }

      h5 {
        margin: 0 0 5px;
      }
    }
  }
}

.swiper-container-horizontal {
  .swiper-scrollbar {
    background-color: $light-gray-03;
    height: 4px;
    border-radius: 0;
    left: 0;
    bottom: 0;
    width: 100%;

    @include bp(md-max) {
      left: auto;
      right: 0;
      max-width: 100%;
      margin: 0 auto;
    }

    .swiper-scrollbar-drag {
      background-color: $primary-color;
      @include border-radius(inherit);
    }
  }
}

.card-slider {
  cursor: grab;

  .swiper-wrapper {
    margin: 0 0 68px;

    @include bp(sm-max) {
      margin: 0 0 40px;
    }
  }

  .swiper-scrollbar {
    max-width: 1088px;

    @include bp(md-max) {
      max-width: 100%;
    }
  }

  .swiper-slide {
    height: auto;
  }

  .slide-card {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background-color: $light-gray-05;
    @include border-radius(4px);
    height: 100%;
    @include transition(all 0.3s);

    .slide-img-block {
      margin: 0 0 25px;

      @include bp(sm-max) {
        margin: 0 0 15px;
      }
    }

    .img-block {
      display: inline-block;
      position: relative;
      max-width: 35px;
      width: 100%;

      img {
        @include transition(all 0.3s);
      }

      .hover-img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
        visibility: hidden;
      }
    }

    h5 {
      line-height: 1.4;
      letter-spacing: -0.4px;
      color: $black;
      @include transition(all 0.3s);
    }

    p {
      line-height: 1.45;
      color: $dark-gray-02;
      letter-spacing: -0.2px;
      @include transition(all 0.3s);
    }

    .link-block {
      padding: 11px 25px 22px;
      margin-top: auto;
    }
  }
}

.two-card-section {
  padding: 86px 0 128px;

  @include bp(md-max) {
    padding: 50px 0;
  }

  .title {
    margin: 0 0 32px;
  }

  .card-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 100%;
    padding: 35px 40px 45px;
    @include border-radius(4px);
    background-color: $light-gray-05;
    @include transition(all 0.3s);

    @include bp(sm-max) {
      padding: 30px 25px;
    }

    .inner-wrap {
      margin: 0 0 64px;

      @include bp(sm-max) {
        margin: 0 0 40px;
      }
    }

    .img-wrap {
      margin: 0 0 32px;

      .card-label {
        margin: 0 0 20px;

        span {
          font-size: 10px;
          line-height: 1.6;
          letter-spacing: 1.42px;
          text-transform: uppercase;
          color: $primary-color;
          font-family: $roboto;
          font-weight: 500;
          background-color: $white;
          border-radius: 4px;
          display: inline-block;
          padding: 4px 8px;
          @include transition(all 0.3s);
        }
      }

      @include bp(sm-max) {
        margin: 0 0 15px;
      }
    }

    .img-block {
      display: inline-block;
      position: relative;
      max-width: 35px;
      width: 100%;

      img {
        @include transition(all 0.3s);
      }

      .hover-img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
        visibility: hidden;
      }
    }

    h4 {
      font-size: 24px;
      line-height: 1.2;
      color: $black;
      letter-spacing: -0.6px;
      font-family: $roboto;
      margin: 0 0 24px;
      @include transition(all 0.3s);

      @include bp(sm-max) {
        font-size: 16px;
        margin: 0 0 15px;
      }
    }

    p {
      color: $dark-gray-02;
      line-height: 1.5;
      letter-spacing: -0.2px;
      @include transition(all 0.3s);
    }

    .btn-block {
      margin-top: auto;
    }
  }
}

.product-card-section {
  padding: 100px 0 128px;

  @include bp(md-max) {
    padding: 50px 0;
  }

  .title {
    margin: 0 0 32px;
  }

  .label-card-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 100%;
    padding: 32px 40px 45px;
    border-radius: 4px;
    background-color: $light-gray-05;
    @include transition(all 0.3s);

    @include bp(sm-max) {
      padding: 30px 25px;
    }

    .inner-wrap {
      margin: 0 0 32px;
    }

    .card-label {
      margin: 0 0 20px;

      span {
        font-size: 10px;
        line-height: 1.6;
        letter-spacing: 1.42px;
        text-transform: uppercase;
        color: $primary-color;
        font-family: $roboto;
        font-weight: 500;
        background-color: $white;
        border-radius: 4px;
        display: inline-block;
        padding: 4px 8px;
        @include transition(all 0.3s);
      }
    }

    h4 {
      font-size: 24px;
      line-height: 1.2;
      letter-spacing: -0.6px;
      color: $black;
      font-family: $roboto;
      font-weight: 500;
      margin: 0 0 32px;
      @include transition(all 0.3s);

      @include bp(sm-max) {
        font-size: 16px;
        margin: 0 0 15px;
      }
    }

    .price-block {
      margin-top: auto;

      p {
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: -0.2px;
        color: $dark-gray-02;
        font-family: $roboto;
        font-weight: 700;
        margin: 0 0 4px;
        @include transition(all 0.3s);

        span {
          display: inline-block;
          color: $primary-color;
          @include transition(all 0.3s);
        }
      }
    }
  }

  .custom-list {
    margin: 0;
  }
}

.content-cards-slice {
  @include bp(md-max) {
    padding: 50px 0;
  }

  .title {
    margin: 0 0 32px;
  }

  .content-card-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-bottom: 42px;
    height: 100%;
    border-radius: 4px;
    background-color: $light-gray-05;
    @include transition(all 0.3s);

    .card-heading-block {
      padding: 30px 30px 0 30px;
    }

    .inner-wrap {
      margin: 0;
      padding: 20px 30px;
    }

    .file-icon {
      max-width: 30px;
      width: 100%;
      display: block;
      margin: 0 0 20px;
    }

    .card-label {
      margin: 0 0 20px;

      span {
        font-size: 10px;
        line-height: 1.6;
        letter-spacing: 1.42px;
        text-transform: uppercase;
        color: $primary-color;
        font-family: $roboto;
        font-weight: 500;
        background-color: $white;
        border-radius: 4px;
        display: inline-block;
        padding: 4px 8px;
        @include transition(all 0.3s);
      }
    }

    .card-img {
      display: block;
      margin: 0 !important;
      padding: 0 !important;
      border-radius: 0;

      img {
        max-width: 100%;
        width: 100%;
        height: auto;
      }
    }

    h4 {
      font-size: 20px;
      line-height: 1.2;
      letter-spacing: -0.6px;
      color: $black;
      font-family: $roboto;
      font-weight: 500;
      margin: 0 0 32px;
      @include transition(all 0.3s);

      @include bp(sm-max) {
        font-size: 16px;
        margin: 0 0 15px;
      }
    }

    .feature-icon {
      padding-right: 12px;

      img {
        width: 20px;
        height: 20px;
      }
    }

    .download-links {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-top: auto;
      padding: 0 30px;

      .download-link {
        display: inherit;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        font-size: 14px;
        line-height: 1.45;
        letter-spacing: -0.2px;
        color: $primary-color;
        font-family: $roboto;
        font-weight: 500;
        margin-right: 19px;

        &:last-child {
          margin-right: 0;
        }
      }

      .download-icon {
        width: 10px;
        margin-right: 11px;
      }
    }

    .custom-list {
      margin: 0;
    }
  }

}


/* two-col-accordion*/
.two-col-accordion {
  padding: 98px 0 66px;
  @include bp(md-max) {
    padding: 48px 5px 30px;
  }

  .right-col {
    padding-left: 0;
    @include bp(sm-max) {
      padding-left: 15px;
    }
  }
}

/* content-dual-slice-section */
.content-dual-slice {
  padding: 98px 0 85px;
  @include bp(md-max) {
    padding: 50px 5px 47px;
  }

  .content-step-wrapper {
    .content-inner {
      position: relative;
      padding-left: 40px;
      @include bp(sm-max) {
        padding-left: 27px;
        margin-bottom: 35px;
      }

      &:before {
        content: "";
        position: absolute;
        top: 3px;
        left: 5px;
        height: 12px;
        width: 12px;
        border: 3px solid $blue-shade-03;
        border-radius: 100%;
      }

      p {
        color: $dark-gray-02;
        margin-bottom: 16px;
        @include bp(sm-max) {
          margin-bottom: 20px;
        }

        b {
          font-weight: 700;
        }
      }

      ul {
        li {
          padding-left: 32px;

          &:before {
            left: 10px;
          }
        }
      }
    }
  }
}

/* content-slice-section */
.content-slice {
  &.additional-content {
    padding: 128px 0;
    @include bp(md-max) {
      padding: 40px 5px;
    }

    .container {
      max-width: 910px;
    }

    .content-slice-detail, .content-slice-detail-blog {
      @include bp(sm-max) {
        padding-right: 10px;
      }

      p {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.2px;
        color: $dark-gray-05;
        margin-bottom: 30px;
        @include bp(sm-max) {
          margin-bottom: 23px;
        }
      }

      a {
        display: inline-flex;
        justify-content: center;
        align-items: center;
      }
    }

    .content-slice-detail-blog {
      ol,ul, dl {
        margin-block-start: 1em !important;
        margin-block-end: 1em !important;
        margin-inline-start: 0px !important;
        margin-inline-end: 0px !important;
        padding-inline-start: 40px !important;
        }
      ol {
            list-style-type: decimal !important;
      }
      ul {
            list-style-type: unset !important;
      }
    }
  }
}

.content-slice {
  background-color: $white;
  padding: 40px 0;
  @include bp(md-max) {
    padding: 20px 5px;
  }

  &.contact-form {
    background-color: $light-gray-05;
    margin: 0;

    .form-control {
      background-color: $white;
    }
  }

  &.embedded-product-tool {
    iframe {
      width: 100%;
      border: none;
    }
  }

  &.about-contents-slice {
    .sfp-wheel {
      width: 100%;
      max-width: 720px;
      margin: 30px auto 0;
      padding-top: 30px;

      img {
        max-width: 100%;
      }
    }
  }

  .content-slice-detail, .content-slice-detail-blog {
    @include bp(sm-max) {
      padding-right: 10px;
    }

    &.blog-listing {
      ol {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.2px;
        margin: 0 0 30px 0;
        list-style: -moz-ethiopic-numeric;
        li {
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.2px;
          margin: 0 0 10px 0;
          padding: 0 0 0 10px;
          position: relative;
          left: 25px;
          list-style-type: unset;;
        }  
      }
      ul {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.2px;
        margin: 0 0 30px 0;
        list-style: none;
        li {
          list-style-type: unset;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.2px;
          margin: 0 0 10px 0;
          padding: 0 0 0 35px;
          position: relative;
        }
        li:before {
          content: "";
          height: 12px;
          width: 12px;
          border: 3px solid $primary-color;
          border-radius: 100%;
          display: inline-block;
          margin-right: 10px;
          position: absolute;
          top: 3px;
          left: 8px;
        }
      }
    }
    
    li {
     list-style-type: unset; 
    }

    p {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.2px;
      color: $dark-gray-05;
      margin-bottom: 30px;
      @include bp(sm-max) {
        margin-bottom: 23px;
      }
    }

    a {
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.form-section {
  padding: 96px 0 128px;

  .title {
    margin: 0 0 32px;

    &:last-child {
      margin: 0;
    }
  }

  @include bp(md-max) {
    padding: 50px 0;
  }

  .col-lg-5 {
    @include bp(lg-min) {
      flex: 0 0 38%;
      max-width: 38%;
    }

    @include bp(md-max) {
      margin: 0 0 30px;
    }
  }

  .col-lg-7 {
    @include bp(lg-min) {
      flex: 0 0 62%;
      max-width: 62%;
    }
  }

  .form-wrap {
    p {
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: -0.2px;
      color: $dark-gray-05;
      margin: 0 0 32px;

      @include bp(md-max) {
        margin: 0 0 20px;
      }
    }

    .form-block {
      p {
        color: $black-shade-01;
        margin: 0 0 8px;
        font-weight: 700;
      }
    }
  }
}

.img-content-section {
  padding: 96px 0 112px;

  @include bp(md-max) {
    padding: 50px 0;
  }

  .img-block {
    @include bp(sm-max) {
      max-width: 500px;
      margin: 0 0 30px;
    }
  }

  .content-block {
    padding-top: 35px;
    padding-left: 120px;

    @include bp(lg-max) {
      padding-left: 60px;
    }

    @include bp(md-max) {
      padding: 0;
    }

    .title {
      margin: 0 0 35px;

      @include bp(sm-max) {
        margin: 0 0 25px;
      }
    }

    .content {
      margin: 0 0 20px;

      @include bp(sm-max) {
        margin: 0 0 10px;
      }

      p {
        font-size: 16px;
        line-height: 1.5;
        color: $dark-gray-05;
        letter-spacing: -0.2px;
      }
    }
  }
}

.testimonial-section {
  padding: 128px 0 80px;

  @include bp(md-max) {
    padding: 50px 0;
  }

  .section-wrap {
    .col-lg-4 {
      @include bp(md-max) {
        margin: 0 0 30px;
      }
    }
  }

  .title {
    margin: 0 0 32px;

    @include bp(lg-min) {
      padding-right: 70px;
    }

    &:last-child {
      margin: 0;
    }
  }
}

.testimonial-slider {
  cursor: grab;

  .swiper-wrapper {
    margin: 0 0 64px;

    @include bp(sm-max) {
      margin: 0 0 40px;
    }
  }

  .swiper-scrollbar {
    max-width: 655px;

    @include bp(md-max) {
      max-width: 100%;
    }
  }

  .swiper-slide {
    height: auto;
  }

  .testimonial-slide {
    height: 100%;
    background-color: $light-gray-09;
    @include border-radius(4px);
    padding: 40px 37px 48px;
    @include transition(all 0.3s);

    @include bp(md-max) {
      padding: 27px 30px;
    }

    @include bp(xs-max) {
      padding: 24px;
    }

    h5 {
      line-height: 1.4;
      letter-spacing: -0.4px;
      color: $black;
      margin: 0 0 20px;
      @include transition(all 0.3s);

      &:last-child {
        margin: 0;
      }
    }

    p {
      font-size: 20px;
      line-height: 1.6;
      letter-spacing: -0.25px;
      color: $dark-gray-02;
      margin: 0 0 20px;
      @include transition(all 0.3s);

      @include bp(md-max) {
        font-size: 16px;
      }

      &:last-child {
        margin: 0;
      }
    }
  }
}

.table-section {
  padding: 96px 0;

  @include bp(md-max) {
    padding: 50px 0;
  }

  .row-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -40px;

    @include bp(sm-max) {
      margin: 0;
    }

    .block-title {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 30%;
      flex: 0 0 30%;
      max-width: 30%;
      padding: 0 40px;

      @include bp(md-max) {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        margin: 0 0 40px;
      }

      @include bp(sm-max) {
        padding: 0;
      }
    }

    .table-blocks {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 35%;
      flex: 0 0 35%;
      max-width: 35%;
      padding: 0 40px;

      @include bp(md-max) {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
      }

      @include bp(sm-max) {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0 0 24px;
        margin: 0 0 16px;
        border-bottom: 1px solid $light-gray-01;

        &:last-child {
          padding: 0;
          margin: 0;
          border: none;
        }
      }
    }
  }

  .title {
    margin: 0 0 32px;

    &:last-child {
      margin: 0;
    }

    h1 {
      letter-spacing: -1.97px;
      color: $black;
      margin: 0 0 4px;
      font-weight: 300;

      img {
        display: inline-block;
        vertical-align: super;
      }
    }

    .grey-text {
      font-size: 14px;
      line-height: 1.45;
      letter-spacing: -0.2px;
      color: $dark-gray-02;
      margin: 0 0 32px;

      @include bp(md-max) {
        margin: 0 0 15px;
      }
    }

    .btn.btn-raised.btn-primary {
      font-size: 14px !important;
      line-height: 1.75 !important;
      letter-spacing: -0.17px;
      padding: 6px 30px;
      width: auto;
      height: auto;
    }
  }

  .table-blocks {

    .table-block {
      height: 100%;
    }

    .inner-table {
      margin: 0 0 24px;

      @include bp(sm-max) {
        margin: 0 0 20px;
      }
    }

    .table-title {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      margin: 0 0 32px;

      &.open {
        .down-arrow {
          @include rotateX(180deg);
        }
      }

      @include bp(md-max) {
        margin: 0 0 20px;
      }

      @include bp(sm-max) {
        margin: 0;
        cursor: pointer;
      }

      .inner-wrap {
        @include bp(sm-max) {
          padding-right: 10px;
        }
      }

      h5 {
        line-height: 1.4;
        letter-spacing: -0.4px;
        color: $black;
        margin: 0 0 4px;
      }

      p {
        font-size: 12px;
        line-height: 1.4;
        letter-spacing: -0.2px;
        color: $dark-gray-02;
      }

      .down-arrow {
        max-width: 12px;
        width: 100%;
        display: none;
        @include transition(all 0.5s);

        @include bp(sm-max) {
          display: block;
        }
      }
    }

    .table-content {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;

      @include bp(sm-max) {
        padding: 20px 0 0;
        display: none;
      }

      .link-block {
        margin-top: auto;
      }
    }

    .inner-table {
      tr {
        border-bottom: 1px solid $light-gray-01;

        &:first-child {
          border-top: 1px solid $light-gray-01;
        }

        td {
          font-size: 16px;
          line-height: 2.1;
          letter-spacing: -0.2px;
          color: $dark-gray-05;
          padding: 6px 0;

          @include bp(md-max) {
            font-size: 14px;
          }

          &.bold {
            width: 100%;
            font-weight: 500;
          }
        }
      }
    }
  }
}

.form-block {
  background-color: $light-gray-05;
  border: 1px solid $light-gray-05;
  @include border-radius(4px);
  padding: 30px;

  @include bp(md-max) {
    padding: 20px;
  }

  p {
    color: $black-shade-01;
    margin: 0 0 8px;
    font-weight: 700;
  }

  .form-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;

    .custom-select {
      background-image: none;
      width: 100%;
      padding: 0;
      font-size: 16px;
      line-height: 1.5;
      color: $black-shade-01;
      border: none;
      box-shadow: none;
      background-color: transparent;
      height: auto;
      @include transition(all 0.3s);

      &.disable {
        color: $dark-gray-03;

        .dk-selected {
          border: 2px solid $light-gray-01;
        }
      }

      &.dk-select-open-down, &.dk-select-open-up {
        .dk-selected {
          &:after {
            top: 25px;
            @include rotate(225deg);

            @include bp(md-max) {
              top: 20px;
            }
          }
        }
      }

      .dk-selected {
        padding: 16px 35px 16px 16px;
        background-color: #fff;
        border: 2px solid $dark-gray-07;
        @include transition(all 0.3s);
        border-radius: 4px;

        @include bp(md-max) {
          padding: 12px 35px 12px 16px;
        }

        &:focus {
          border-color: $primary-color;
        }

        &:before {
          display: none;
        }

        &:after {
          content: "";
          height: 10px;
          width: 10px;
          display: block;
          top: 20px;
          right: 15px;
          margin: 2px 0 0;
          border: 2px solid $primary-color;
          border-top: 0;
          border-left: 0;
          @include rotate(45deg);

          @include bp(md-max) {
            top: 15px;
          }
        }
      }

      .dk-select-options {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .24);
        border: none;
        border-radius: 0;
        max-height: 180px;
        margin: 0;

        .dk-option {
          padding: 16px;
          color: $black-shade-01;

          &.hide {
            display: none;
          }

          &.dk-option-highlight {
            color: $black;
            background-color: $light-gray-08;
          }

          &.dk-option-selected {
            color: $white;
            background-color: $primary-color;
          }

          @include bp(md-max) {
            padding: 12px 16px;
          }
        }
      }
    }
  }

  .dropdown-block {
    padding-right: 16px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 63%;
    flex: 0 0 63%;
    max-width: 63%;

    @include bp(xs-max) {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0;
      margin: 0 0 15px;
    }

    .option-list {
      right: 16px;

      @include bp(xs-max) {
        right: 0;
      }
    }

    &.disable {
      .select2-container {
        .select2-selection--single {
          border-color: $light-gray-01;

          &:focus {
            border-color: $primary-color;
          }

          .select2-selection__rendered {
            color: $dark-gray-03;
          }
        }
      }
    }
  }

  .btn.btn-raised.btn-primary {
    line-height: 1.5 !important;
    padding: 16px 31px;
    margin: 0;
    min-width: auto;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 37%;
    flex: 0 0 37%;
    max-width: 37%;
    height: auto;

    @include bp(md-max) {
      padding: 12px 30px;
    }

    @include bp(xs-max) {
      font-size: 14px;
      padding: 8px 30px;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

.dropdown-block {
  position: relative;
  width: 100%;
  cursor: pointer;

  &.open {
    .selected-text {
      border-radius: 4px 4px 0 0;
    }

    .down-arrow {
      @include rotateX(180deg);
    }
  }

  .selected-text {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 18px 16px;
    background-color: $white;
    border: 2px solid $dark-gray-07;
    @include border-radius(4px);
    @include transition(all 0.3s);

    @include bp(md-max) {
      padding: 12px 16px;
    }

    &.disable {
      border-color: $light-gray-01;

      span {
        color: $dark-gray-03;
      }
    }

    span {
      font-size: 16px;
      line-height: 1.5;
      color: $black-shade-01;
      @include transition(all 0.3s);
    }

    .down-arrow {
      margin-left: 5px;
      max-width: 14px;
      width: 100%;
      @include transition(all 0.5s);
    }
  }

  .option-list {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 98%;
    border: 2px solid $dark-gray-07;
    border-radius: 0 0 4px 4px;
    background-color: $white;
    z-index: 10;
    list-style: none;

    li {
      font-size: 16px;
      line-height: 1.5;

      &.active {
        display: none;
      }

      a {
        display: block;
        padding: 16px;
        color: $black-shade-01;
        @include transition(all 0.3s);

        @include bp(md-max) {
          padding: 12px 16px;
        }

        &:hover {
          color: $white;
          background-color: $primary-color;
        }
      }
    }
  }
}

.card-row {
  margin: 0 0 40px;

  @include bp(sm-max) {
    margin: 0 0 25px;
  }

  &:last-child {
    margin: 0;
  }

  .col-md-4, .col-md-6 {
    @include bp(sm-max) {
      margin-top: 25px;

      &:first-child {
        margin: 0;
      }
    }
  }

  .expandable-block {
    margin-top: 32px;
    display: none;

    @include bp(sm-max) {
      margin-top: 20px;
    }
  }
}

.btn-wrapper {
  &.center {
    text-align: center;
  }

  .view-btn {
    font-size: 16px;
    line-height: 1.5;
    font-family: $roboto;
    font-weight: 500;
    padding: 16px 33px;
    border: 2px solid $light-gray-01;
    border-radius: 4px;
    letter-spacing: -0.2px;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    @include transition(all 0.3s);
    position: relative;

    @include bp(md-max) {
      font-size: 14px;
      padding: 10px 20px;
    }

    @include bp(sm-max) {
      width: 100%;
    }

    &:hover {
      background-color: $light-gray-07;
    }

    &:focus {
      background-color: transparent;
      border-color: $primary-color;
    }

    span {
      display: -webkit-inline-box;
      display: -webkit-inline-flex;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      color: $primary-color;
      @include transition(all 0.7s ease-in-out);
    }

    .icon {
      display: inline-block;
      font-style: normal;
      margin-right: 13px;
      height: 14px;
      width: 14px;
      position: relative;

      @include bp(md-max) {
        width: 12px;
        height: 12px;
      }

      &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        @include translateY(-50%);
        height: 2px;
        width: 100%;
        background-color: $primary-color;
        @include transition(all 0.7s ease-in-out);
      }

      &.plus {
        &:after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 50%;
          @include translateX(-50%);
          width: 2px;
          height: 100%;
          background-color: $primary-color;
          @include transition(all 0.7s ease-in-out);
        }
      }
    }

    &.text-change {
      .more-text {
        opacity: 0;
        visibility: hidden;
      }

      .less-text {
        opacity: 1;
        visibility: visible;
      }
    }

    .less-text {
      position: absolute;
      left: 0;
      right: 0;
      opacity: 0;
      visibility: hidden;
    }
  }

  .btn {
    &.btn-raised {
      &.btn-secondary {
        line-height: 1.5 !important;
        height: auto;
        min-width: auto;
        background-color: transparent !important;

        @include bp(md-max) {
          font-size: 14px !important;
          padding: 10px 20px;
        }

        @include bp(sm-max) {
          width: 100%;
        }

        &:hover {
          color: $white;

          span {
            color: $white;
          }

          .icon {
            &:before, &:after {
              background-color: $white;
            }
          }
        }
      }
    }
  }
}

.blue-link {
  display: inline-block;
  font-size: 14px;
  line-height: 1.5;
  color: $primary-color;
  font-family: $roboto;
  font-weight: 500;
  @include transition(all 0.3s);
}

.title {
  &.short-length {
    max-width: 700px;
    width: 100%;

    @include bp(sm-max) {
      max-width: 500px;
    }
  }
}

.accordion-wrapper {
  .custom-accordion {
    margin: 0 0 32px;

    @include bp(sm-max) {
      margin: 0 0 24px;
    }

    .accordion-title[aria-expanded="true"] {
      color: $primary-color;
    }

    .accordion-title {
      font-size: 24px;
      line-height: 1.2;
      letter-spacing: -0.6px;
      color: $black;
      font-weight: 300;

      @include bp(sm-max) {
        font-size: 20px;
        letter-spacing: -0.4px;
      }
    }
  }

  .accordion-block {
    padding: 0 0 24px;
    padding-left: 40px;
    margin: 0 0 24px;
    border-bottom: 1px solid $light-gray-01;
    position: relative;

    @include bp(md-max) {
      padding: 0 0 20px 40px;
      margin: 0 0 15px;
    }

    @include bp(sm-max) {
      padding: 0 0 15px 40px;
    }

    &:last-child {
      border: none;
      margin: 0;
      padding-bottom: 0;
    }

    .number {
      height: 24px;
      width: 24px;
      font-size: 14px;
      line-height: 1.5;
      letter-spacing: -0.35px;
      color: $white;
      font-weight: 300;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 50%;
    }

    .accordion-title[aria-expanded="true"] {
      h5 {
        color: $primary-color !important;
      }
    }

    .accordion-title {
      h5 {
        line-height: 28px;
        letter-spacing: -0.6px;
        color: $black;
        margin: 0 0 4px;
        font-weight: 300;
        height: 28px;
        width: 592px;
        font-size: 24px;

        @include bp(sm-max) {
          width: auto;
          font-size: 16px;
          line-height: 1.3;
          letter-spacing: -0.19px;
          margin: 0 0 8px;
        }

        &:last-child {
          margin: 0;
        }
      }
    }

    .accordion-content {
      padding: 24px 0 0;

      @include bp(md-max) {
        padding: 15px 0 0;
      }

      @include bp(sm-max) {
        padding: 12px 0 0;
      }

      p {
        margin: 0 0 24px;

        @include bp(md-max) {
          margin: 0 0 15px;
        }
      }

      .inner-content {
        margin: 0 0 16px;

        @include bp(md-max) {
          margin: 0 0 12px;
        }

        &:last-child {
          margin: 0;
        }

        p {
          margin: 0 0 5px;

          @include bp(md-max) {
            margin: 0 0 2x;
          }
        }
      }
    }

    p {
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: -0.2px;
      color: $dark-gray-05;
      font-family: $roboto;

      &:last-child {
        margin: 0;
      }

      &.bold {
        font-weight: 500;
      }
    }

    .links-block {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      .inner-link {
        display: inherit;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        font-size: 14px;
        line-height: 1.45;
        letter-spacing: -.2px;
        color: $primary-color;
        font-family: $roboto;
        font-weight: 500;
        margin-right: 26px;
        height: auto;
        text-align: left;
        white-space: nowrap;

        &:last-child {
          margin-right: 0;
        }

        .link-icon {
          width: 16px;
          margin-right: 11px;
          margin-bottom: 2px;
        }
      }
    }
  }

  .accordion-title[aria-expanded="true"] {
    &:after {
      top: 10px;
      @include rotate(225deg);
    }
  }

  .accordion-title {
    cursor: pointer;
    position: relative;
    padding-right: 35px;
    @include transition(all 0.5s);

    &:after {
      content: "";
      height: 8px;
      width: 8px;
      display: block;
      margin: 2px 0 0;
      border: 2px solid $primary-color;
      border-top: 0;
      border-left: 0;
      position: absolute;
      right: 5px;
      top: 6px;
      @include rotate(45deg);
      @include bp(sm-max) {
        top: 5px;
        right: 15px;
      }
    }
  }

  .accordion-detail {
    display: none;
    padding: 24px 0px;

    &.show {
      display: block;
    }

    @include bp(sm-max) {
      padding: 11px 0;
    }

    p {
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: -0.2px;
      color: $dark-gray-05;
      margin: 0 0 10px;

      &:last-child {
        margin: 0;
      }

      @include bp(sm-max) {
        font-size: 14px;
        line-height: 20px;
      }

    }
  }
}

.custom-list {
  &.disc-style {
    ol {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.2px;
      margin: 0 0 30px 0;
      list-style: -moz-ethiopic-numeric;
      li {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.2px;
        margin: 0 0 10px 0;
        padding: 0 0 0 10px;
        position: relative;
        left: 25px;
        list-style-type: unset;;
      }
    }
    ul {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.2px;
      margin: 0 0 30px 0;
      list-style: none;
      li {
        list-style-type: unset;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.2px;
        margin: 0 0 10px 0;
        padding: 0 0 0 35px;
        position: relative;
      }
      li:before {
        content: "";
        height: 12px;
        width: 12px;
        border: 3px solid $primary-color;
        border-radius: 100%;
        display: inline-block;
        margin-right: 10px;
        position: absolute;
        top: 3px;
        left: 8px;
      }
    }
  }
  &.disk-style {
    li {
      color: $dark-gray-02;
      margin: 0 0 16px;
      padding: 0 0 0 23px;

      &:last-child {
        margin: 0;
      }

      &:before {
        border: none;
        left: 0;
        top: 8px;
        width: 6px;
        height: 6px;
        background-color: $blue-shade-03;
      }
    }
  }
}

.text-right {
  text-align: right;
}

.banner-section {
  &.will-banner {
    .content-block {
      h1 {
        span {
          display: inline-block;
        }
      }
    }

    padding-bottom: 0;
  }
}

.btn-wrap .btn {
  margin: 0 15px 0 0;

  &:last-child {
    margin: 0;
  }

  @include bp(sm-max) {
    margin: 0 0 15px;
  }
}

.no-line-clamp {
  p {
    display: block !important;
  }
}

.info-block {
  margin: 16px 0 0;

  p, a {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 14px !important;
    line-height: 1.45 !important;
    letter-spacing: -0.2px !important;
    color: $dark-gray-08 !important;
    margin: 0 0 10px;

    @include bp(sm-max) {
      font-size: 12px !important;
      line-height: 1.4 !important;
    }

    &:last-child {
      margin: 0;
    }

    .info-img {
      font-size: 10px;
      font-style: normal;
      color: $white;
      font-weight: 900;
      width: 16px;
      height: 16px;
      background-color: $dark-gray-08;
      border-radius: 50%;
      margin-right: 10px;
      display: -webkit-inline-box;
      display: -webkit-inline-flex;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
  }
}

.row-reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  flex-direction: row-reverse;
}

.calling-modal {
  .modal-dialog {
    @include bp(xs-max) {
      width: 335px;
      margin: 20px;
    }
  }

  .modal-content {
    border-radius: 5px;

    .modal-header {
      padding: 30px 25px 30px 32px;
      border-bottom: 1px solid $dark-gray-07;

      @include bp(sm-max) {
        padding: 25px;
      }
    }

    .modal-title {
      font-size: 24px;
      line-height: 1.2;
      font-weight: 400;

      span {
        font-weight: 600;
      }
    }

    .modal-body {
      padding: 35px 32px 30px;
      @include bp(sm-max) {
        padding: 25px;
      }

      > p {
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: -.2px;
        margin-bottom: 24px;
        color: $dark-gray-05;

        @include bp(sm-max) {
          margin-bottom: 15px;
        }
      }

      .contact-form {
        margin: 0;

        .btn-wrap {
          &.align-right {
            -webkit-box-pack: end;
            -ms-flex-pack: flex-end;
            justify-content: flex-end;
          }

          p {
            font-size: 16px;
            line-height: 1.5;
            letter-spacing: -.2px;
            margin: 0;

            @include bp(sm-max) {
              font-size: 12px;
            }
          }

          .btn {
            &.btn-raised {
              &.btn-primary {
                font-size: 14px !important;
                line-height: 1.95 !important;
                height: auto;
                letter-spacing: -0.17px;

                &:last-child {
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }

    .form-group {
      margin: 0 0 17px;
    }

    .form-group {
      &.bmd-form-group {
        margin: 0 0 17px;
      }

      &.form-check {
        margin: 0 0 30px;
      }
    }
  }
}

.navbar-section {
  padding: 10px 0;
  background-color: $white;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 10;

  @include bp(lg-max) {
    top: -1px !important;
  }

  .navbar-block, .navbar-block1 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    list-style: none;
    margin: 0 -30px;

    @include bp(sm-max) {
      overflow-x: auto;
      scrollbar-width: none;
      -ms-overflow-style: none;
      white-space: nowrap;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    @include bp(xs-max) {
      margin: 0 -20px;
    }

    li {
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: -.2px;
      padding: 0 14px;

      @include bp(xs-max) {
        padding: 0 20px;
      }

      a {
        color: $dark-gray-05;
        display: inline-block;
        padding: 2px 0 6px;
        position: relative;
        @include transition(all 0.3s);

        &.active {
          color: $black;
          font-weight: 500;

          &:after {
            width: 100%;
          }
        }


        &.desk-link {
          &:hover {
            color: $primary-color;

            &:after {
              width: 100%;
            }
          }
        }

        &:after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 0;
          height: 2px;
          background-color: $primary-color;
          @include transition(all 0.3s);
        }
      }
    }
  }
}

.media-section {
  padding: 130px 0;

  @include bp(md-max) {
    padding: 50px 0;
  }

  .title-block {
    margin: 0 0 82px;

    @include bp(md-max) {
      margin: 0 0 40px;
    }

    .left-col {
      @include bp(md-max) {
        margin: 0 0 25px;
      }
    }
  }

  .title {
    &:last-child {
      margin: 0;
    }
  }

  .center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .content {
    p {
      font-size: 16px;
      line-height: 1.5;
      color: $dark-gray-05;
      letter-spacing: -0.2px;
      font-family: $roboto;
      margin: 0 0 16px;

      &:last-child {
        margin: 0;
      }
    }
  }
}

.media-slider {
  cursor: grab;

  .swiper-wrapper {
    margin: 0 0 36px;
  }

  .swiper-scrollbar {
    @include bp(lg-min) {
      max-width: 1088px;
    }
  }

  .media-slide {
    display: block;

    .slide-img {
      display: block;
      margin: 0 0 16px;

      &:last-child {
        margin: 0;
      }
    }

    .label {
      font-size: 12px;
      line-height: 1.4;
      color: $primary-color;
      letter-spacing: -0.2px;
      margin: 0 0 8px;
      font-family: $roboto;
      font-weight: 400;
      text-transform: uppercase;
      display: block;

      &:last-child {
        margin: 0;
      }
    }

    p {
      font-size: 18px;
      line-height: 1.35;
      letter-spacing: -0.4px;
      color: $black;
      margin: 0 0 8px;
      font-family: $roboto;
      font-weight: 700;

      @include bp(sm-max) {
        font-size: 16px;
        line-height: 1.3;
        letter-spacing: -0.19px;
      }

      &:last-child {
        margin: 0;
      }
    }
  }
}

.form-download-section {
  padding: 128px 0;

  @include bp(md-max) {
    padding: 50px 0;
  }

  .title-block {
    margin: 0 0 82px;

    @include bp(md-max) {
      margin: 0 0 40px;
    }

    .left-col {
      @include bp(md-max) {
        margin: 0 0 25px;
      }
    }
  }

  .title {
    &:last-child {
      margin: 0;
    }
  }

  .center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .content {
    p {
      font-size: 16px;
      line-height: 1.5;
      color: $dark-gray-05;
      letter-spacing: -0.2px;
      font-family: $roboto;
      margin: 0 0 16px;

      &:last-child {
        margin: 0;
      }

      a {
        display: inline-block;
        color: $primary-color;
        word-break: break-word;
      }
    }
  }
}

.download-blocks {
  .download-col {
    margin: 20px 0;

    @include bp(xs-max) {
      margin-bottom: 16px;
    }
  }

  .expandable-block {
    display: none;
  }

  .download-card {
    padding: 32px 32px 27px;
    background-color: $light-gray-05;
    border-radius: 4px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 100%;

    @include bp(sm-max) {
      padding: 30px 24px 25px;
    }

    .inner-wrap {
      margin: 0 0 25px;

      @include bp(sm-max) {
        margin: 0 0 40px;
      }
    }

    .file-icon {
      max-width: 30px;
      width: 100%;
      display: block;
      margin: 0 0 24px;
    }

    .card-title {
      display: block;
      margin: 0 0 8px;

      &:last-child {
        margin: 0;
      }
    }

    h4 {
      font-size: 24px;
      line-height: 1.2;
      color: $black;
      letter-spacing: -0.6px;
      font-family: $roboto;
      font-weight: 700;
      margin: 0 0 8px;

      @include bp(lg-max) {
        font-size: 21px;
      }

      @include bp(sm-max) {
        font-size: 20px;
      }

      &:last-child {
        margin: 0;
      }
    }

    .download-links {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-top: auto;

      .download-link, .card-link {
        display: inherit;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        font-size: 14px;
        line-height: 1.45;
        letter-spacing: -0.2px;
        color: $primary-color;
        font-family: $roboto;
        font-weight: 500;
        margin-right: 19px;

        &:last-child {
          margin-right: 0;
        }
      }

      .download-icon {
        width: 10px;
        margin-right: 11px;
      }
    }
  }
}

.fund-offer-section {
  padding: 128px 0;

  @include bp(md-max) {
    padding: 50px 0;
  }

  .left-block {
    @include bp(md-max) {
      margin: 0 0 40px;
    }
  }

  .title {
    margin: 0 0 32px;

    @include bp(md-max) {
      margin: 0 0 25px;
    }
  }

  .content {
    p {
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: -0.2px;
      color: $dark-gray-05;
      margin: 0 0 16px;

      @include bp(md-max) {
        margin: 0 0 10px;
      }

      &:last-child {
        margin: 0;
      }

      a {
        display: inline-block;
        color: $primary-color;
      }
    }
  }

  .info-block {
    margin: 24px 0 0;

    @include bp(md-max) {
      margin: 15px 0 0;
    }

    .modal-link {
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;

      @include bp(sm-max) {
        font-size: 14px !important;
        line-height: 1.45 !important;
      }
    }
  }
}

.fund-offer-section {
  .repeater-list-item {
    padding: 0 0 28px 0;
    margin: 0 0 24px 40px;
    border-bottom: 1px solid $light-gray-04;

    h5 {
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      padding-bottom: 14px;
      letter-spacing: -0.4px;
    }

    p {
      font-size: 14px !important;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  .accordion-table-row {
    border-bottom: 1px solid $light-gray-04;
    margin: 0;

    .accordion-table-left {
      background-color: $white;
      padding: 20px;
    }

    .accordion-table-right {
      background-color: $light-gray-05;
      padding: 20px;
    }

    .heading-text {
      font-weight: 500;
      line-height: 24px;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}

.news-section {
  padding: 128px 0;

  @include bp(md-max) {
    padding: 50px 0;
  }

  .title {
    margin: 0 0 32px;
  }

  .news-cards {
    margin: 0 0 48px;

    @include bp(md-max) {
      margin: 0 0 40px;
    }

    @include bp(sm-max) {
      margin-bottom: 24px;
    }

    .card-col {
      &:nth-last-child(2) {
        @include bp(md-min) {
          margin: 0;
        }
      }

      &:last-child {
        margin: 0;
      }
    }
  }

  .news-cards, .media-cards {
    .row {
      @include bp(lg-min) {
        margin: 0 -24px;
      }
    }

    .col-md-6, .col-lg-4, .col-sm-6 {
      @include bp(lg-min) {
        padding: 0 24px;
      }
    }
  }

  &.expandable-section {
    .news-cards {
      @include bp(sm-max) {
        margin: 0 0 30px;
      }

      .card-col {
        margin: 40px 0 0;

        @include bp(sm-max) {
          margin: 24px 0 0;
        }

        &:nth-child(2) {
          @include bp(md-min) {
            margin: 0;
          }
        }

        &:first-child {
          margin: 0;
        }
      }
    }
  }
}

.news-cards {
  .card-col {
    margin: 0 0 40px;

    @include bp(md-max) {
      margin: 0 0 30px;
    }

    @include bp(sm-max) {
      margin: 0 0 24px;
    }
  }

  .news-card {
    height: 100%;
    background-color: $light-gray-05;

    .card-img {
      display: block;
      border-radius: 0;

      img {
        max-width: 100%;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .inner-wrap {
      margin: 0 0 10px;
    }

    .card-content {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      padding: 32px 45px 26px 40px;

      @include bp(sm-max) {
        padding: 24px 16px 19px;
      }

      .card-title {
        display: block;
        margin: 0 0 16px;

        &:last-child {
          margin: 0;
        }
      }

      h4 {
        letter-spacing: -0.8px;
        color: $black;
        margin: 0 0 16px;

        @include bp(sm-max) {
          font-size: 20px;
          line-height: 1.2;
          line-height: -0.4px;
        }

        &:last-child {
          margin: 0;
        }
      }

      p {
        font-size: 14px;
        line-height: 1.35;
        letter-spacing: -0.2px;
        color: $dark-gray-10;
        margin: 0 0 11px;

        @include bp(sm-max) {
          font-size: 12px;
        }

        &:last-child {
          margin: 0;
        }
      }

      .text-btn {
        margin-top: auto;
      }
    }
  }
}

.media-cards {
  .card-col {
    @include bp(md-max) {
      margin: 0 0 30px;
    }

    @include bp(xs-max) {
      margin: 0 0 24px;
    }

    &:last-child {
      margin: 0;
    }
  }

  .media-card {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 32px 40px 34px;
    height: 100%;
    background-color: $light-gray-05;

    @include bp(sm-max) {
      padding: 32px 16px 35px;
    }

    .card-label {
      display: block;
      margin: 0 0 32px;

      p {
        font-size: 14px;
        line-height: 1.2;
        color: $primary-color;
        font-weight: 500;
        text-transform: uppercase;
      }
    }

    .card-title {
      display: block;
      margin: 0 0 4px;

      &:last-child {
        margin: 0;
      }
    }

    h5 {
      line-height: 1.35;
      letter-spacing: -0.4px;
      color: $black;
      margin: 0 0 4px;

      @include bp(sm-max) {
        font-size: 16px;
        line-height: 1.3;
        letter-spacing: -0.19px;
      }

      &:last-child {
        margin: 0;
      }
    }

    p {
      font-size: 14px;
      line-height: 1.45;
      color: $dark-gray-10;
      letter-spacing: -0.2px;
      margin: 0 0 8px;

      &:last-child {
        margin: 0;
      }
    }

    .lines-block {
      margin: 0 0 24px;

      &:last-child {
        margin: 0;
      }

      p {
        text-transform: uppercase;
      }
    }
  }

  .inner-wrap {
    margin: 0 0 18px;
  }
}

.disclaimer-modal {
  overflow-y: hidden !important;
  padding: 0 !important;

  .modal-dialog {
    margin: 20px auto;
    padding: 0 15px;
    max-width: 746px;

    @include bp(sm-max) {
      max-width: 100%;
    }
  }

  .modal-content {
    border-radius: 4px;
    padding: 32px 20px 32px 32px;

    @include bp(xs-max) {
      padding: 24px 20px 24px 24px;
    }

    .modal-header {
      padding: 0 0 16px;
    }
  }

  .modal-header {

    h5 {
      line-height: 1.35;
      letter-spacing: -0.4px;
      color: $black;
    }

    .close {
      padding: 15px;
      position: absolute;
      margin: 0;
      right: 0;
      top: 0;
      opacity: 1;
      cursor: pointer;
      z-index: 1;
    }
  }

  .modal-body {
    padding: 0;
    max-height: calc(100vh - 290px);
    overflow-y: auto;

    p {
      font-size: 16px;
      line-height: 1.2;
      letter-spacing: -0.2px;
      color: $dark-gray-05;
      margin: 0 0 12px;

      &:last-child {
        margin: 0;
      }
    }
  }
}

.video-modal {
  padding: 0 !important;

  .modal-dialog {
    max-width: 746px;
    padding: 0 15px;
  }

  .modal-content {
    border-radius: 4px;

    .modal-header {
      @include bp(xs-max) {
        padding: 16px 16px 0;
      }
    }

    .modal-body {
      @include bp(xs-max) {
        padding: 12px 16px 16px;
      }
    }
  }

  .modal-body {
    img {
      max-width: 100%;
    }

    .video-block {
      width: 100%;
      height: 376px;
      position: relative;

      @include bp(xs-max) {
        height: 190px;
      }

      @include bp(xxxs-min) {
        height: 170px;
      }

      @include bp(xxxs-max) {
        height: 140px;
      }
    }

    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
}

.has-custom-scroll {
  .mCSB_inside {
    > .mCSB_container {
      margin-right: 32px;
    }

    .mCSB_scrollTools {
      width: 4px;
      opacity: 1;
      background-color: $light-gray-03;

      .mCSB_dragger {
        .mCSB_dragger_bar {
          background-color: $primary-color;
          @include border-radius(0);
        }
      }

      .mCSB_draggerRail {
        height: 0;
      }
    }
  }
}

.custom-banner {
  padding: 96px 0;

  @include bp(sm-max) {
    padding: 48px 0;
  }

  .content-block {
    h1 {
      font-size: 50px;
      line-height: 1.45;
      letter-spacing: -1.54px;

      @include bp(md-max) {
        font-size: 40px;
        line-height: 1.2;
      }

      &:last-child {
        margin: 0;
      }

      span {
        display: inline-block;
      }
    }
  }
}

.pdf-download-section {
  padding: 60px 0;
  @include bp(md-max) {
    padding: 50px 0;
  }

  .title-block {
    margin: 0 0 82px;
    @include bp(md-max) {
      margin: 0 0 40px;
    }

    .left-col {
      @include bp(md-max) {
        margin: 0 0 25px;
      }
    }
  }

  .title {
    &:last-child {
      margin: 0;
    }
  }

  .center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .content {
    p {
      font-size: 16px;
      line-height: 1.5;
      color: $dark-gray-05;
      letter-spacing: -0.2px;
      font-family: $roboto;
      margin: 0 0 16px;

      &:last-child {
        margin: 0;
      }

      a {
        display: inline-block;
        color: $primary-color;
        word-break: break-word;
      }
    }
  }
}

.client-testimonial-slider, .client-testimonial-static-slider {
    cursor: grab;

    .swiper-scrollbar {
        @include bp(lg-min) {
            max-width: 655px;
        }
    }

    .swiper-wrapper {
        margin: 0 0 33px;
    }

    .testimonial-slide {
        padding: 40px;
        background-color: $light-gray-09;
        @include border-radius(4px);

        @include bp(xs-max) {
            padding: 20px;
        }

        .client-intro {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            margin: 0 0 32px;

            @include bp(xs-max) {
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                margin: 0 0 15px;
            }

            .slider-img {
                display: block;
                width: 72px;
                margin-right: 24px;

                @include bp(xs-max) {
                    margin: 0 0 15px;
                }
            }

            h6 {
                font-size: 14px;
                line-height: 15.4px;
                color: $primary-color;
                letter-spacing: 2px;
                font-weight: 500;
                text-transform: uppercase;
            }
        }

        p {
            font-size: 14px;
            list-style-image: 1.45;
            letter-spacing: -0.2px;
            color: $dark-gray-02;
            margin: 0 0 16px;

            @include bp(xs-max) {
                margin: 0 0 8px;
            }

            &:last-child {
                margin: 0;
            }
        }
    }
}

.single-client-testimonial-slider {
  max-width: 529px;
  cursor: grab;

  .swiper-scrollbar {
    @include bp(lg-min) {
      max-width: 655px;
    }
  }

  .swiper-wrapper {
    margin: 0 0 33px;
  }

  .testimonial-slide {
    padding: 40px;
    background-color: $light-gray-09;
    @include border-radius(4px);

    @include bp(xs-max) {
      padding: 20px;
    }

    .client-intro {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin: 0 0 32px;

      @include bp(xs-max) {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin: 0 0 15px;
      }

      .slider-img {
        display: block;
        width: 72px;
        margin-right: 24px;

        @include bp(xs-max) {
          margin: 0 0 15px;
        }
      }

      h6 {
        font-size: 14px;
        line-height: 15.4px;
        color: $primary-color;
        letter-spacing: 2px;
        font-weight: 500;
        text-transform: uppercase;
      }
    }

    p {
      font-size: 14px;
      list-style-image: 1.45;
      letter-spacing: -0.2px;
      color: $dark-gray-02;
      margin: 0 0 16px;

      @include bp(xs-max) {
        margin: 0 0 8px;
      }

      &:last-child {
        margin: 0;
      }
    }
  }
}

.award-section {
  .img-block {
    padding: 50px;
    padding-right: 100px;
    padding-left: 0;

    @include bp(md-max) {
      padding: 0;
    }

    @include bp(sm-max) {
      max-width: 500px;
      width: 100%;
      margin: 0 0 25px;
    }
  }
}

.align-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.connection-section {
  padding: 128px 0;

  @include bp(md-max) {
    padding: 50px 0;
  }

  .align-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .content-block {
    @include bp(sm-max) {
      margin: 0 0 30px;
    }

    h2 {
      font-size: 64px;
      line-height: 72px;
      font-weight: 300;
      color: $black;
      letter-spacing: -1px;

      @include bp(lg-max) {
        font-size: 58px;
        line-height: 64px;
      }

      @include bp(md-max) {
        font-size: 40px;
        line-height: 48px;
      }

      span {
        font-weight: 500;
      }
    }

    .line-bottom {
      width: 32px;
      height: 2px;
      margin: 24px 0 0;
      background-color: $primary-color;
    }

    .btn {
      margin-top: 25px;
    }
  }

  .img-block {
    padding: 50px 0 50px 100px;

    @include bp(md-max) {
      padding: 0;
    }
  }
}

.expandable-block {
  display: none;
}

.theme-grey, .tool-launcher {
  background-color: $light-gray-05;

  &.stacked-card-section {
    .stacked-card-block {
      background-color: $white;

      &:hover {
        background-color: $primary-color;
      }
    }
  }

  .card-slider {
    .slide-card {
      background-color: $white;
    }
  }

  &.two-card-section {
    .card-block {
      background-color: $white;
    }
  }

  &.product-card-section {
    .label-card-block {
      background-color: $white;

      .card-label {
        span {
          background-color: $light-gray-05;
        }
      }
    }
  }

  .form-block {
    background-color: $white;
    border-color: $light-gray-01;
  }

  .testimonial-slider, .client-testimonial-slider {
    .testimonial-slide {
      background-color: $white;
    }
  }

  &.offer-block {
    .offer-tiles-wrapper {
      .offer-tiles {
        background-color: $white;

        &:hover {
          background-color: $primary-color;
        }

        .btn {
          &.btn-raised {
            &.btn-secondary {
              background-color: $white !important;

              &:hover {
                background-color: $light-gray-07 !important;
              }
            }
          }
        }
      }
    }
  }

  &.tools-calculators {
    .tools-wrapper {
      .col-sm {
        background-color: $white;

        &:hover {
          background-color: $primary-color;
        }
      }
    }
  }

  &.about-section {
    .about-text-wrap {
      .expandable-block {
        background-color: $light-gray-06;
      }
    }
  }

  .download-blocks {
    .download-card {
      background-color: $white;
    }
  }

  .news-cards {
    .news-card {
      background-color: $white;
    }
  }

  .media-cards {
    .media-card {
      background-color: $white;
    }
  }

  .btn.btn-raised.btn-secondary {
    background-color: transparent !important;

    &:hover {
      background-color: $light-gray-07 !important;
    }
  }

  &.blog-listing-section {
    .category-listing-block {
      background-color: $white;
    }
  }
}

.theme-dark {
  background-color: $theme-dark-1;

  .title {
    .text-blue {
      color: $light-gray-05;
    }

    h1 {
      color: $white;
    }

    h2 {
      color: $white;
    }

    .line-bottom {
      background-color: $blue-04;
    }

    .grey-text {
      color: $light-gray-06;
    }
  }

  &.stacked-card-section {
    .stacked-card-block {
      background-color: $white;

      &:hover {
        background-color: $primary-color;
      }
    }
  }

  &.two-card-section {
    .card-block {
      background-color: $white;
    }
  }

  .btn-wrapper {
    .view-btn {
      border-color: $primary-color;

      span {
        color: $white;
      }

      .icon {
        &:before, &:after {
          background-color: $light-gray-05;
        }
      }

      &:hover {
        /*border-color: $light-gray-07;*/
        span {
          color: $primary-color;
        }

        .icon {
          &:before, &:after {
            background-color: $primary-color;
          }
        }
      }

      &:focus {
        background-color: transparent;
        border-color: $light-gray-07;

        span {
          color: $white;
        }

        .icon {
          &:before, &:after {
            background-color: $light-gray-05;
          }
        }
      }
    }

    .btn {
      &.btn-raised {
        &.btn-secondary {
          color: $white !important;
          border-color: $primary-color;
        }
      }
    }
  }

  &.form-section {
    .form-wrap {
      p {
        color: $light-gray-06;
      }

      .form-block {
        p {
          color: $black-shade-01;
        }
      }
    }
  }

  &.img-content-section {
    .content-block {
      .content {
        p {
          color: $light-gray-06;
        }
      }

      .blue-link {
        color: $blue-04;
      }
    }
  }

  .swiper-container-horizontal {
    .swiper-scrollbar {
      .swiper-scrollbar-drag {
        background-color: $blue-04;
      }
    }
  }

  &.table-section {
    .table-blocks {
      .table-title {
        h5 {
          color: $white;
        }

        p {
          color: $light-gray-06;
        }
      }

      .inner-table {
        tr {
          td {
            color: $light-gray-06;
          }
        }
      }

      .blue-link {
        color: $blue-04;
      }
    }
  }

  &.banner-section {
    background-color: $theme-dark;
    background-image: url(../../assets/images/black-star.svg);
    @include bp(lg-max) {
      background-image: none;
    }

    .content-block {
      h1,
      p {
        color: $white;
      }
    }

    .contact-block {
      .detail-wrapper {
        box-shadow: none;
        background-color: $theme-dark-1;

        li {
          a {
            color: $white;

            &:after {
              background-color: $dark-gray-09;
            }

            i {
              &.call-btn {
                img {
                  display: none;

                  &.call-white {
                    display: inline-block;
                  }
                }
              }
            }
          }
        }

        .rounded-btn {
          &:hover {
            background-color: $dark-gray-09;
          }
        }
      }
    }

    .btn {
      &.btn-raised {
        &.btn-secondary {
          color: $white !important;
          border-color: $primary-color;
          background-color: transparent !important;

          &:hover {
            background-color: $primary-color !important;
          }
        }
      }
    }

    &.secondary-banner {
      .more-info {
        color: $white;
      }
    }
  }

  &.offer-block {
    .offer-tiles-wrapper {
      .offer-tiles {
        background-color: $theme-dark;

        &:hover {
          background-color: $primary-color;

          p {
            color: $white;
          }
        }

        h5 {
          color: $white;
        }

        p {
          color: $dark-gray-08;
        }
      }
    }

    &.has-tools {
      .offer-tiles-wrapper {
        .offer-tiles {
          @include bp(xs-max) {
            &:hover {
              .btn {
                &.btn-raised {
                  &.btn-secondary {
                    @include bp(xs-max) {
                      color: $primary-color !important;
                      border-color: transparent;
                      background-color: $white !important;

                      &:hover {
                        background-color: $light-gray-07 !important;
                      }
                    }
                  }
                }
              }
            }
          }

          .btn {
            &.btn-raised {
              &.btn-secondary {
                @include bp(xs-max) {
                  color: $white !important;
                  border-color: $primary-color;
                  background-color: transparent !important;
                }
              }
            }
          }
        }
      }
    }
  }

  &.tools-calculators {
    .tools-wrapper {
      .col-sm {
        background-color: $theme-dark;

        &:hover {
          background-color: $primary-color;
        }

        h4 {
          color: $white;
        }

        p {
          color: $light-gray-05;
        }
      }
    }
  }

  &.slider-section {
    .swiper-wrapper {
      .swiper-slide {
        p {
          color: $light-gray-03;
        }

        h4 {
          color: $white;
        }
      }
    }
  }

  &.financial-goals {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.43);
    }

    .container {
      z-index: 1;
    }

    h2 {
      color: $white;

      span {
        font-weight: 500;
      }
    }

    p {
      color: $white;
    }

    .btn {
      &.btn-raised {
        &.btn-secondary {
          color: $white !important;
          border-color: $primary-color;
          background-color: transparent !important;

          &:hover {
            background-color: $primary-color !important;
          }
        }
      }
    }
  }

  .info-block {
    p, a {
      .info-img {
        color: $dark-gray-02;
      }
    }
  }

  &.media-section {
    .content {
      p {
        color: $light-gray-06;
      }
    }
  }

  .media-slider {
    .media-slide {
      .label {
        color: $light-gray-05;
      }

      p {
        color: $white;
      }
    }
  }

  &.form-download-section {
    .content {
      p {
        color: $light-gray-06;

        a {
          color: $yellow-01;
        }
      }
    }
  }

  .download-blocks {
    .download-card {
      background-color: $dark-gray-05;

      h4 {
        color: $white;
      }
    }
  }

  &.blog-listing-section {
    background-color: $dark-gray-05;

    h5 {
      color: $white;
    }

    .tags-block {
      p {
        color: $dark-gray-08;
      }
    }

    .category-listing-block {
      background-color: $theme-dark-1;
    }

    .content {
      p {
        color: $light-gray-06;
      }
    }
  }

  .btn-block {
    .show-btn {

      &:hover {
        background-color: $yellow-03;
      }

      &:focus {
        border-color: $yellow-01;
      }

      .ripple-container {
        .ripple-decorator {
          &.ripple-on {
            background-color: $yellow-03 !important;
            opacity: 0.8;
          }

          &.ripple-out {
            opacity: 0;
          }
        }
      }

      span {
        color: $yellow-01;
      }

      .icon {
        &:before, &:after {
          background-color: $yellow-01 !important;
        }
      }
    }
  }

  .circle-list {
    li {
      color: $dark-gray-08;

      a {
        color: $dark-gray-08;

        &:hover {
          color: $yellow-01;
        }
      }

      &.active {
        color: $yellow-01;

        a {
          color: $yellow-01;
        }
      }
    }
  }

}

.content-text {
  overflow: hidden;
  max-height: 216px;

  @include bp(sm-max) {
    max-height: 220px;
  }
}

.modal {
  .modal-dialog {
    &.home-screen-modal{
      .modal-body {
        h5 {
          font-weight: bold;
        }
      }
      .modal-footer {
        button {
          min-width: 75px; height: 50px;  
        }
      }
      max-width: 400px;
    }
  }
}
.modal-body-text {
  height: 48px;
  width: 352px;
  color: #333E48;
  font-family: sans-serif;
  font-size: 16px;
  letter-spacing: -0.2px;
  line-height: 24px; 
}
.modal-content{
  &.qr-code {
  max-width: min-content;
  }  
}