.blog-section {
  padding: 100px 0;
}
.blog-listing-section {
  padding: 0 0 128px;
  @include bp(sm-max){
    padding: 0 0 48px;
  }

  .content {
    p {
      font-size: 20px;
      line-height: 1.6;
      letter-spacing: -0.25px;
      color: $dark-gray-02;
      margin: 0 0 8px;

      @include bp(sm-max) {
        font-size: 16px;
      }

      &:last-child {
        margin: 0;
      }
    }
  }

  .banner-section {
    .content-block{
      @include bp(md-max) {
        margin: 0 0 20px;
      }
      h1 {
        span{
          @include bp(md-max){
            font-weight: 500;
          }
        }
      }
    } 
  }
  .category-listing-block {
    padding: 40px;
    margin-bottom: 32px;
    background-color: $light-gray-05;

    @include bp(md-max) {
      padding: 20px;
    }

    .services-list.category-list {
      margin-bottom: 25px;

      li.expandable-list {
        padding-left: 0;
        &::before {
          display: none;
        }
        display: none;
      }
    }

    .tags-list.category-list {
      margin-bottom: 25px;

      .expandable-tags {
        display: none;
      }
    }
  }
  h5 {
    line-height: 24px;
    letter-spacing: -0.4px;
    color: $dark-gray-05;
  }
  .btn-block {
    margin-bottom: 31px;
  }
  .tags-block {
    h5 {
      margin-bottom: 17px;
    }
    p {
      line-height: 20px;
      letter-spacing: -0.2px;
      color: $dark-gray-02;
    }
    a {
      background-color: rgba(153, 153, 153, 0.2);

      &:hover {
        text-decoration: underline;
      }
    }
    .tag-active {
      color: $primary-color;
    }
  }
  .blog-iframe-block {
    a {
      display: block;
      img{
        max-width: 100%;
      }
    }
  }
  .news-cards {
    padding-left: 60px;
    @include bp(md-max){
      padding-left: 0;
    }
    .card-col {
      margin: 0 0 27px;
      @include bp(sm-max){
        margin: 0 0 17px;
      }
    }
    .news-card {
      .card-content {
        padding: 23px 16px 16px;
        .btn-block {
          margin-bottom: 0;
        }
        .inner-wrap {
          min-height: 72px;
        }
        .card-title {
          margin-bottom: 8px;

          &:last-child {
            margin: 0;
          }
        }
        h4 {
          font-size: 20px;
          line-height: 1.2;
          letter-spacing: -0.4px;
        }
        p {
          font-size: 12px;
          line-height: 1.4;
          letter-spacing: -0.2px;
          color: $dark-gray-02;
          font-weight: 500;
        }
      }
    }
  }
  .category-listing-wrapper{
    @include bp(sm-max){
      order: 2;
      padding-top: 52px;
    }
    .category-listing-block{
      @include bp(sm-max){
        display: none;
      } 
    }
  }
}
.btn-block {
  .show-btn {
    font-size: 14px;
    line-height: 1.4;
    font-weight: 500;
    padding: 16px;
    border-radius: 4px;
    letter-spacing: -0.2px;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    @include transition(all 0.3s);
    position: relative;

    @include bp(md-max) {
      padding: 10px 20px;
    }

    @include bp(sm-max) {
      width: 100%;
    }
    span {
      display: -webkit-inline-box;
      display: -webkit-inline-flex;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      color: $primary-color;
      @include transition(all 0.7s ease-in-out);
    }

    .icon {
      display: inline-block;
      font-style: normal;
      margin-right: 11px;
      height: 10px;
      width: 10px;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        @include translateY(-50%);
        height: 2px;
        width: 100%;
        background-color: $primary-color;
        @include transition(all 0.7s ease-in-out);
      }

      &.plus {
        &:after {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 50%;
          @include translateX(-50%);
          width: 2px;
          height: 100%;
          background-color: $primary-color;
          @include transition(all 0.7s ease-in-out);
        }
      }
    }

    &.text-change {
      .more-text {
        opacity: 0;
        visibility: hidden;
      }
      .less-text {
        opacity: 1;
        visibility: visible;
      }
    }

    .less-text {
      position: absolute;
      left: 0;
      right: 0;
      opacity: 0;
      visibility: hidden;
    }
  }
}
.circle-list {
  li {
    padding-left: 22px;
    position: relative;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.2px;
    color: $dark-gray-02;
    margin-bottom: 12px;

    &:last-child {
      margin: 0;
    }

    &:before {
      content: "";
      height: 6px;
      width: 6px;
      border: 1px solid $dark-gray-05;
      background-color: $light-gray-05;
      position: absolute;
      left: 0;
      top: 6px;
      display: block;
      border-radius: 50%;
    }
    a {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.2px;
      color: $dark-gray-02;
      display: inline-block;

      &:hover {
        color: $primary-color;
      }
    }
    &.title {
      padding-left: 0;
      margin: 0 0 17px;
      &:before {
        display: none;
      }
    }
    &.active {
      color: $primary-color;
      a {
        color: $primary-color;
      }
    }
  }
}

// pagination
.pagination {
  justify-content: flex-end;
  @include bp(sm-max){
    justify-content: center;
  }
  .page-item {    
    .page-link {
      height: 32px;
      width: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      min-width: auto;
      margin-right: 10px;
      font-size: 18px;
      line-height: 20px;
      font-weight: 600;
      color: $dark-gray-11;
      box-shadow: none;
      border: none;

      &:hover {
        color: $primary-color;
        background-color: transparent;
      }

      span {
        display: inherit;
        line-height: 0.8;
        align-items: flex-start;
      }

      .ripple-container {
        display: none;
      }
    }
    &.active {
      .page-link {
        background-color: $primary-color;
        border-color: $primary-color;
        color: $white;
      }
    }
    .nav-arrow {
      height: 10px;
      width: 10px;
      display: block;
      margin: 2px 0 0;
      border: 2px solid $dark-gray-03;
      border-top: 0;
      border-left: 0;
      @include rotate(132deg);
    }
    &.next, &.prev {
      .page-link {
        &:hover {
          background-color: $light-gray-03;
        }
      }
    }
    &.next {
      .nav-arrow {
        margin: 0 3px 0 0;
        @include rotate(-45deg);
      }
    }
    &.prev {
      .nav-arrow {
        margin: 0 0 0 4px;
      }
    }
    &.more-items {
      .page-link {
        align-items: flex-start;
      }
    }
    &:last-child{
      .page-link{
        margin-right: 0;
      }
    }
  }
}
