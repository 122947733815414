.square {
  margin-left: -28px;
  margin-right: 12px;
  display: inline-block;
  height: 16px;
  width: 16px;
  content: "";
}

.square-custom-list {

  padding-left: 28px;

  li {
    padding-top: 12px;
    list-style: none;

    :first-child {

      display: flex;
      align-items: center;
    }
  }
}