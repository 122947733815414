.img-block {
  position: relative;
  img {
    width: 100%;
    max-width: 100%;
    position: relative;
    z-index: 1;
  }
}
.title {
  margin: 0 0 50px 0;
  @include bp(sm-max) {
    margin: 0 0 30px 0;
  }
  h2 {
    color: $black;
    font-weight: 300;
    letter-spacing: -1px;
    @include bp(xs-max) {
      letter-spacing: -0.6px;
    }
    span {
      font-weight: 500;
      display: inline-block;
      @include bp(xs-max) {
        font-weight: 300;
      }
    }
  }
  .line-bottom {
    width: 32px;
    height: 2px;
    margin: 24px 0 0;
    background-color: $primary-color;
  }
  .text-blue {
    color: $primary-color;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 2px;
    font-weight: 500;
    text-transform: uppercase;
    margin: 0 0 15px 0;
    @include bp(sm-max) {
      letter-spacing: 1.7px;
      font-size: 12px;
    }
  }
}
.head-title {
  &.blog-title {
    margin: unset;
  }
  max-width: 860px;
  margin: 0 auto;
  font-size: 64px;
  line-height: 72px;
  font-weight: 300;
  letter-spacing: -2px;
  @include bp(lg-max) {
    max-width: 65%;
    font-size: 58px;
    line-height: 64px;
  }
  @include bp(md-max) {
    max-width: 72%;
    font-size: 48px;
    line-height: 54px;
  }
  @include bp(sm-max) {
    max-width: 100%;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -1.6px;
  }
}
.swiper-scrollbar-drag {
  background: $primary-color;
  border-radius: 0;
}
.cfp-icon {
  font-size: 13px;
}
.markets-section {
  padding: 35px 0 90px;
  @include bp(tablet-max) {
    padding: 30px 0 50px;
  }
  @include bp(sm-max) {
    padding: 20px 0 30px;
    font-size: small;
  }
  .indicator-icon {
    padding: 0 0 4px 16px;
    height: 16px;
  }
  .green-indicator-text {
    color: #17B278;
    font-weight: bold;
  }
  .red-indicator-text {
    color: #CB333B;
    font-weight: bold;
  }
  .cell-row-style {
    padding: 20px 0 !important;
    color: black !important;
  }
  h2 {
    font-size: 26px;
  }
  h5 {
    font-size: 16px;
    @include bp(xs-max) {
      font-size: 12px;
      letter-spacing: -0.2px;
      min-height: auto;
    }
  }
}
.breadcrumb {
  background: none !important;
}
.embed-tool {
  height: 100vh;
  @include bp(sm-max) {
    height: 200vh;
  }
  @include bp(md-max) {
    height: 150vh;
  }
}